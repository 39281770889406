import React,{useState} from 'react';
import { toast } from 'react-toastify';
import {
	forgetPassword
} from "../Store/actions";
import {CenterContent, Main} from "../layout";
import {PrimaryButton} from "../components";


export const ForgetPassword: React.FC<any> = ()  => {
	const [email, setEmail] = useState("");
	const [requesing, setRequesting] = useState(false);
	
	
	  

	const handleSubmit = async (e:any) => {
		e.preventDefault();
		if(!email){
			return;
		}
		setRequesting(true);
		const res = await forgetPassword(email);
		if(res.success){
			toast.success("Reset Link Send Successfully!")
		}
		else {
			toast.error(res.message);
		}
		setRequesting(false);
	}

  return (
    <>
		<Main>
			<CenterContent>
			            <div className=" mb-4 text-center">
							<h3 className="pt-4 mb-2 text-2xl text-secondry-color font-bold">Forgot Your Password?</h3>
							<p className="mb-4 text-sm text-secondry-color">
								We get it, stuff happens. <br/> Just enter your email address below and we'll send you a
								link to reset your password!
							</p>
						</div>
						<form className="pt-6 rounded">
							<div className="mb-8">
								<label className="block mb-2 text-sm text-secondry-color" htmlFor="email">
									Email
								</label>
								<input
									className="w-full px-3 py-2 text-sm leading-tight text-secondry-color bg-secondry-color/20 rounded-md shadow appearance-none focus:outline-none focus:shadow-outline"
									id="email"
									type="email"
									value={email}
									onChange={(e)=>setEmail(e.target.value)}
									placeholder="Enter Email Address..."
								/>
							</div>
							<div className="mb-6 text-center">
								<PrimaryButton style={{padding: "12px 0"}} onClick={handleSubmit}>{requesing? "...":"Reset Password"}</PrimaryButton>
							</div>
						</form>
			</CenterContent>
		</Main>
    </>
  )
}
