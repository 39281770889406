import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { 
  createPriPostSurvey,
  getSurveyTitleByUserIdAndSurveyId,
  updateLoggedInUser
 } from '../../Store/actions';
import { toast } from 'react-toastify';
import {
  Loading, 
  SurveyUI
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import {Main} from "../../layout";
import { replaceQuestionsPlaceholders } from '../OnBoarding/components/SetYourBaseline';
import { Header, Wrapper} from '../OnBoarding/components';
import { getUserActivities } from '../../Store/actions/activity';
import { AppDispatch, RootState } from '../../Store/store';
import { EnterOrganizationName } from './components/EnterOrganizationName';
import { PreSurvey } from './components/PreSurvey';
import { AfterSurveyPage } from './components/AfterSurveyPage';


interface ICreatePrePostSurvey{
  setIsShow?: any;
  setScreen?: any;
}

const CreatePrePostSurvey: React.FC<ICreatePrePostSurvey> = ({setIsShow}) => {
  const { userId, surveyId } = useParams();
  const [survey,setSurvey] = useState<any>(null);
  const [user,setUser] = useState<any>(null);
  const [questions,setQuestions] = useState(null);
  const [answers, setAnswers] = React.useState<any>(null);
  const [isPreDone, setIsPreDone] = React.useState(false);
  const [isSurveyDone, setIsSurveyDone] = React.useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
  const dispatch:AppDispatch = useDispatch();
  const [organizationName, setOrganizationName] = useState("");
  const location = useLocation();

  const handleSubmit = async (lastAns:any,lastFactor:any,starttime:any) => {
    setIsSubmiting(true);
    const myAnswers = [...answers,{rating: lastAns,XLFactor: lastFactor,starttime,endtime: new Date().getTime()}];
    const data = {
      answers: myAnswers,
      surveytitle: survey._id,
      user: user._id 
    };
    
    const res = await createPriPostSurvey(data);
    // console.log(res);
    if(res.success){
      if(location.state?.isPostSurvey){
        dispatch(updateLoggedInUser({isPostSurveyDone: true}));
      }
      if(setIsShow){
        dispatch(updateLoggedInUser({isPreSurveyDone: true}));
      }
      dispatch(updateLoggedInUser({isPreSurveyDone: true}));
      setIsSurveyDone(true);
    }
    setIsSubmiting(false);
  }

  useEffect(() => {
    (async()=>{
      const tUserId = userId || loggedInUser._id;
      const tSurveyId = surveyId || loggedInUser.preSurveyId;
      const res = await getSurveyTitleByUserIdAndSurveyId(tUserId, tSurveyId,false);
      const res1 = await getUserActivities();

      if(res.success && res1.success){
        setSurvey(res.surveyTitle);
        setUser(res.user);
        if(res.user.organizationName){
          const surveyName = res1.activities[0].category;
          const title = res1.activities[0].name;
          const questions = replaceQuestionsPlaceholders(res.surveyTitle.questions,res.user.organizationName,res.user.personalImprovementGoals,res.user.personalImprovementObstacles,surveyName,title);
          setQuestions(questions);
        }
      }
      else {
        toast.error("Something Went Wrong!");
      }
    })()
  }, [userId,surveyId,loggedInUser]);


  const handleBack = () => {
    setIsPreDone(false);
  }

  if(!survey || !user){
    return <div className="w-full h-[93vh]"><Loading/></div>
  }

  if(!questions){
    return <Main>
            <Wrapper>
                  <EnterOrganizationName survey={survey} setQuestions={setQuestions} organizationName={organizationName} setOrganizationName={setOrganizationName}/>
            </Wrapper> 
        </Main> 
    
  }

  if(!isPreDone){
    return <Main>
              <Wrapper>
                  <PreSurvey setIsPreDone={setIsPreDone}/>
              </Wrapper> 
          </Main> 
  }

  if(isSurveyDone){
    return <Main>
              <Wrapper>
                  <AfterSurveyPage/>
              </Wrapper> 
          </Main> 
  }

  return (
    <Main>
      <div className='h-5'/>
      <Header handleBack={handleBack} title="Set Your Baseline"/>
      <div className='h-[20px]'/>
      <SurveyUI isShowFinish={true} isSubmiting={isSubmiting} handleSubmit={handleSubmit} questions={questions} answers={answers} setAnswers={setAnswers}/>
    </Main>
  )
}

export default CreatePrePostSurvey;











