import React, { useEffect, useState } from "react";
import { 
  useLocation, 
  useNavigate, useParams } from "react-router-dom";
import { 
  getSurveyTitleByUserIdAndSurveyId,
  createSurvey,
  setPersonalImprovementPoints,
  updateLoggedInUser,
  getUserNotifications,
  getMyStreak
 } from "../../Store/actions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { 
  SET_SURVEY_USER,
  COMPLETE_SURVEY,
  GOAL_TYPES,
 } from "../../constants";
import {SurveyUI} from "../../components";
import {
  PurposeAndGoalStatement,
  Logo,
  NonCogniPulseTime,
  Streak,
  IStreak
} from "./components";
import {Main} from "../../layout";
import { useScrollToTop } from "../../hooks";
import { getActivityByid, getUserActivities } from "../../Store/actions/activity";
import { AppDispatch, RootState } from "../../Store/store";
import { CurrentGoalorTask } from "./components/CurrentGoalorTask";
import { ImpulseRating } from "./components/ImpulseRating";
import { getCurrentGoal } from "../../Store/actions/goal";
import { firstLetterUpperCase } from "../../utils";


interface ICreateSurvey {};

const CreateSurvey: React.FC<ICreateSurvey> = () => {
  const location = useLocation();
  const isCommingFromPrePostSurvey = location?.state?.isCommingFromPrePostSurvey;
  const urlParams = new URLSearchParams(window.location.search);
  const surveyName:any = urlParams.get("surveyName");
  const title:any = urlParams.get("title");
  const surveyLinkId = urlParams.get("surveyLinkId");
  const activityId = urlParams.get("activityId");
  const newSurvey = urlParams.get("new");
  const isPreviousQuestions:any = urlParams.get("isPreviousQuestions");
  const isNonCogniPulse = urlParams.get("isNonCogniPulse");
  const dispatch: AppDispatch = useDispatch();
  const navigator = useNavigate();
  const { userId, surveyId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [user, setUser] = useState<any>(null);
  const [isDisplayLogo, setIsDisplayLogo] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
  const [purposeAndGoalStatementSeen, setpurposeAndGoalStatementSeen] = useState(false);
  const [surveyQuestionInstruction, setSurveyQuestionInstruction] = useState(null);
  const [inThePastMinutes, setInThePastMinutes] = useState<any>(null);
  const [pastTimeCompleted, setPastTimeCompleted] = useState(false);
  const [surveyTitle, setSurveyTitle] = useState<any>(null);
  const [numberOfNotifications, setNumberOfNotifications] = useState(null);
  const [numberOfCompletedSurveys, setNumberOfCompletedSurveys] = useState<any>(null);
  const [numberOfCompletedImpactSurveys, setNumberOfCompletedImpactSurveys] = useState<any>(null);
  const [dayOrWeekGoal, setDayOrWeekGoal] = useState<null | string>(null);
  const [shouldShowStreak, setShouldShowStreak] = useState(false);
  const [streakData, setStreakData] = useState<IStreak | null>(null);
  const [navigatorStateData, setNavigatorStateData] = useState<any>(null);
  const [shouldDisplayEditCurrentGoal, setShouldDisplayEditCurrentGoal] = useState(true);
  const [currentActivity, setCurrentAcitvity] = useState<any>({});
  const [isImpulseCompleted, setIsImpulseCompleted] = useState(false);
  const [stateData, setStateData] = useState<any>(null);
  const [goalToDisplayInDial, setGoalToDisplayInDial] = useState("");
  const [goalToDisplayInHeader, setGoalToDisplayInHeader] = useState("");
  const [goalToDisplayInHeaderTitle, setGoalToDisplayInHeaderTitle] = useState("");
  const [goalIds, setGoalIds] = useState({dialGoalId: "",headerGoalId: ""});
  const [impactSurveyid, setImpactSurveyId] = useState("");
  const setGoals = useSetGoals(setGoalToDisplayInDial,setGoalToDisplayInHeader,setGoalToDisplayInHeaderTitle,setGoalIds);
  useScrollToTop();


  

  const handleSubmit = async (lastAns:any,lastFactor:any,starttime:any) => {
        let stateData:any = null;
        setIsSubmiting(true);
        const preparFinalSurveyAnswers = () => {
            const myAnswers = [...answers,{
              starttime,
              endtime: new Date().getTime(),
              rating: lastAns,
              XLFactor: lastFactor
            }];

            let data:any = {};

            myAnswers.forEach((answer:any) => {
              let temp = {
                starttime: answer.starttime,
                endtime: answer.endtime,
                rating: answer.rating,
              };
              data[answer.questiontype || answer.XLFactor] = temp;
            });

            if(!isNonCogniPulse && (!data.Fun || !data.Purpose || !data.Growth || !data.Emotives || !data.Utility || !data.Apathy)){
              console.log(data);
              return null;
            }
            if(isNonCogniPulse && !inThePastMinutes){
              console.log(data);
              return null;
            }

            data.isconsentformseen = true;
            data.isbriefingformseen = true;
            data.userId = userId;
            data.surveyTitleId = surveyId;
            data.link = window.location.href;
            data.surveyName = surveyName;
            data.isNewSurvey = newSurvey;
            data.surveyPeriod = inThePastMinutes;
            data.activity = currentActivity._id;
            if(goalIds.dialGoalId){
              data.dialGoal = goalIds.dialGoalId;
            }
            if(goalIds.headerGoalId){
              data.headerGoal = goalIds.headerGoalId;
            }

            if(surveyLinkId){
              data.surveyLinkId = surveyLinkId;
            }

            if (window.location.hostname === "localhost") {
              data.link = window.location.href.replace(
                "localhost:3000",
                "localhost:4000"
              );
            }

            return data;
        };
      
        const data = preparFinalSurveyAnswers();
       
        if(!data){
            setIsSubmiting(false);
            console.log("Fun Purpose Growth Emotives Utility Apathy are required!");
            toast.error("Something went wrong!");
            return;
        }
        
        const res = await createSurvey(data);
        const streakRes = await getMyStreak(surveyName);
        

        if(res.success){
          const isCogniPulse = isNonCogniPulse === "true"? false:true;
          stateData = {
              category: title,
              surveyId,
              surveyName,
              isNonCogniPulse: isCogniPulse? false:true,
              loading: isCogniPulse,
              numberOfNotifications,
              numberOfCompletedSurveys: isCogniPulse? numberOfCompletedSurveys+1:numberOfCompletedSurveys,
              numberOfCompletedImpactSurveys: isCogniPulse? numberOfCompletedImpactSurveys:numberOfCompletedImpactSurveys+1,
              isCogniPuseChanged: isCogniPulse? true:false,
              isImPuseChanged: isCogniPulse? false:true,
              newCognipulseId: res.survey._id,
              activityId
          };


          
          if(!isCogniPulse){
            setIsImpulseCompleted(true);
            setStateData(stateData);
            setImpactSurveyId(res.survey._id)
            return;
          }
        }

        if(streakRes.success){
          setNavigatorStateData(stateData);
          setStreakData(streakRes.streak);
          setShouldShowStreak(true);
          return;
        }
        
        if (res.success) {
           if(!user.hasCompletedFirstSurvey){
              dispatch(updateLoggedInUser({hasCompletedFirstSurvey: true}));
           }
          
            setTimeout(() => {
              dispatch(setPersonalImprovementPoints(userId as string,COMPLETE_SURVEY));
            }, 7000);

            
            if(!loggedInUser.isPostSurveyDone && loggedInUser.postSurveyDate && new Date(loggedInUser.postSurveyDate) < new Date()){
              const url = `/pre-post-survey/${loggedInUser._id}/${loggedInUser.postSurveyId}`;
              navigator(url,{state: {isPostSurvey: true}});
              return;
            }

            navigator(`/my-dashboard`, { state: stateData});
            return;
        } 
        else {
          toast.error(res.message);
        }

        setIsSubmiting(false);
  }

  useEffect(() => {
    (async () => {
      const res = await getSurveyTitleByUserIdAndSurveyId(userId as string, surveyId as string,isPreviousQuestions);
      // console.log(res);
      if (res.success) {
        dispatch({ type: SET_SURVEY_USER, payload: res.user });
        const url = window.location.href;
        const isCompleted = res.user.surveysinfo.find((si:any) => {
            let link1 = si.link.trim().split("/survey")[1];
            link1 = link1?.replaceAll("%20"," ");
            let link2 = url.trim().split("/survey")[1];
            link2 = link2?.replaceAll("%20"," ");
            if(si.iscompleted === true && link1 === link2){
              return si;
            }
            else{
              return null;
            }
          }
        );


        if (isCompleted) {
          navigator(`/my-dashboard`, { state: surveyId });
        }

        let shuffled = res.surveyTitle.questions.map((a:any) => ({sort: Math.random(), value: a}))
                                                .sort((a:any, b:any) => a.sort - b.sort)
                                                .map((a:any) => a.value);
                                                
        shuffled = shuffled.map((ques:any) => {
          let goal = `<div style="margin-top: 8px; box-shadow: 0px 4px 16px rgba(17, 17, 26, 0.1), 0px 8px 24px rgba(17, 17, 26, 0.1), 0px 16px 26px rgba(17, 17, 26, 0.1); border-radius: 4px; padding: 4px;">${goalToDisplayInDial}</div>`;
          let question = `${ques.question}`;
          question = question?.replace("study", "Studies");
          if(goalToDisplayInDial){
            question = question?.replace("[insert survey subject]", "");
            question = question?.replace("[insert user input name/title]", "[thisismydialgoal]");
            question = question?.replace("[thisismydialgoal]", goal);
            // question = question?.replace("[thisismydialgoal]", goalToDisplayInDial);
          }
          else {
            question = question?.replace("[insert survey subject]", surveyName);
            question = question?.replace("[insert user input name/title]", title);
          }
          return {...ques,question};
        })                                             
        setQuestions(shuffled);
        // console.log(shuffled);
        setUser(res.user);
        if(res.surveyTitle.surveyquestioninstruction.isvisible){
          setSurveyQuestionInstruction(res.surveyTitle.surveyquestioninstruction.instruction);
        }
        setSurveyTitle(res.surveyTitle);
        setNumberOfCompletedSurveys(res.surveysDoneToday);
        setNumberOfCompletedImpactSurveys(res.numberOfCompletedImpactSurvey);
      }
    })();
  }, [userId, surveyId, navigator, dispatch,surveyName,title,isPreviousQuestions,goalToDisplayInDial]);

  useEffect(() => {
    (async ()=>{
      const res = await getUserNotifications();
      if(res.success){
          const numberOfNotifications = res.notifications
          .reduce((a:any,item:any) => {
            return a = [...a,...item.days]
           },[])
          .filter((item:any) => item.day === new Date().getDay())
          .reduce((a:any,item:any) => {
            return a = [...a,...item.times]
          },[]).length;

          setNumberOfNotifications(numberOfNotifications);
      }
    })()
  }, []);

  useEffect(() => {
    (async () => {
      const res = await getUserActivities();
      if(res.success){
        const currentActivity = res.activities.find((act:any) => act.category === surveyName);

        setCurrentAcitvity(currentActivity);

        // setGoals(currentActivity);
        
        // if(currentActivity){
        //   setCurrentAcitvity(currentActivity);
        //   setShouldDisplayEditCurrentGoal(true);
        //   setcurrentActivityId(currentActivity._id);
        // }

        // if(currentActivity && currentActivity.shouldDisplayDayOrWeekGoalInCognipuse){
        //   setDayOrWeekGoal(currentActivity.dayOrWeekGoal);
        //   setLabel("My Day Goal");
        // }
        
        // if(currentActivity.goal?.length > 0 && currentActivity.purpose?.length > 0){
        //   setpurposeAndGoalStatementSeen(false);
        // }
        // else {
        //   setpurposeAndGoalStatementSeen(true);
        // }
      }
    })()
  }, [surveyName,setDayOrWeekGoal]);

  
  if(isDisplayLogo){
    return <Logo setIsDisplayLogo={setIsDisplayLogo}/>
  }

  if(!purposeAndGoalStatementSeen && !isNonCogniPulse){
    return <PurposeAndGoalStatement 
            currentCategory={surveyName}
            currentName={title}
            user={user} 
            isCommingFromPrePostSurvey={isCommingFromPrePostSurvey}
            setpurposeAndGoalStatementSeen={setpurposeAndGoalStatementSeen}
            />
  }

  if(shouldDisplayEditCurrentGoal){
    return <CurrentGoalorTask setGoals={setGoals}  currentActivity={currentActivity} currentActivityId={currentActivity?._id} setShouldDisplayEditCurrentGoal={setShouldDisplayEditCurrentGoal}/>
  }

  if(isNonCogniPulse && !pastTimeCompleted){
    return <NonCogniPulseTime dayOrWeekGoal={dayOrWeekGoal} userId={userId} surveyName={surveyName} title={title} setPastTimeCompleted={setPastTimeCompleted} inThePastMinutes={inThePastMinutes} setInThePastMinutes={setInThePastMinutes}/>
  }

  if(isNonCogniPulse && isImpulseCompleted){
    return <ImpulseRating goalIds={goalIds} impactSurveyid={impactSurveyid} stateData={stateData} dayOrWeekGoal={goalToDisplayInDial} currentActivity={currentActivity} inThePastMinutes={inThePastMinutes} surveyTitle={surveyTitle}/>
  }

  if(shouldShowStreak){
    return <Streak streakData={streakData} navigatorStateData={navigatorStateData}/>
  }
 
  
  return (
    <>
      <Main>
          <div className="h-[20px]"/>
          <p className="mb-3 text-center text-secondry-color font-bold select-none">{!inThePastMinutes? `CogniPulse`:`${surveyTitle.title}`}</p>
          {
            inThePastMinutes && <h6 className="mb-3 text-center text-secondry-color font-bold">In the past {inThePastMinutes} minutes</h6>
          }

          <SurveyUI 
            isShowFinish={isNonCogniPulse? false:true} 
            dayOrWeekGoal={dayOrWeekGoal} 
            label={""}  
            handleSubmit={handleSubmit} 
            surveyQuestionInstruction={surveyQuestionInstruction} 
            isSubmiting={isSubmiting} 
            questions={questions} 
            answers={answers} 
            setAnswers={setAnswers}
            goalToDisplayInHeader={goalToDisplayInHeader}
            goalToDisplayInHeaderTitle={goalToDisplayInHeaderTitle}
          /> 
      </Main>
    </>
  );
}

export default CreateSurvey;



function useSetGoals(setDisplayInDial:any,setDisplayInHeader:any,setDisplayInHeaderTitle:any,setGoalIds:any){

  async function func(activityId:string,skipCurrentGoal?: boolean){
      let dialData:any = "";
      let headerData:any = "";
      let isParentTaskIsGoal = true;

      const act = await getActivityByid(activityId);

      if(skipCurrentGoal){
        let index = 4;
        
        while (index >= 0) {
          const res = await getCurrentGoal(GOAL_TYPES[index],activityId);
          if(res.success && res.goal){
            if(!dialData){
              dialData = res.goal;
              if(res.parentTask){
                headerData = res.parentTask;
                isParentTaskIsGoal = res.isParentTaskIsGoal;
              }
            }
            else {
              if(!headerData){
                headerData = res.goal;
                index = -1;
              }
            }
          }
          index--;
        }
        
      }
      else {
        let index = 5;

        while (index >= 0) {
          const res = await getCurrentGoal(GOAL_TYPES[index],activityId);
          
          if(res.success && res.goal){
            if(!dialData){
              dialData = res.goal;
              if(res.parentTask){
                headerData = res.parentTask;
                isParentTaskIsGoal = res.isParentTaskIsGoal;
              }
            }
            else {
              if(!headerData){
                headerData = res.goal;
                index = -1;
              }
            }
          }
          index--;
        }
      }

      if(dialData){
        setGoalIds((prev:any)=> {
          return {
            ...prev,
            dialGoalId: dialData._id
          }
        });
        // if(dialData.dynamicProperties.parentGoalId){
        //   // const res = await getGoal
        // }
      }
      if(headerData){
        setGoalIds((prev:any)=> {
          return {
            ...prev,
            headerGoalId: headerData._id
          }
        })
      }


      const dialStr = dialData.name;
      const headerStr = headerData.name;
      
      setDisplayInHeader(headerStr);
      setDisplayInDial(dialStr);

      if(!headerStr && act.success && dialStr){
        setDisplayInHeader(`My ${firstLetterUpperCase(act.activity?.name)} ${firstLetterUpperCase(act.activity?.category)}`);
      }
      console.log(isParentTaskIsGoal);
      
      if(!isParentTaskIsGoal){
        setDisplayInHeader(`My ${firstLetterUpperCase(act.activity?.name)} ${firstLetterUpperCase(act.activity?.category)}`);
      }

      if(headerData.type === GOAL_TYPES[0]){
        setDisplayInHeaderTitle("My Moonshot Goal");
      }
      if(headerData.type === GOAL_TYPES[1]){
        setDisplayInHeaderTitle(headerData.type);
      }
      if(headerData.type === GOAL_TYPES[2]){
        setDisplayInHeaderTitle(headerData.type);
      }
      if(headerData.type === GOAL_TYPES[3]){
        setDisplayInHeaderTitle(headerData.type);
      }
      if(headerData.type === GOAL_TYPES[4]){
        setDisplayInHeaderTitle("My Day Goal");
      }
      if(headerData.type === GOAL_TYPES[5]){
        setDisplayInHeaderTitle("My Current Goal/Task");
      }
  }

  return func;
}




