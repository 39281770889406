import { useEffect } from 'react';
import { useState } from 'react';
import { RectTooltip } from "../../../components";
import { motion } from "framer-motion";
import { arc } from 'd3-shape';
import {SECONDRY_COLOR, QUADRANTS, PRIMARY_COLOR} from "../../../constants";
import { ArrowDownRed, ArrowUpGreen } from '../../../components/Icons';
const tooltipId = "tooltips-inside-score-in-brain";
export const XLScoreInBraintoolTipText = `A high score means you're more able to access your potential for adaptive performance. A low score means your potential is more likely to be limited to tactical performance.`; 




interface IScoreInBrainWithLabels {
  containerRef: any;
  XLScore: any;
  XLChange: any;
  setIsAnimationEnds: any;
  isBrainAnimate: any;
  currentTourStep: any;
  radius?: number;
}

const ScoreInBrainWithLabels: React.FC<IScoreInBrainWithLabels> = ({radius,containerRef,XLScore,XLChange,setIsAnimationEnds,isBrainAnimate,currentTourStep}) =>  {
    const [width, setWidth] = useState(715);

    useEffect(() => {
      if(containerRef){
        setWidth(containerRef.current.clientWidth);
      }
    }, [containerRef]);


    return <>
        <ProgressBar radius={radius} currentTourStep={currentTourStep} isBrainAnimate={isBrainAnimate} setIsAnimationEnds={setIsAnimationEnds} width={width} progress={XLScore} change={parseFloat(XLChange)}/>
    </> 
}

export {ScoreInBrainWithLabels};


interface IProgressBar {
  width: any;
  progress: any;
  change: any;
  setIsAnimationEnds: any;
  isBrainAnimate: any;
  currentTourStep: any;
  radius: any;
}

const ProgressBar: React.FC<IProgressBar> = ({radius,width,progress,change,setIsAnimationEnds,isBrainAnimate,currentTourStep}) =>  {
    // const containerwidth = width;
    width = width === 715? 270:((57/100)*width);
    const [currentProgress,setCurrentProgress]= useState(0);
    const [animationDone,setAnimationDone]= useState(false);
    const [isLabelAnimationDone,setIsLabelAnimationDone]= useState(false);
    const height = width;
    const strokeWidth = 19.2;
    const r = radius || 116;
    const strokeDasharray = 2 * 3.14 * r;
    const strokeDashoffset = strokeDasharray * ((100 - currentProgress)/100);
    const gap = 40;
    const labelsAnimationDuration = .2;
    const SVGWidth = r*3;
    const SVGHeight = r*3;
    const left = SVGWidth/2;
    const top = SVGHeight/2;     

    
    useEffect(() => {
        if(!isBrainAnimate){
          setAnimationDone(true);
          setIsAnimationEnds(true);
          setCurrentProgress(progress);
        }
        if(isLabelAnimationDone && isBrainAnimate){
          let time1:any = undefined;
          const timing = setInterval(() => {
            setCurrentProgress(p => {
                // if((p < (progress/4) || p > (progress-(progress/4))) && p <= progress){
                //   return p+.1;
                // }
                if(p <= progress){
                  // const t = easeOutBounce((p+.5)/progress)*progress;
                  return p+.5;
                }
                
                clearInterval(timing);
                setAnimationDone(true);
                time1 = setTimeout(() => {
                  setIsAnimationEnds(true);
                }, 2000);

                return progress;
            })
          }, 5);

          return () => {
              clearInterval(timing);
              clearInterval(time1);
          };
        }
    }, [progress,isLabelAnimationDone,setIsAnimationEnds,isBrainAnimate]);
    

    useEffect(() => {
      const tim = setTimeout(() => {
        setIsLabelAnimationDone(true);
      }, isBrainAnimate? ((((labelsAnimationDuration*1000)*4)*3)):0);

      return () => {
        clearTimeout(tim);
      };
    }, [isBrainAnimate]);


    
    return (<>
        <svg 
        id='introSelector5' 
        style={{overflow: "visible"}} 
        width={SVGWidth} 
        height={SVGHeight} 
        viewBox={`0 0 ${SVGWidth} ${SVGHeight}`}>
          
          <defs>
            <mask id='myMask'>
              {/* Add mask content here */}
              <circle r={r} cx={left} cy={top} fill="transparent" stroke={`white`} strokeWidth={strokeWidth} strokeDasharray={strokeDasharray+1} strokeDashoffset={strokeDashoffset+2}/>
            </mask>

            <filter id='shadow' colorInterpolationFilters="sRGB">
              <feDropShadow dx="2" dy="2" stdDeviation="3" floodOpacity="0.5"/>
            </filter>
          </defs>

          <circle filter="url(#shadow)" r={r} cx={left} cy={top} fill="transparent" stroke={PRIMARY_COLOR} strokeWidth={strokeWidth}/>
          
         
          {/* apply gradient  */}
          <foreignObject x={0} y={0} width={SVGWidth} height={SVGHeight} mask='url(#myMask)'>
            <div 
              style={{
                width: `100%`,
                height: `100%`,
                background: `conic-gradient(from 90deg, #dc6d60 18%, #ffa162 43%, #64e55a 61%, #eee164 89%)`
              }}
            />
          </foreignObject>


          
          <Quadrants radius={r+(strokeWidth/2)} SVGWidth={SVGWidth} SVGHeight={SVGHeight} isBrainAnimate={isBrainAnimate} labelsAnimationDuration={labelsAnimationDuration}/>

          {/* only for dashboard tutorial  */}
          {
            (currentTourStep === 1 || currentTourStep === 2 || currentTourStep === 3) && <>
            <circle r={r-(strokeWidth/2)} cx={left} cy={top} fill="black" style={{opacity: .4}} stroke="transparent" strokeWidth={strokeWidth}/>
            {/* <circle r={r+strokeWidth} cx={left} cy={top} fill="transparent" style={{opacity: .6}} stroke="blue" strokeWidth={strokeWidth+100}/> */}
            </>
          }
          <circle id='introSelector2' r={r+7} cx={left} cy={top} fill="transparent" stroke="transparent" strokeWidth={strokeWidth}/>
          {/* top half  */}
          <rect id='introSelector4' fill='transparent' width={width-strokeWidth} height={(height-strokeWidth)/2} x={left-((width-strokeWidth)/2)} y={top-((height-strokeWidth)/2)}/>
          {/* bottom half  */}
          <rect id='introSelector3' fill='transparent' width={width-strokeWidth} height={(height-strokeWidth)/2} x={left-((width-strokeWidth)/2)} y={top}/>
        
          <foreignObject style={{overflow: "visible",display: (currentTourStep === 1 || currentTourStep === 2 || currentTourStep === 3)? "none":""}} width={r*2-gap} height={r*2-gap} x={left-((r*2-gap)/2)} y={top-((r*2-gap)/2)}>
            <XLScore r={r}  progress={currentProgress} change={change} animationDone={animationDone} isLabelAnimationDone={isLabelAnimationDone}/>
          </foreignObject>

        </svg>
        <RectTooltip id={tooltipId}/>
    </>)
}

interface IXLScore {
  progress: any;
  change: any;
  animationDone: any;
  isLabelAnimationDone: any;
  r: any;
}

const XLScore: React.FC<IXLScore> = ({progress,change,animationDone,isLabelAnimationDone,r}) => {
    const brainColor = getCurrentColorForXLScore(progress); 

    
    return (
        <>
          <div className="relative flex justify-center items-center w-full h-full z-20">
                {/* <div className="absolute w-[100%] h-[100%]">
                  <BrainSVG color={brainColor} progress={progress} isLabelAnimationDone={isLabelAnimationDone}/>
                </div> */}
                
                <div id='introSelector1' className='myBox w-[80%] h-[80%] absolute bg-primary-color p-1 shadow-[0px_6px_12px_-3px_rgba(0,0,0,0.3)] rounded-full flex flex-col items-center justify-center'>
                     {
                        isLabelAnimationDone && <>
                                <div>
                                    <div style={{color: brainColor,fontSize: `${(r/100)*50}px`,lineHeight: `${(r/100)*50}px`}} className='text-center text-secondry-color/60 font-bold flex justify-center items-center'>
                                      <span id='dashboard-step-1' className='p-1' >{parseFloat(progress).toFixed(0)}</span>
                                    </div>
                                </div>
                                {
                                  animationDone && <>
                                        <div 
                                          style={{fontSize: `${(r/100)*14}px`,lineHeight: `${(r/100)*14}px`}} 
                                          className={`${change < 0 ? 'text-red-600':'text-green-600'} flex justify-center items-center gap-1`}
                                        >
                                          <div style={{height: `${(r/100)*13}px`}} className="flex items-center items-center gap-1">
                                            {parseFloat(change).toFixed(0)}%
                                            {
                                                change < 0 ? <ArrowDownRed className={"h-full"}/>:<ArrowUpGreen className={"h-full"}/>
                                            }
                                          </div>
                                        </div>
                                  </>
                                }
                        </>
                     } 
                </div> 
          </div>
        </>
    )
}


interface IQuadrants {
  radius: any;
  SVGHeight: any;
  SVGWidth: any;
  isBrainAnimate: any;
  labelsAnimationDuration: any;
}

const Quadrants: React.FC<IQuadrants> = ({radius,SVGHeight,SVGWidth,isBrainAnimate,labelsAnimationDuration}) => {
  
  const arcGenerator:any = arc().innerRadius(radius+10).outerRadius(radius+15);

  const secondLayerTextArc =  arc().innerRadius(radius+30).outerRadius(radius+30);

  const secondLayerTextArcBottom =  arc().innerRadius(radius+39).outerRadius(radius+39); 



  return (
    <>
      <g transform={`translate(${SVGWidth/2},${SVGHeight/2})`} >
          {
            QUADRANTS.map((quadrant,idx) => {
              const isBQ = idx===0 || idx===1;
              // const flt:any = isBQ? firstLayerTextArcBottom:firstLayerTextArc;
              const slt:any = isBQ? secondLayerTextArcBottom:secondLayerTextArc;
              const dur = isBrainAnimate? labelsAnimationDuration:0;
              const parentDur = dur*3;
              const delay = idx*parentDur;

              return <motion.g key={idx} transition={{duration: parentDur,delay: delay}} initial={{opacity: 0}} animate={{opacity: 1}}>
                  {/* quadrant arcs  */}
                  <motion.path filter="url(#shadow)"  transition={{duration: dur,delay: delay+0}} initial={{opacity: 0}} animate={{opacity: 1}} fill={idx === 0? "#dc6d60":idx === 1? "#ffa162":idx === 2? "#64e55a":"#eee164"} d={arcGenerator(quadrant.angles)}/>
                  
                  {/* first layer text  */}
                  {/* <path id={`flt${idx}`} fill="black" d={flt(quadrant.angles)}/>
                  <motion.text fill={ACCENT_COLOR} transition={{duration: dur,delay: delay+.1}} initial={{opacity: 0}} animate={{opacity: 1}}>
                    <textPath style={{fontSize: "12.5px"}} startOffset={isBQ? "75%":"25%"} textAnchor='middle' href={`#flt${idx}`}>
                        {quadrant.desc}
                    </textPath>
                  </motion.text> */}

                  {/* second layer text  */}
                  <path id={`slt${idx}`} fill="black" d={slt(quadrant.angles)}/>
                  <motion.text fill={SECONDRY_COLOR} transition={{duration: dur,delay: delay+.2}} initial={{opacity: 0}} animate={{opacity: 1}}>
                    <textPath className='text-sm' startOffset={isBQ? "75%":"25%"} textAnchor='middle' href={`#slt${idx}`}>
                        {quadrant.title.replace(" Flow","")}
                    </textPath>
                  </motion.text>

                  {/* {
                    SVGWidth > 700 && <Labels labels={quadrant.labels} radius={radius} isBrainAnimate={true} animDuration={dur} idx={idx} prevDelay={delay+.3}/>
                  } */}
              </motion.g>
            })
          }
      </g>

      {/* <g  transform={`translate(${SVGWidth/2},${SVGHeight/2})`}>
        <path id="curve" fill='transparent' d={adaptivePerformancePath()} />
        <text fill={SECONDRY_COLOR}>
          <textPath startOffset={"25%"} textAnchor='middle' href="#curve">
            Adaptive Performance
          </textPath>
        </text>
      </g>

      <g transform={`translate(${SVGWidth/2},${SVGHeight/2})`}>
        <path id="circlePath" fill="black" d={tacticalPerformancePath()}/>

        <text fill={ACCENT_COLOR}>
          <textPath startOffset={"75%"} textAnchor='middle' href="#circlePath">
              Tactical Performance
          </textPath>
        </text>
      </g> */}
    </>
  )
}


export function getCurrentColorForXLScore(value:any) {
  if (value < 0 || value > 100) {
    throw new Error('Value must be between 0 and 100');
  }  

  const getShade = (startColor:any, endColor:any, ratio:any) => {
    const hex = (start:any, end:any, ratio:any) => Math.round(start + ratio * (end - start)).toString(16).padStart(2, '0');
    const r = hex(parseInt(startColor.slice(1, 3), 16), parseInt(endColor.slice(1, 3), 16), ratio);
    const g = hex(parseInt(startColor.slice(3, 5), 16), parseInt(endColor.slice(3, 5), 16), ratio);
    const b = hex(parseInt(startColor.slice(5, 7), 16), parseInt(endColor.slice(5, 7), 16), ratio);
    return `#${r}${g}${b}`;
  };

  const colorRanges = [
    { min: 0, max: 25, startColor: '#dc6d60', endColor: '#dc6d60' }, // Dark red to red
    { min: 25, max: 50, startColor: '#ffa162', endColor: '#ffa162' }, // Dark orange to orange
    { min: 50, max: 75, startColor: '#64e55a', endColor: '#64e55a' }, // Dark green to green
    { min: 75, max: 100, startColor: '#eee164', endColor: '#eee164' } // Dark yellow to yellow
  ];

  for (const range of colorRanges) {
    if (value >= range.min && value <= range.max) {
      const ratio = (value - range.min) / (range.max - range.min);
      return getShade(range.startColor, range.endColor, ratio);
    }
  }
}











