export const APP_VERSION = "{{770}}";

const VERSION = 'api/v1';

let root = "";

if(window.location.hostname === "localhost"){
    root = "http://localhost:4000";
}
else if(window.location.host === "xlrate.10x.systems"){
    root = "https://tenxsurveycasestudy.herokuapp.com";
}
else {
    root = "https://xlratebeta-fd1a6dd1e692.herokuapp.com";
}


export const DOMAIN_NAME = `${root}/${VERSION}`;


export const XL_FACTORS = [
    "Fun",
    "Purpose",
    "Growth",
    "Emotives",
    "Utility",
    "Apathy",
    "Challenge",
    "SelfEfficacy",
    "Autonomy",
    "ClearGoal",
    "Feedback",
];

export const ACTIVITIES = ["work","relationship","hobby","study","sport","business"];

export const LOGIN_FIRST_TIME = "LOGIN_FIRST_TIME";
export const LOGIN_AFTER_FIRST_TIME = "LOGIN_AFTER_FIRST_TIME";
export const CHOOSE_THINGS_TO_IMPROVE = "CHOOSE_THINGS_TO_IMPROVE";
export const CHOOSE_GOAL_AND_ACHIVEMENTS = "CHOOSE_GOAL_AND_ACHIVEMENTS";
export const COMPLETE_SURVEY = "COMPLETE_SURVEY";
export const ADD_EVENT = "ADD_EVENT";

export const NUMBER_WITH_DAYS = {
    0: "S",
    1: "M",
    2: "T",
    3: "W",
    4: "T",
    5: "F",
    6: "S"
}

export type INUMBER_WITH_DAYS = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const NUMBER_AND_DAYS_MAPING = {
    0: {
        sort: "S",
        mid: "Sun",
        full: "Sunday",
    },
    1: {
        sort: "M",
        mid: "Mon",
        full: "Monday",
    },
    2: {
        sort: "T",
        mid: "Tue",
        full: "Tuesday",
    },
    3: {
        sort: "W",
        mid: "Wed",
        full: "Wednesday",
    },
    4: {
        sort: "T",
        mid: "Thu",
        full: "Thursday",
    },
    5: {
        sort: "F",
        mid: "Fri",
        full: "Friday",
    },
    6: {
        sort: "S",
        mid: "Sat",
        full: "Saturday",
    }
}

// FACTORS
export const FACTOR_COLORS = {
    XLSCORE: "#66FF00",
    FUN: "#F5A571",
    PURPOSE: "#73B5EB",
    GROWTH: "#86D1AC",
    EMOTIVES: "#F19B96",
    UTILITY: "#8A9AED",
    APATHY: "#8F7472",
    CHALLENGE: "#ff6666"
} 

export const SQUIRE_LOGO_URL = "https://res.cloudinary.com/dsnjr85ys/image/upload/v1704051668/XLRate_Logo_w_Tag_New_122023_1080sq_wr8svt.png";
export const RECT_LOGO_URL = "https://res.cloudinary.com/dsnjr85ys/image/upload/v1704211715/Copy_XLRate_new_logo_landscape_gewvxp.png";


export const GENDER_IDENTITIES = ["","Male","Female","Non-Binary","Genderqueer","Genderfluid","Agender","Bigender","Two-Spirit","Demiboy","Demigirl","Androgynous","Neutrois","Pangender","Third Gender","Multigender","Transgender","Cisgender","Questioning","Other"];

export const PRIMARY_COLOR = "#DDDDDD";
export const SECONDRY_COLOR = "#666666";
export const ACCENT_COLOR = "#333333";
export const PRIMARY_BUTTON_COLOR = "#001f3f";
export const SECONDRY_BUTTON_COLOR = "#39CCCC";

const gapBetweenQuadrant = .05;
export const QUADRANTS = [
    {
        title: "Emergent",
        desc: "Untapped Potential",
        angles: {
        startAngle: (Math.PI/2)+gapBetweenQuadrant,
        endAngle: Math.PI-gapBetweenQuadrant,
        },
        fill: '#d40404',
        labels: [
        {
            text: 'Lack of Goal Clarity',
            img: '',
            tooltip: "You possess significant potential to refine and clarify your goals, creating a clear direction that fuels your purpose and motivation.",
        },
        {
            text: 'Low Intrinsic Motivation',
            img: '',
            tooltip: "There's a vast reserve of internal motivation within you, waiting to be tapped into to energise your engagement and efforts.",
        },
        {
            text: 'Resistance to Change',
            img: '',
        tooltip: "You have the inherent ability to become more adaptable, turning new situations, processes, or ideas into opportunities for growth.",
        },
        {
            text: 'Fear of Failure',
            img: '',
            tooltip: "Your potential to reframe failure as a learning opportunity is immense, reducing apprehension and encouraging initiative.",
        },
        {
            text: 'Decision-Making Paralysis',
            img: '',
            tooltip: "You are well-equipped to overcome decision-making paralysis, with the capacity to navigate choices more decisively and confidently.",
        },
        {
            text: 'Emotional Exhaustion',
            img: '',
            tooltip: "There's a profound potential within you to replenish your emotional energy, fostering renewed interest and motivation.",
        },
        {
        text: 'Limited Self-Efficacy',
        img: '',
        tooltip: "You have an enormous latent potential to enhance your belief in your abilities, driving you to take action and achieve your goals.",
        },
        {
        text: 'Cognitive Overload',
        img: '',
        tooltip: "You have a lot of potential to reduce the impact of overwhelming information or demands on your capacity to engage or make decisions.",
        },
        ]
    },
    {
        title: "Focused",
        desc: "Tactical Potential",
        angles: {
        startAngle: Math.PI+gapBetweenQuadrant,
        endAngle: (Math.PI+(Math.PI/2))-gapBetweenQuadrant,
        },
        fill: '#ffa805',
        labels: [
        {
            text: 'Efficient Decision Making',
            img: '',
            tooltip: "Your current potential to make informed and timely decisions based on familiar contexts.",
        },
        {
            text: 'Operational Awareness',
            img: '',
            tooltip: "Your current potential in maintaining awareness of standard operations and workflow efficiency.",
        },
        {
            text: 'Consistent Skill Execution',
            img: '',
        tooltip: "Your current potential in reliably performing well-practised skills within routine tasks.",
        },
        {
            text: 'Effective Resource Management',
            img: '',
            tooltip: "Your current potential in organising and utilising resources efficiently in known operational settings.",
        },
        {
            text: 'Adaptability to Minor Changes',
            img: '',
            tooltip: "Your current potential in adjusting methods or approaches to minor variations in familiar procedures.",
        },
        {
            text: 'Routine Problem-Solving',
            img: '',
            tooltip: "Your current potential in applying known solutions to common routine operations issues.",
        },
        {
        text: 'Standard Risk Assessment',
        img: '',
        tooltip: "Your current potential in identifying and managing typical risks in standard tasks and processes.",
        }
        ]
    },
    {
        title: "Productive Flow",
        desc: "High Adaptive & Tactical Potential",
        angles: {
        startAngle: (Math.PI+(Math.PI/2))+gapBetweenQuadrant,
        endAngle: (Math.PI+(Math.PI))-gapBetweenQuadrant,
        },
        fill: '#26ff05',
        labels: [
        {
            text: 'Learning Agility',
            img: '',
            tooltip: "Your current potential in learning new tasks.",
        },
        {
            text: 'Cultural Flexibility',
            img: '',
            tooltip: "Your current potential for cultural adaptability.",
        },
        {
            text: 'Interpersonal Adaptability',
            img: '',
        tooltip: "The extent to which you can utilise your interpersonal adaptability skills.",
        },
        {
            text: 'Goal-Oriented Creativity',
            img: '',
            tooltip: "Your current potential in goal-oriented problem-solving.",
        },
        {
            text: 'Stress Management Capacity',
            img: '',
            tooltip: "Your current potential to handle stress.",
        },
        {
            text: 'Crisis Response Effectiveness',
            img: '',
            tooltip: "Your current potential to effectively manage a crisis.",
        },
        {
            text: 'Uncertainty Adaptability',
            img: '',
            tooltip: "Your current personal potential to adjust and effectively respond to physically demanding or changing environments.",
        },
        {
        text: 'Physical Adaptability',
        img: '',
        tooltip: "Your current potential for handling uncertain situations.",
        },
        ]
    },
    {
        title: "Creative Flow",
        desc: "High Adaptive & Lower Tactical Potential",
        angles: {
        startAngle: (Math.PI+(Math.PI))+gapBetweenQuadrant,
        endAngle: (Math.PI+(Math.PI)+(Math.PI/2))-gapBetweenQuadrant,
        },
        fill: '#ffe205',
        labels: [
        {
            text: 'Divergent Thinking',
            img: '',
            tooltip: "Your current potential in generating multiple, unique solutions to open-ended problems.",
        },
        {
            text: 'Conceptual Expansion',
            img: '',
            tooltip: "Your current potential in broadening perspectives and integrating disparate ideas into cohesive, innovative concepts.",
        },
        {
            text: 'Curiosity and Exploration',
            img: '',
        tooltip: "Your current potential in seeking new information, experiences, and insights to fuel creativity.",
        },
        {
            text: 'Risk-Taking in Idea Generation',
            img: '',
            tooltip: " Your current potential in proposing bold, unconventional ideas, even at the risk of failure.",
        },
        {
            text: 'Persistence Through Creative Blocks',
            img: '',
            tooltip: " Your current potential to maintain momentum in creative endeavours, even when faced with obstacles.",
        },
        {
            text: 'Collaborative Creativity',
            img: '',
            tooltip: "Your current potential in leveraging group dynamics to enhance idea generation and innovation.",
        },
        {
            text: 'Adaptive Expertise',
            img: '',
            tooltip: "Your current potential in applying deep, flexible knowledge to novel and complex problems.",
        },
        {
        text: 'Openness to Experience',
        img: '',
        tooltip: "Your potential to embrace new experiences, perspectives, and challenges to enrich creativity.",
        },
        ]
    },
];

export const TOOL_TIP_INDEX = 100;
export const MODALS_INDEX = 99;
export const NAV_BAR_INDEX = 98;


export  const EMOJIES = [
    {emoji: "😀", name: "Happy"},
    {emoji: "😔", name: "Pensive"},
    {emoji: "😳", name: "Embarrassed"},
    {emoji: "😫", name: "Overwhelmed"},
    {emoji: "😟", name: "Unhappy"},
    {emoji: "😎", name: "In the Zone"},
    {emoji: "😊", name: "Positive"},
    {emoji: "🥰", name: "Loved"},
    {emoji: "😧", name: "Worried"},
    {emoji: "😠", name: "Angry"},
    {emoji: "🤬", name: "Very angry"},
    {emoji: "​​😕", name: "Uncertain"},
    {emoji: "​😲​", name: "Surprised"},
    {emoji: "😔​​", name: "Sad"},
    {emoji: "🥱", name: "Tired"},
    {emoji: "😎", name: "Cool"},
    {emoji: "😐", name: "Ok-ish"},
    {emoji: "🥳", name: "Excited"},
    {emoji: "💪", name: "Motivated"},
    {emoji: "😫", name: "Stressed"},
    {emoji: "🥺", name: "Lonely"},
    {emoji: "🙏", name: "Grateful"},
    {emoji: "😲", name: "Surprised"},
    {emoji: "😤", name: "Frustrated"},
    {emoji: "😰", name: "Anxious"},
    {emoji: "🧐", name: "Deep Thought"},
    {emoji: "🌊", name: "In Flow"}
  ]

export type IFACTOR_COLORS = "XLSCORE" | "FUN" | "PURPOSE" | "GROWTH" | "EMOTIVES" | "UTILITY" | "APATHY" | "CHALLENGE";


export const GOAL_TYPES = ["moonshot_goal","milestone_goal","month_goal","week_goal","day_goal","current_goal"] as const;

export const GOALS_TYPES_OBJ = {
    moonshot_goal: "moonshot_goal",
    milestone_goal: "milestone_goal",
    month_goal: "month_goal",
    week_goal: "week_goal",
    day_goal: "day_goal",
    current_goal: "current_goal"
}