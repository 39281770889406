import React from 'react'


interface ISecondryButton {
  id?: string;
  children: JSX.Element[] | JSX.Element | string;
  onClick?: any;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const SecondryButton: React.FC<ISecondryButton> = ({id="",children,onClick,style={},disabled=false}) => {


  return (
    <button id={id} onClick={onClick} disabled={disabled} style={style} className={`bg-[#39CCCC] w-full px-2 text-base md:text-lg md:px-4 py-2 rounded-md text-white hover:shadow hover:shadow-[#39CCCC] focus:bg-[#39CCCC]/70 disabled:bg-[#39CCCC]/60 disabled:text-primary-color disabled:hover:shadow-none disabled:cursor-not-allowed active:bg-[#39CCCC]/5`}>
        {
            children && children
        }
        {
            !children && "Secondary Button"
        }
    </button>
  )
}

export {SecondryButton};