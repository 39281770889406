import { DOMAIN_NAME } from "../../constants";
import {fetchRequest} from "../../utils";



export const getCurrentPurpose = async (activityId: string) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/purposes/${activityId}`,"GET",{});

    return res;
}

interface IBody {
    name: string;
    dynamicProperties: Object;
    startDate: Date;
    activity: string;
}

export const createPurpose = async (body: IBody) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/purposes`,"POST",body);

    return res;
}



interface IBody1 {
    name: string;
    dynamicProperties: Object;
}

export const updatePurpose = async (purposeId: string,body: IBody1) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/purposes/${purposeId}`,"PUT",body);

    return res;
}
