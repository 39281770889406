import React, { useMemo } from 'react';
import { getCurrentColorForXLScore } from '../../../components/XLScoreDial';
import { getCurrentColorForFlowScore } from '../../../components/FlowScoreDial';

const text = `Please feel that you can speak to me… or use the wellbeing resources (link/phone number) if you would like more support…. Don’t forget that you can report unacceptable behaviours using our hotline….. You anonymity and privacy has been protected.`;

interface IHeatMap {
  histogramData: any;
  isFlowScore?: boolean;
}


export const HeatMap: React.FC<IHeatMap> = ({histogramData,isFlowScore}) => {
    histogramData = histogramData.map((item:any) => {return {...item,XLScore: item.XLScore,flowScore: item.flowScore}})
    // histogramData = dummyDataGenerator(10);
    const rows = useMemo(()=>Math.ceil(Math.sqrt(histogramData.length)),[histogramData]);

    

    if(histogramData.length === 0){
      return <>
          <div className="w-full h-full font-bold text-secondry-color/40 flex justify-center items-center"><span>Not Enough Data to Show</span></div>
      </>
    }

    if(isFlowScore){
      return <>
                <GridComponent rows={rows} >
                  {
                    histogramData && histogramData.map((item:any,i:number) => {
                      // console.log(item.XLScore);
                      return <div  key={i} className={`border hover:cursor-pointer relative`} title={item.flowScore < 50? text:Number(item.flowScore).toFixed(0)} style={{backgroundColor: getCurrentColorForFlowScore(item.flowScore)}} >
                                {
                                  item.streak && <div title={`How many days this individual has been below 50`} className="absolute top-1 right-1 w-5 h-5 rounded-full bg-blue-800 flex justify-center items-center text-[12px] text-white font-bold cursor-pointer">{item.streak}</div>
                                }
                            </div>
                    })
                  }
                </GridComponent>
              </>
    }  

    return (
          <>
            <GridComponent rows={rows} >
              {
                histogramData && histogramData.map((item:any,i:number) => {
                  // console.log(item.XLScore);
                  return <div  key={i} className={`border hover:cursor-pointer relative`} title={item.XLScore < 50? text:Number(item.XLScore).toFixed(0)} style={{backgroundColor: getCurrentColorForXLScore(item.XLScore)}} >
                            {
                              item.streak && <div title={`How many days this individual has been below 50`} className="absolute top-1 right-1 w-5 h-5 rounded-full bg-blue-800 flex justify-center items-center text-[12px] text-white font-bold cursor-pointer">{item.streak}</div>
                            }
                        </div>
                })
              }
            </GridComponent>
          </>
    )
}


const GridComponent = ({ rows,children }:{rows: number,children: any}) => {
  
  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(${rows}, 1fr)`, // Each column takes equal space
    gridTemplateRows: `repeat(${rows}, 1fr)`,    // Each row takes equal space
    width: '100%',
    height: '100%'
  };

  return (
    <div style={gridStyle}>
      {children}
    </div>
  );
};


// function dummyDataGenerator(length: number) {
//   // Function to generate a random number between min and max
//   const getRandomNumber = (min:any, max:any) => Math.floor(Math.random() * (max - min + 1)) + min;

//   // Function to create a dummy data array
//   const createDummyData = (length:number) => {
//     const dummyData = [];

//     for (let i = 0; i < length; i++) {
//       const data = {
//         XLScore: getRandomNumber(0, 100), // Random XLScore between 0 and 100
//         // flowScore: getRandomNumber(0, 100), // Random XLScore between 0 and 100
//         streak: getRandomNumber(0, 100), // Array of 15 random streak numbers between 0 and 10
//       };
//       dummyData.push(data);
//     }

//     return dummyData;
//   };

//   return createDummyData(length)
// }

