  import { QUADRANTS } from "../../../constants";
import { mapper__100_100_to_0_100 } from "../../../utils";
  import { valueAtPercentage } from "../../../utils";

  const getGreet = (XLScore) => {
    const XLScoreLocal = XLScore;
    if(Number(XLScoreLocal) <= 50){
      return "Ok";
    }

    if(Number(XLScoreLocal) <= 75){
      return "Great";
    }

    return "Awesome";

  }

  const getQuardrantLevel = (start,end,value) => {
    const val = valueAtPercentage(start,end,33.333);
    // console.log(start,end);
    // console.log(val);
    const frac = val - start;
    if(value < val){
      return "low"
    }
    if(value > val && value < (end-frac)){
      return "mid"
    }
    return "high"
  }

  const getCurrentFlow = (XLScore,brainColor) => {
    const XLScoreLocal = XLScore;

    if(Number(XLScoreLocal) <= 25){
      const temp = getQuardrantLevel(0,25,Number(XLScoreLocal));
      return {
          quadrant: "Emergent",
          quadrantLevel: temp,
          quadrantLevel1: <>This means you have <span style={{backgroundColor: brainColor}} className={`text-black px-1 rounded-md mx-1`}>{temp === "low"? "vast":temp === "mid"? "huge":"significant"} untapped tactical and adaptive potential </span> but may currently:</>,
          quadrantLevel2: <>This means they have <span style={{backgroundColor: brainColor}} className={`text-black px-1 rounded-md mx-1`}>{temp === "low"? "vast":temp === "mid"? "huge":"significant"} untapped tactical and adaptive potential </span> but may currently:</>
      }
    }
    if(Number(XLScoreLocal) <= 50){
      const temp = getQuardrantLevel(26,50,Number(XLScoreLocal));
      return {
        quadrant: "Focused",
        quadrantLevel: temp,
        quadrantLevel1: <>This means you have <span style={{backgroundColor: brainColor}} className={`text-black px-1 rounded-md mx-1`}>{temp === "low"? "Limited":temp === "mid"? "competent":"advanced"} tactical, but low adaptive potential </span> for:</>,
        quadrantLevel2: <>This means they have <span style={{backgroundColor: brainColor}} className={`text-black px-1 rounded-md mx-1`}>{temp === "low"? "Limited":temp === "mid"? "competent":"advanced"} tactical, but low adaptive potential </span> for:</>
      }
    }
    if(Number(XLScoreLocal) <= 75){
      const temp = getQuardrantLevel(51,75,Number(XLScoreLocal));
      return {
        quadrant: "Productive Flow",
        quadrantLevel: temp,
        quadrantLevel1: <>This means you have <span style={{backgroundColor: brainColor}} className={`text-black px-1 rounded-md mx-1`}>{temp === "low"? "good":temp === "mid"? "great":"outstanding"} adaptive and tactical potential </span> for:</>,
        quadrantLevel2: <>This means they have <span style={{backgroundColor: brainColor}} className={`text-black px-1 rounded-md mx-1`}>{temp === "low"? "good":temp === "mid"? "great":"outstanding"} adaptive and tactical potential </span> for:</>
      }
    }
    const temp = getQuardrantLevel(76,100,Number(XLScoreLocal));
    return {
      quadrant: "Creative Flow",
      quadrantLevel: temp,
      quadrantLevel1: <>This means you have <span style={{backgroundColor: brainColor}} className={`text-black px-1 rounded-md mx-1`}>{temp === "low"? "good":temp === "mid"? "great":"outstanding"} adaptive but lower tactical potential </span> for:</>,
      quadrantLevel2: <>This means they have <span style={{backgroundColor: brainColor}} className={`text-black px-1 rounded-md mx-1`}>{temp === "low"? "good":temp === "mid"? "great":"outstanding"} adaptive but lower tactical potential </span> for:</>
    }
  }

  const getCurrentQuardForXLScore = (XLScore) => {
    XLScore = Number(XLScore);

    if(XLScore <= 25){
      const temp = getQuardrantLevel(0,25,Number(XLScore));
      return `${temp} emergent`;
    }
    if(XLScore <= 50){
      const temp = getQuardrantLevel(26,50,Number(XLScore));
      return `${temp} focused`;
    }
    if(XLScore <= 75){
      const temp = getQuardrantLevel(51,75,Number(XLScore));
      return `${temp} productive flow`;
    }
    const temp = getQuardrantLevel(76,100,Number(XLScore));
    return `${temp} creative flow`;
  }

  const getCurrentQuardForFlowScore = (flowScore) => {
    flowScore = Number(flowScore);

    if(flowScore <= 20){
      return "low flow"
    }
    if(flowScore <= 40){
      return "below average flow"
    }
    if(flowScore <= 60){
      return "moderate flow"
    }
    if(flowScore <= 80){
      return "high flow"
    }
    return "optimal Flow"
  }

  const getFeedBackMessage = (XLScore) => {
    const XLScoreLocal = mapper__100_100_to_0_100(XLScore);
    if(Number(XLScoreLocal) < 25){
      return "Ok, you are in a tough place right now, but the good news is, you've got stacks of unrealised potential!"
    }
    if(Number(XLScoreLocal) < 39){
      return "Ok, not bad, but you still have incredable potential"
    }
    if(Number(XLScoreLocal) < 59){
      return "You are progressing, but have even more potential to unlock"
    }
    if(Number(XLScoreLocal) < 75){
      return "You are rocking it"
    }
    return "Wow, you fully able to unlock your creative potential right now"
  }


  const getLabels = (XLScore) => {
    const XLScoreLocal = mapper__100_100_to_0_100(XLScore);
    if(XLScoreLocal <= 25){
      return QUADRANTS[0].labels;
    }
    if(XLScoreLocal <= 50){
      return QUADRANTS[1].labels;
    }
    if(XLScoreLocal <= 75){
      return QUADRANTS[2].labels;
    }
    return QUADRANTS[3].labels;
  }

  export const getCurrentStateData = (XLScore,flowScore,brainColor) => {
    const greetMessage = getGreet(XLScore);
    const currentFlow = getCurrentFlow(XLScore,brainColor);
    const XLScoreQuadrant = getCurrentQuardForXLScore(XLScore);
    const flowScoreQuadrant = getCurrentQuardForFlowScore(flowScore);
    const feedbackMessage = getFeedBackMessage(XLScore);
    const labels = getLabels(XLScore);

    return {
        greetMessage,
        currentFlow,
        feedbackMessage,
        labels,
        XLScoreQuadrant,
        flowScoreQuadrant
    }
  }
