import React, { useEffect } from 'react';
import "./App.css";
import { Navbar } from './layout';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  getLoggedInUser,
  setIsMenuOpen
} from "./Store/actions";
import { useDispatch, useSelector } from 'react-redux';
import { CustomRoutes } from "./components";
import {
  ActionChallenges,
  CompletedSurveys,
  CreateSurvey,
  Groups,
  Login,
  Signup,
  Surveys,
  // UserDashboard,
  Users,
  AddUsers,
  Home,
  PageNotFound,
  ManagementDashboard,
  UserSurveySelection,
  UniversalSurveyTitle,
  ForgetPassword,
  ResetPassword,
  PurposeAndGoal,
  RemaningSurveys,
  UnRegularCompletedSurveys,
  CreatePrePostSurvey,
  OnBoarding,
  ManageNotifications,
  TermsAndConditions,
  PrivacyPolicy,
  CookiePolicy,
  WaitingList,
  LandingPageA,
  LandingPageB,
  Footer,
  Profile,
  BetaUserDashboard
} from "./pages";

import { generateFireBaseToken } from './firebase';


export function App() {
  const dispatch:any = useDispatch();
  const loggedInUser = useSelector((state: any) => state.loggedInUser);

  const handleClick = (e: any) => {
    if (e.target?.id !== "hem" && e.target?.id !== "plus" && e.target?.id !== "reset-test-user") {
      dispatch(setIsMenuOpen(false));
    }
  };

  

  useEffect(() => {
    if (!loggedInUser) {
      dispatch(getLoggedInUser());
    }
  }, [dispatch, loggedInUser]);

  useEffect(() => {
    if (!loggedInUser) {
      return;
    }

    (async () => {
      await generateFireBaseToken();
    })();
  }, [loggedInUser]);

  // useEffect(() => {
  //   window.addEventListener('message', (event) => {
  //     // const { authToken, userId } = event.data;
  //     // console.log(event.data);
      
  //     // Ensure the message is from a trusted source
  //     // if (event.origin === 'https://parent-website.com') {
    
  //       // Store data locally for future requests
  //       // localStorage.setItem('authToken', authToken);
  //       // localStorage.setItem('userId', userId);
  //     // }
  //   });
  // }, []);

  return (
    <div
      onClick={handleClick}
      className={`${window.location.pathname === "/90-day-peak-performance" || window.location.pathname === "/waiting-b" ? "min-h-[100vh]" : "min-h-[calc(100vh-64px)]"} bg-primary-color`}
    >
      <BrowserRouter>
        <Routes>
          {/* admin routes */}
          <Route element={<CustomRoutes.AdminRoutes />}>
            <Route path='/admin/manage/users' element={<><Navbar /><Users /></>} />
            <Route path='/admin/manage/surveytitle' element={<><Navbar /><Surveys /></>} />
            <Route path='/admin/surveys/completed-surveys' element={<><Navbar /><CompletedSurveys /></>} />
            <Route path='/admin/add/user' element={<><Navbar /><AddUsers /></>} />
            <Route path='/admin/manage/universal/surveytitle' element={<><Navbar /><UniversalSurveyTitle /></>} />
            <Route path='/admin/un-regular-completed-surveys' element={<><Navbar /><UnRegularCompletedSurveys /></>} />
            <Route path='/admin/waiting-list' element={<><Navbar /><WaitingList /></>} />
          </Route>

          {/* manager routes */}
          <Route element={<CustomRoutes.ManagerRoutes />}>
            <Route path='/admin/manage/groups' element={<><Navbar /><Groups /></>} />
            <Route path='/dashboard/management' element={<><Navbar /><ManagementDashboard /></>} />
          </Route>

          {/* private routes */}
          <Route element={<CustomRoutes.PrivateRoutes />}>
            <Route path='/user-survey-selection' element={<><Navbar /><UserSurveySelection /></>} />
            <Route path='/on-boarding' element={<><Navbar /><OnBoarding /></>} />
            <Route path='/purpose-and-goal' element={<><Navbar /><PurposeAndGoal /></>} />
            <Route path='/uncompleted-surveys' element={<><Navbar /><RemaningSurveys /></>} />
            <Route path='/survey/:userId/:surveyId/:time' element={<><Navbar /><CreateSurvey /></>} />
            <Route path='/my-dashboard' element={<><Navbar /><BetaUserDashboard /></>} />
            {/* <Route path='/my-beta-dashboard' element={<><Navbar /><BetaUserDashboard /></>} /> */}
            <Route path='/pre-post-survey/:userId/:surveyId' element={<><Navbar /><CreatePrePostSurvey setScreen={()=>{}}/></>} />
            <Route path='/action-challenges' element={<><Navbar /><ActionChallenges /></>} />
            <Route path='/' element={<><Navbar /><Home /></>} />
            <Route path='/manage/notifications' element={<><Navbar /><ManageNotifications /></>} />
            <Route path='/profile' element={<><Navbar /><Profile /></>} />
          </Route>

          {/* public routes */}
          <Route path='/login' element={<><Navbar /><Login /></>} />
          <Route path='/signup' element={<><Navbar /><Signup /></>} />
          <Route path='/forget-password' element={<><Navbar /><ForgetPassword /></>} />
          <Route path='/reset-password' element={<><Navbar /><ResetPassword /></>} />
          <Route path='/terms-and-conditions' element={<><TermsAndConditions /></>} />
          <Route path='/privacy-policy' element={<><Navbar /><PrivacyPolicy /></>} />
          <Route path='/cookie-policy' element={<><Navbar /><CookiePolicy /></>} />
          <Route path='/90-day-peak-performance' element={<><LandingPageA /></>} />
          <Route path='/waiting-b' element={<><LandingPageB /></>} />
          <Route path='*' element={<><Navbar /><PageNotFound /></>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
