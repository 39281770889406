import React,{useEffect,useRef,useState} from 'react';
import {
  getManagementDashboardData
} from "../../Store/actions";
import { toast } from 'react-toastify';
import {
  Factors,
  Loading,
  PrimaryButton,
  InfoIconWithToolTip,
  EventModal,
  ActionChallengeModal,
  ShadowBox,
  SecondryButton,
  XLScoreDial,
  FlowScoreDial
} from "../../components";
import { useSelector } from 'react-redux';
import { FACTOR_COLORS, XL_FACTORS } from '../../constants';
import { 
  mapper__100_100_to_0_100,
  // allSurveysWithXLScoresToHistoricalSPQ,
  OPQCalculator
} from '../../utils';

import {
  ActionChallenge,
  // Users,
  HeatMap,
  // useLeadershipText
} from "./components";
import { Chart as ChartJS} from "chart.js/auto";
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Main } from '../../layout';
// import 'react-dropdown-tree-select/dist/styles.css';
// import { CircularProgressbarWithChildren,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {useScrollToTop} from "../../hooks";
import { getCurrentStateData } from '../BetaUserDashboard/utils';
import dayjs from 'dayjs';
import isBetween from "dayjs/plugin/isBetween";
import { RootState } from '../../Store/store';
// import { CircularProgressBar } from './components/CircularProgressBar';
import {Groups} from './components/Groups';
import { MyChart } from './components/MyChart';
import { useLocation } from 'react-router-dom';
import { mapper_0_100_to__100_100 } from '../../utils/mapper_0_100_to__100_100';
import { getCurrentColorForXLScore } from '../../components/XLScoreDial';
import { getCurrentColorForFlowScore } from '../../components/FlowScoreDial';

dayjs.extend(isBetween);
ChartJS.register(zoomPlugin);
ChartJS.register(annotationPlugin);


const Index: React.FC<any> = () => {
    const location = useLocation();
    const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
    const [group, setGroup] = useState<any>(null);
    const [onlyOneUserHaveDone, setOnlyOneUserHaveDone] = useState(false);
    const [allUserNames,setAllUsersName] = useState<any>(null);
    const [groups, setGroups] = useState(null);
    const [data, setData] = useState<any>(null);
    const [index, setIIndex] = useState(0);
    const [selectedGroup, setSelectedGroup] = useState("");
    useScrollToTop();
 
    
    useEffect(() => {
      if(group?.histogramData?.length <= 1){
        setOnlyOneUserHaveDone(false);   
      }
    }, [group]);

    useEffect(() => { 
      (async ()=> {
          if(data){
            if(data.users?.length > 0){
              const res = await getManagementDashboardData(data.users,'',undefined);


              if(res.success){
                setAllUsersName(()=> {
                  return [...data.usersDetails].map(u => `${u.forename} ${u.surname}`);
                })
                setGroup({...res,groupname: data.groupname,users:data.users,surveyTitle: data.userSurveys,factorsMinMax: res.factorsMinMax});
                // if(dataFetched){
                //   dispatch({type: SET_SURVEY_NAME,payload: location?.state?.selectedSurveyNames || ""});
                //   setDataFetched(true);
                // }
                // if(!userSurveyNames){
                //   setUserSurveyNames(location.state.userSurveys);
                // }
                setIIndex(res.allData.length-1);
              }
              else {
                toast.error(res.message);
              }
            }
            else {
              setData(null);
              toast.error("The selected group has no users.");
            }
          }
      })()
    }, [data]);

    useEffect(() => {
      if(location?.state?.users){
        const d = {
          groupname: location?.state?.groupname,
          userSurveys: location?.state?.userSurveys,
          users: location?.state?.users,
          usersDetails: [...location?.state?.usersDetails],
        };
        setData(d);
        setSelectedGroup(location?.state?.groupId);
      }
    }, [location]);



    if(!data){
      return <><Main>
               <Groups selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} setData={setData} groups={groups} setGroups={setGroups}/>
               <div className='min-h-[50vh] flex justify-center items-center'>
                  <p className='text-secondry-color'>Please select a group to view the leadership dashboard.</p>
               </div>
             </Main>
             </>
    }



    if(!group || !loggedInUser){
      return <Main>
               <Groups selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} setData={setData} groups={groups} setGroups={setGroups}/>
               <Loading/>
             </Main>
    }

    
    return <>
      <Main fullWidth={true}>
        <Dashboard 
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            setData={setData}
            groups={groups}
            setGroups={setGroups} 
            users={data?.users} 
            index={index} 
            setIndex={setIIndex} 
            allData={group.allData} 
            allSurveysWithXLScores={group.allSurveysWithXLScores}  
            loggedInUser={loggedInUser} 
            factorsMinMax={group.factorsMinMax} 
            onlyOneUserHaveDone={onlyOneUserHaveDone} 
            groupname={data?.groupname} 
            historicalData={group.historicalData} 
            myEvents={group.events} 
            myActionChalenges={group.actionChallenges} 
            allUserNames={allUserNames}
        />
      </Main>
    </>
}

export default Index;


interface IDashboard {
  users: any;
  index: any;
  setIndex: any;
  myEvents: any;
  myActionChalenges: any;
  allSurveysWithXLScores: any;
  allData: any;
  factorsMinMax: any;
  loggedInUser: any;
  groupname: any;
  onlyOneUserHaveDone: any;
  allUserNames: any;
  historicalData: any;
  selectedGroup: any;
  setSelectedGroup: any;
  setData: any;
  groups: any;
  setGroups: any;
}

const Dashboard: React.FC<IDashboard> = ({selectedGroup,setSelectedGroup,setData,groups,setGroups,users,index,setIndex,myEvents,myActionChalenges,allSurveysWithXLScores,allData,factorsMinMax,loggedInUser,groupname,onlyOneUserHaveDone,allUserNames,historicalData}) => {
  
  const group = allData[index];
  // group.currentXLScore = -10;
  // const [dateOfTheCurrentXLScore, setDateOfTheCurrentXLScore] = useState(group.dateOfTheCurrentXLScore);
  const [brushSelection, setBrushSelection] = useState<any>(null);
  const SELECTED_RANGES = brushSelection? allData.map((item:any,index:number) => {return {...item,index}}).filter((item:any) => dayjs(item.dateOfTheCurrentXLScore).isBetween(dayjs(brushSelection.range[0]),dayjs(brushSelection.range[1]))):allData;
  const SUM_OF_XL_SCORES = SELECTED_RANGES.reduce((a:any,b:any)=> a + b.currentXLScore,0)/SELECTED_RANGES.length;
  const MOST_FREQUENT_FACTORS = useFrequencyOfFactorsCalculator(SELECTED_RANGES);
  const allSelectedSurveysWithXLScores = allSurveysWithXLScores.filter((survey:any) => dayjs(survey.createdAt).isBetween(dayjs(SELECTED_RANGES[0]?.dateOfTheCurrentXLScore), dayjs(SELECTED_RANGES[SELECTED_RANGES.length-1]?.dateOfTheCurrentXLScore), null, '[]'))
  const OPQ = OPQCalculator(allSelectedSurveysWithXLScores,"XLScore");
  // const historicalOPQ = allSurveysWithXLScoresToHistoricalSPQ(allSurveysWithXLScores);
  // const currentSurveys = allSurveysWithXLScores.filter((item:any) => dayjs(item?.allSurveysWithXLScores).format("DD-MM-YYYY") === dayjs(group?.allSurveysWithXLScores).format("DD-MM-YYYY"))
  // const currentOPQ = OPQCalculator(currentSurveys,"XLScore");
  const NEXT_DISABLED = index === null || index === allData.length-1;
  const PREV_DISABLED = index === 0;
  const XLScoreHistoricalDataWithStreak = useCalculateXLScoreStreakOfUser(allData,group,index);
  const flowScoreHistoricalDataWithStreak = useCalculateFlowScoreStreakOfUser(allData,group,index);
  const [showActionChallengePopup, setShowActionChallengePopup] = useState(false);
  const [currentActionChallenge, setCurrentActionChallenge] = useState(null);
  const toltipText = `(Caution: this is a Beta feature!) A indicator of performance states that strike the balance between innovation and deliverable solution. Higher scores suggest that performance is more dynamic, possibly reflecting varying levels of innovation, creativity, or adaptability in approaching the problem. Early data indicates a strong correlation between high scores and performance output (i.e. productive value) Note:  and higher scores may also imply inconsistency in performance, which could either be a sign of creative experimentation or lack of a steady approach). Lower scores indicate a more tactical, steady approach to performance with less variability in creativity or innovation. Initial data indicates lower scores have a strong correlation with performance outcomes witt less productive value.`;
  const [isOpenAddEventsModal, setIsOpenAddEventsModal] = useState(false);
  const [isUpdateEvent, setIsUpdateEvent] = useState(false);
  const [eventData, setEventData] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [events, setEvents] = useState(myEvents);
  const [streamGraphChartData, setstreamGraphChartData] = useState(()=> {
      return {
                labels: historicalData.map((item:any) => new Date(item.year)),
                datasets: [
                    {
                      type: "scatter",
                      label: "Events",
                      data: myEvents?.map((ev:any) => {return {x: new Date(ev.date),y:-85,description: ev.description,tags: ev.tags}}),
                      borderColor: "#db03fc",
                      backgroundColor: "#db03fc",
                      stack: 'combined',
                    },
                    {
                      type: "scatter",
                      label: "Action Challenges",
                      data: myActionChalenges?.map((ac:any) => {return {x: new Date(ac.createdAt),y:-95,steps: ac.steps,actionChallenge: ac.actionChallenge}}),
                      borderColor: "#4F46E5",
                      backgroundColor: "#4F46E5",
                      stack: 'combined',
                    }
                ]
            }
  });
  const [chart, setChart] = useState<any>(null);
  useEventsChangeEffect({events,setstreamGraphChartData});
  useHistoricalDataChangeEffect({historicalData,setstreamGraphChartData});
  const currentColorForXLScore = getCurrentColorForXLScore(group.currentXLScore)
  const currentColorForFlowScore = getCurrentColorForFlowScore(group.currentXLScore);
  const state = getCurrentStateData(group.currentXLScore,group.currentFlowScore,currentColorForXLScore);
  const XlScoreContainerRef = useRef(null);
  const flScoreContainerRef = useRef(null);
  
  // console.log(group.currentFlowScore);
  

  const handleKeyDown = (e:any) => {
      e.preventDefault();
      if(e.key === "ArrowLeft" && !PREV_DISABLED){
        handlePrev();
      }
      if(e.key === "ArrowRight" && !NEXT_DISABLED){
        handleNext();
      }
  }

  const handlePrev = () => {
      setIndex((prev:any) => {
        if(prev === 0){
            return 0;
        }
        return prev-1;
      });
      


      const newDate = new Date(allData[index-1].dateOfTheCurrentXLScore);
      chart.options.plugins.annotation.annotations.line1.xMin = newDate;
      chart.options.plugins.annotation.annotations.line1.xMax = newDate;
      chart.update();

      const selection = {
          range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
      };
      setBrushSelection(selection);
  }

  const handleNext = () => {
    setIndex((prev:any) => {
        if(prev === allData.length-1){
            return allData.length-1;
        }
        return prev+1;
    })
 
    // if(allData[index+2].dateOfTheCurrentXLScore && chart.getZoomLevel() !== 1){
    //     const startDate = new Date(chart.scales.x.min).setDate(new Date(chart.scales.x.min).getDate()+1);
    //     const endDate = new Date(allData[index+2].dateOfTheCurrentXLScore).getTime();
    //     chart.zoomScale('x', {min: startDate, max: endDate}, 'none');
    // }
    
    const newDate = new Date(allData[index+1].dateOfTheCurrentXLScore);
    chart.options.plugins.annotation.annotations.line1.xMin = newDate;
    chart.options.plugins.annotation.annotations.line1.xMax = newDate;
    chart.update();

    const selection = {
        range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
    };
    setBrushSelection(selection);
  }


  const handleActionChallengeClick = (ac:any) => {
    setCurrentActionChallenge(ac);
    setShowActionChallengePopup(true);
  }
  

  return (<>
    <>
       {
        group &&  <>       

        <div className='flex gap-1'>
                      <Groups selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} setData={setData} groups={groups} setGroups={setGroups}/>
                      {
                        (dayjs(group?.dateOfTheCurrentXLScore).format("DD-MM-YYYY") !== dayjs().format("DD-MM-YYYY")) && <>
                          <div className='flex border border-secondry-color/20 rounded-md p-2 mt-1'>
                            <p className='text-blue-600 text-sm text-center'>
                              <span className='font-bold'>Important:</span> This data is from { dayjs(group?.dateOfTheCurrentXLScore).format("DD-MM-YYYY")}.
                            </p>
                          </div>
                          </>
                      }
        </div>

        <div tabIndex={0} onKeyUp={handleKeyDown} className='pb-8 relative mt-2 flex flex-col gap-4 md:min-h-[1050px]'>
              {/* upper section  */}
              <div className="w-full flex justify-center flex-wrap gap-4 h-[60%]">
                <div className='w-[270px] flex flex-col gap-4'>
                  <ShadowBox>
                      {/* <div className='flex mb-4 gap-2'>
                        <Users allUserNames={allUserNames}/>
                      </div> */}
                  
                      <h4 className='text-xl mb-[14px] font-bold text-center text-secondry-color'>XL Score</h4>
                      <div ref={XlScoreContainerRef} className='flex justify-center items-center'>
                        <XLScoreDial containerRef={XlScoreContainerRef} XLScore={group?.currentXLScore} XLChange={group?.XLChange}/>
                      </div>
            
            
                      {/* {
                          (dayjs(group?.dateOfTheCurrentXLScore).format("DD-MM-YYYY") !== dayjs().format("DD-MM-YYYY")) && <>
                          <div className='mt-2 border border-secondry-color/20 rounded-md p-2'>
                            <p className='text-blue-600 text-[12px] text-center'>
                              <span className='font-bold'>Important:</span> This data is from { dayjs(group?.dateOfTheCurrentXLScore).format("DD-MM-YYYY")}.
                            </p>
                          </div>
                          </>
                      } */}
                  </ShadowBox>
                  <ShadowBox>
                      <h4 className='flex items-center gap-2 text-secondry-color'>XL Heatmap ({group?.histogramData?.length}/{users?.length}) <InfoIconWithToolTip content="XL scores/Team Members"/></h4>
                      <div className='h-[170px] lg:h[100%]'>
                        <HeatMap isFlowScore={false} histogramData={XLScoreHistoricalDataWithStreak}/>
                      </div>
                  </ShadowBox>
                </div>
                <div className="w-[270px] flex flex-col gap-4">
                  {/*flow score  */}
                  <ShadowBox className='flex flex-col'>
                        <h4 className='mb-4 text-xl mb-1 font-bold text-center text-secondry-color'>Flow Score</h4>
                        <div ref={flScoreContainerRef} className='flex justify-center items-center'>
                          <FlowScoreDial containerRef={flScoreContainerRef} XLScore={group?.currentFlowScore} XLChange={group?.flowChange}/>
                        </div>
                  </ShadowBox> 

                  {/* Heatmap box  */}
                  <ShadowBox className='flex flex-col'>
                    <h4 className='flex items-center gap-2 text-secondry-color'>Flow Heatmap ({group?.histogramData?.length}/{users?.length}) <InfoIconWithToolTip content="XL scores/Team Members"/></h4>
                    <div className='h-[170px] lg:h[100%]'>
                      <HeatMap isFlowScore={true} histogramData={flowScoreHistoricalDataWithStreak}/>
                    </div>
                  </ShadowBox> 
                </div>
                <ShadowBox className='flex-1 min-w-[270px]'>
                    <h3 className="text-sm font-bold text-secondry-color">{groupname} XLRate score changed by <span style={{color: group?.XLChange>0? "#00ff00":"#ff0000"}}>{parseFloat(group?.XLChange).toFixed(0)}%</span></h3>

                    <p className="text-xs text-secondry-color mt-2">They are currently in a <span style={{backgroundColor: currentColorForXLScore}} className={`text-black px-1 rounded-md`}>{state.XLScoreQuadrant}</span> and <span style={{backgroundColor: currentColorForFlowScore}} className={`text-black text-white px-1 rounded-md`}>{state.flowScoreQuadrant}</span> state.</p>
              
                    <p className="text-xs text-secondry-color mt-2">{state.currentFlow.quadrantLevel2}</p>

                    {
                        state.labels.map((obj:any,idx:number) => <SecondryButton key={idx} style={{marginTop: "10px",padding: "3px 3px",backgroundColor: currentColorForXLScore,color: "#1f1f1f",fontSize: "12px"}}><span className='mr-1'>{obj.text}</span><InfoIconWithToolTip id="asfd" content={obj.tooltip}/></SecondryButton>)
                    }
                </ShadowBox>
                <ShadowBox className='flex-1'>
                    <h5 className='relative z-20 text-sm font-bold text-secondry-color'>Team XL Factors <InfoIconWithToolTip place="bottom" content="These are the key influences shaping the teams XL Score, a dynamic blend of drivers and constrainers that influence their well-being and performance."/></h5>
                    <Factors 
                      Fun={group.factors?.Fun} 
                      Purpose={group.factors?.Purpose} 
                      Growth={group.factors?.Growth} 
                      Emotives={group.factors?.Emotives} 
                      Utility={group.factors?.Utility} 
                      Apathy={group.factors?.Apathy}
                      factorsMinMax={factorsMinMax}
                      percentageOfChangeOfFactors={group.percentageOfChangeOfFactors}
                    />
                </ShadowBox>
                <ShadowBox className='flex-1'>
                    <div className="text-secondry-color">
                        {/* <h6>What kind of leader are you right now & how can you improve?</h6> */}
                        <div className='rounded-lg'>
                                <p className="text-xs font-bold">{group.leadershipStyle.title.replace("[team_name]", groupname)}</p>
                                {
                                  group.leadershipStyle.description.map((desc:any,index:number) => {
                                    return <p key={index} className='text-xs mb-3'>{desc}</p>
                                  })
                                }
                        </div>
                    </div>

                    <div className='border-t-2 border-secondry-color/20'>
                      {
                          group?.higestDiffrance && <ActionChallenge mixPanelEvent={()=>{}} currentActionChallanges={group.currentActionChallengesWithSteps} higestDiffrance={group?.higestDiffrance} forTeam={true} handleActionChallengeClick={handleActionChallengeClick}/>
                      }
                    </div>
                </ShadowBox>
              </div>

              {/* lower section  */}
              <ShadowBox className='w-full'>
                      <div className='w-full h-[250px]'>
                        <MyChart
                          setCurrentIndex={setIndex}
                          setBrushSelection={setBrushSelection}
                          chart={chart}
                          setChart={setChart}
                          streamGraphChartData={streamGraphChartData}
                          events={events}
                          setEventData={setEventData}
                          setEventId={setEventId}
                          setIsUpdateEvent={setIsUpdateEvent}
                          setIsOpenAddEventsModal={setIsOpenAddEventsModal}
                          />
                      </div>
                      <div className="relative top-2 right-2 flex gap-2 justify-end items-center">
                        <div className="text-center">{dayjs(group.dateOfTheCurrentXLScore).format("DD-MM-YYYY")}</div>
                        <PrimaryButton style={{width: "150px",padding: "5px 1px"}} onClick={()=>setIsOpenAddEventsModal(true)}>Add Event</PrimaryButton>
                        <PrimaryButton style={{width: "70px",padding: "5px 1px"}} disabled={PREV_DISABLED} onClick={handlePrev}>Prev</PrimaryButton>
                        <PrimaryButton style={{width: "70px",padding: "5px 1px"}} disabled={NEXT_DISABLED} onClick={handleNext}>Next</PrimaryButton>
                      </div>
                      <div className="mt-4">
                          <div className="p-2 rounded-md shadow-sm bg-gray-200 min-w-[100px] min-h-[100px]">
                              <div className="">
                                  <span className='font-bold'>Selected Range:</span>
                                  <span className='ml-[60px]'>{dayjs(SELECTED_RANGES[0]?.dateOfTheCurrentXLScore).format("DD/MM/YYYY")}</span>
                                  <span className='mx-1'>-</span>
                                  <span>{dayjs(SELECTED_RANGES[SELECTED_RANGES.length-1]?.dateOfTheCurrentXLScore).format("DD/MM/YYYY")}</span>
                              </div>
                              <div className="">
                                  <span className='font-bold'>Average XLScore:</span>
                                  <span className='ml-12'>{parseFloat(`${mapper__100_100_to_0_100(SUM_OF_XL_SCORES)}`).toFixed(2)}</span>
                              </div>
                              <div className="">
                                  <span className='font-bold'>Highest freq Factor/s:</span>
                                  <span className='ml-5'>{MOST_FREQUENT_FACTORS}</span>
                              </div>
                              <div className="">
                                  <span className='font-bold'>Optimal Performance Quotient <InfoIconWithToolTip content={toltipText} size={10} className="mb-2"/>:</span>
                                  <span className='ml-5'>{parseFloat(`${OPQ}`).toFixed(2)}</span>
                              </div>
                          </div>
                      </div>
              </ShadowBox>
        </div>
        
        </>
       }
        <EventModal    
          isOpen={isOpenAddEventsModal}
          setIsOpen={setIsOpenAddEventsModal}
          userId={loggedInUser._id}
          isUpdate={isUpdateEvent}
          data={eventData}
          setEvents={setEvents}
          eventId={eventId}
          isManagemaneEvent={true}
          surveyTitle={null}
          setIsPhySelfieChanged={false}
          surveyName=''
        />
        {
          (onlyOneUserHaveDone && loggedInUser.role === "admin") && <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[400px] bg-gray-500 text-white p-10 rounded-md font-bold text-center text-xl z-40'>XLrate does not have enough data yet to preserve the anonymity of individuals in this group at this time. Please come back later.</div>
        }
        <ActionChallengeModal currentActivityName={""} show={showActionChallengePopup} setShow={setShowActionChallengePopup} currentActionChallenge={currentActionChallenge} factor={group?.higestDiffrance?.factor} userId={loggedInUser._id} forTeam={true}/>
    </>
  </>)
}

function useFrequencyOfFactorsCalculator(selectedRanges:any){
  const FREQUENCY_OF_FACTORS:any = {}
  const [incDec] = useIsIncreaseOrDicrease();

  selectedRanges.forEach((item:any) => {
      if(FREQUENCY_OF_FACTORS[item.higestDiffrance.factor]){
          FREQUENCY_OF_FACTORS[item.higestDiffrance.factor] = FREQUENCY_OF_FACTORS[item.higestDiffrance.factor]+1;
      }
      else {
          FREQUENCY_OF_FACTORS[item.higestDiffrance.factor] = 1;
      }
  })

  let mostFrequent = undefined;
  const MOST_FREQUENT_WITH_SAME_WEATAGE = [];

  for (const key in FREQUENCY_OF_FACTORS) {
      if(!mostFrequent || mostFrequent.freq < FREQUENCY_OF_FACTORS[key]){
          mostFrequent = {factor: key, freq: FREQUENCY_OF_FACTORS[key]};
      }
      if(mostFrequent.freq < FREQUENCY_OF_FACTORS[key]){
          MOST_FREQUENT_WITH_SAME_WEATAGE.push({factor: key, freq: FREQUENCY_OF_FACTORS[key]});
      }
  }

  if(!mostFrequent){
      return `No Factors in this range`;
  }

  let finalString = `${incDec(mostFrequent.factor)} ${mostFrequent.factor}`;

  MOST_FREQUENT_WITH_SAME_WEATAGE.forEach(item => {;
      finalString += ` & ${incDec(item.factor)} ${item.factor}`;
  })
  
  return finalString;
}

function useIsIncreaseOrDicrease(){
  const  incDec = (factor:any) => {
      return ["Fun","Purpose","Growth"].indexOf(factor) === -1? "Decrease":"Increase"
  }
  
  return  [incDec];
}

function useCalculateXLScoreStreakOfUser(allData:any,group:any,index:any) {
  if(!allData || !group){
    return [];
  }

  const userWithLowScore = group.histogramData.filter((item:any) => item.XLScore < 50);

  const streaks:any = {};

  userWithLowScore.forEach((item:any) => {
    for (let i = index; i > 0; i--) {
      const prevHistogramData = allData[i-1]?.histogramData.find((it:any) => (it._id === item._id && it.XLScore < 50));
  
      if(prevHistogramData){
        if(streaks[item._id]){
          streaks[item._id] = streaks[item._id]+1;
        }
        else {
          streaks[item._id] = 1;
        }
      }
    }
  })

  const finalData = group.histogramData.map((item:any) => {
    for (const key in streaks) {
      if(key === item._id){
        return {...item,streak: streaks[key]}
      }
    }
    return item;
  })

  return finalData;
}

function useCalculateFlowScoreStreakOfUser(allData:any,group:any,index:any) {
  if(!allData || !group){
    return [];
  }

  const histogramData = group.histogramData.filter((item:any) => typeof(item.flowScore) === "number");
  const userWithLowScore = histogramData.filter((item:any) => item.flowScore < 50);

  const streaks:any = {};

  userWithLowScore.forEach((item:any) => {
    for (let i = index; i > 0; i--) {
      const prevHistogramData = allData[i-1]?.histogramData.find((it:any) => (it._id === item._id && it.flowScore < 50));
  
      if(prevHistogramData){
        if(streaks[item._id]){
          streaks[item._id] = streaks[item._id]+1;
        }
        else {
          streaks[item._id] = 1;
        }
      }
    }
  })

  const finalData = histogramData.map((item:any) => {
    for (const key in streaks) {
      if(key === item._id){
        return {...item,streak: streaks[key]}
      }
    }
    return item;
  })

  return finalData;
}

function useEventsChangeEffect({events,setstreamGraphChartData}:any){

  useEffect(() => {
    if(events){
      setstreamGraphChartData((prev:any) => {
        const rem = prev.datasets.filter((dataSet:any) => dataSet.label !== "Events");

        return {
          ...prev,
          datasets: [
            {
              type: "scatter",
              label: "Events",
              data: events.map((ev:any) => {return {x: new Date(ev.date),y:-85,description: ev.description,tags: ev.tags}}),
              borderColor: "#db03fc",
              backgroundColor: "#db03fc",
              stack: 'combined',
            },
            ...rem
          ]
        }
      })
    }
  }, [events,setstreamGraphChartData]);

  return null;
}

function useHistoricalDataChangeEffect({historicalData,setstreamGraphChartData}:any){

  useEffect(() => {
    if(historicalData){
      setstreamGraphChartData((prev:any) => {
        
        const rem = prev.datasets.filter((dataSet:any) => [...XL_FACTORS,"XLScore","FlowScore"].indexOf(dataSet.label) === -1);
   
        return {
          ...prev,
          labels: historicalData.map((item:any) => new Date(item.year)),
          datasets: [
            ...rem,
             {
              type: 'line',
              label: "XLScore",
              data: historicalData.map((item:any) => {return {x: new Date(item.year),y: mapper_0_100_to__100_100(item["XLScore"])}}),
              borderColor: FACTOR_COLORS["XLSCORE"],
              backgroundColor: FACTOR_COLORS["XLSCORE"],
              pointRadius: 0,
              fill: false,
              stack: '1',
            },
             {
              type: 'line',
              label: "FlowScore",
              data: historicalData.map((item:any) => {return {x: new Date(item.year),y: mapper_0_100_to__100_100(item["flowScore"])}}),
              borderColor: getCurrentColorForFlowScore(0),
              backgroundColor: getCurrentColorForFlowScore(0),
              pointRadius: 0,
              fill: false,
              stack: '2',
            },
            ...XL_FACTORS.slice(0,6).map((factor) => {
                const facColor: string = factor.toUpperCase();
                const color = FACTOR_COLORS[facColor as keyof typeof FACTOR_COLORS];

                return {
                  type: 'line',
                  label: factor,
                  data: historicalData.map((item:any) => {return {x: new Date(item.year),y: item[factor]}}),
                  borderColor: color,
                  backgroundColor: color,
                  fill: true,
                  pointRadius: 0,
                }
            })
          ]
        }
      });
    }
  }, [historicalData,setstreamGraphChartData]);

  return null;
}






















