import React, { useEffect, useState } from 'react'
import { ForwardArrowBold } from '../../../components/Icons';
import { getCurrentGoal } from '../../../Store/actions/goal';
import { GOALS_TYPES_OBJ } from '../../../constants';
import { firstLetterUpperCase } from '../../../utils';
import dayjs from 'dayjs';
import { getLeafToCOCNodesByLeafNodeId } from '../../../Store/actions';




interface IGoalProgress {
    currentActivity: any;
    lastCognipulseDate: any;
    dialGoal: string | undefined;
}

export const GoalsProgress: React.FC<IGoalProgress> = ({dialGoal,currentActivity,lastCognipulseDate})  => {
    // const [steps,currentStep,handlePrevAndNext,currentThingToDisplay] = useLogic({currentActivity});
    const [taskList, setTaskList] = useState<any[]>([]);
    const [index, setIndex] = useState(0);

    const handlePrevAndNext = (isPrev:boolean) => {
        if(isPrev){
            if(index < taskList.length-1){
                setIndex(index+1);
            }
        }
        else {
            if(index > 0){
                setIndex(index-1);
            }
        }
    }

    useEffect(() => {
        (async ()=> {
          if(dialGoal){
            const res = await getLeafToCOCNodesByLeafNodeId(dialGoal);
            // console.log(res);
            if(res.success){
              setTaskList(res.arr);
            }
          }
        })()
    }, [dialGoal]);


    return (
        <div>
            {/* <h2 className='font-bold text-secondry-color mb-3'>{currentThingToDisplay.heading}:</h2> */}
            {/* <Steps completedSteps={currentStep} steps={steps}/> */}
            {/* <div className="mt-2 text-center text-secondry-color text-sm">{steps[currentStep-1]}</div> */}
            <div className='bg-gray-300 mt-1 rounded-md flex items-center p-4'>
                <div onClick={() => handlePrevAndNext(true)} className={`cursor-pointer ${index === taskList.length? "opacity-25":"opacity-100"}`}><ForwardArrowBold className={"rotate-180"}/></div>
                <div className='flex-1 text-center overflow-auto text-secondry-color'>{taskList[index]?.objective}</div>
                <div onClick={() => handlePrevAndNext(false)} className={`cursor-pointer ${index === 0? "opacity-25":"opacity-100"}`}><ForwardArrowBold/></div>
            </div>
            <div className='flex justify-end text-secondry-color text-sm'>{lastCognipulseDate? dayjs(lastCognipulseDate).format("llll"):""}</div>
        </div>
    )
}


interface IuseLogic {
    currentActivity: any
}



function useLogic({currentActivity}:IuseLogic):any {
    const [steps] = useState([`${firstLetterUpperCase(currentActivity.name)} ${firstLetterUpperCase(currentActivity.category)}`,"Your Moonshot Goal","Your Week Goal","Your Day Goal","Your Current Goal"]);
    const [currentStep, setCurrentStep] = useState(5);
    const [currentGoal, setCurrentGoal] = useState<any>(null);
    const [dayGoal, setDayGoal] = useState<any>(null);
    const [weekGoal, setWeekGoal] = useState<any>(null);
    const [moonShotGoal, setMoonShotGoal] = useState<any>(null);
    const [currentThingToDisplay, setcurrentThingToDisplay] = useState<any>({
        goal: "",
        heading: "",
        progress: 0,
        date: null
    });

    const handlePrevAndNext = (isPrev: boolean) => {
        let upcommingStep = currentStep;

        if(isPrev){
            if(currentStep !== 1){
                upcommingStep--;
            }
        }
        else {
            if(currentStep !== 5){
                upcommingStep++;
            }
        }

        if(upcommingStep === 5){
            if(currentGoal){
                setcurrentThingToDisplay({
                    goal: currentGoal.name,
                    heading: "Your Potential to achieve",
                    progress: currentGoal.progress[currentGoal.progress.length-1]?.percentage,
                    date: currentGoal.createdAt
                })
            }
            else {
                setcurrentThingToDisplay({
                    goal: "Please Set New Current Goal",
                    heading: "Your Potential toachieve",
                    date: null
                });
            }
        }
        if(upcommingStep === 4){
            if(dayGoal){
                setcurrentThingToDisplay({
                    goal: dayGoal.name,
                    heading: "Your Potential to make progress towards",
                    progress: dayGoal.progress[dayGoal.progress.length-1]?.percentage,
                    date: dayGoal.createdAt
                })
            }
            else {
                setcurrentThingToDisplay({
                    goal: "Please Set New Day Goal",
                    heading: "Your Potential to make progress towards",
                    date: null
                });
            }
        }
        if(upcommingStep === 3){
            if(weekGoal){
                setcurrentThingToDisplay({
                    goal: weekGoal.name,
                    heading: "Your Potential to make progress towards",
                    progress: weekGoal.progress[weekGoal.progress.length-1]?.percentage,
                    date: weekGoal.createdAt
                })
            }
            else {
                setcurrentThingToDisplay({
                    goal: "Please Set New Week Goal",
                    heading: "Your Potential to make progress towards",
                    date: null
                });
            }
        }
        if(upcommingStep === 2){
            if(moonShotGoal){
                setcurrentThingToDisplay({
                    goal: moonShotGoal.name,
                    heading: "Your Potential to make progress towards",
                    progress: moonShotGoal.progress[moonShotGoal.progress.length-1]?.percentage,
                    date: moonShotGoal.createdAt
                })
            }
            else {
                setcurrentThingToDisplay({
                    goal: "Please Set New Moonshot Goal",
                    heading: "Your Potential to make progress towards",
                    date: null
                });
            }
        }
        if(upcommingStep === 1){
            setcurrentThingToDisplay({
                goal: steps[0],
                heading: "Your Potential for your",
                date: currentActivity.createdAt
            });
        }
        
        setCurrentStep(upcommingStep);
    }

    
    useEffect(() => {
        if(currentActivity){
          (async () => {
            const currentRes = await getCurrentGoal(GOALS_TYPES_OBJ.current_goal,currentActivity._id);
            const dayRes = await getCurrentGoal(GOALS_TYPES_OBJ.day_goal,currentActivity._id);
            const weekRes = await getCurrentGoal(GOALS_TYPES_OBJ.week_goal,currentActivity._id);
            const moonShotRes = await getCurrentGoal(GOALS_TYPES_OBJ.moonshot_goal,currentActivity._id);
            
            if(currentRes.success && currentRes.goal){
                setCurrentGoal({...currentRes.goal,progress: currentRes.progress}); 
            }
            if(dayRes.success && dayRes.goal){
                setDayGoal({...dayRes.goal,progress: dayRes.progress});
            }
            if(weekRes.success && weekRes.goal){
                setWeekGoal({...weekRes.goal,progress: weekRes.progress});
            }
            if(moonShotRes.success && moonShotRes.goal){
              setMoonShotGoal({...moonShotRes.goal,progress: moonShotRes.progress});
            }


            // set initial data to display
            if(currentRes?.goal){
                setcurrentThingToDisplay({
                    goal: currentRes.goal.name,
                    heading: "Your Potential to achieve",
                    date: currentRes.goal.createdAt
                });
            }
            else {
                setcurrentThingToDisplay({
                    goal: "Please Set New Current Goal",
                    heading: "Your Potential to achieve",
                    date: null
                });
            }
          })()
        }
    }, [currentActivity]);

    return [steps,currentStep,handlePrevAndNext,currentThingToDisplay];
}


interface ISteps {
    completedSteps: number,
    steps: string[]
}
  
export const Steps: React.FC<ISteps> = ({steps,completedSteps}) => {
    const number = `grid grid-cols-${steps.length} text-sm font-medium text-gray-500`
  
  
    return <>
        <div className="">
            <h2 className="sr-only">Steps</h2>
            <div className="after:mt-4 after:block after:h-1 after:w-full after:rounded-lg after:bg-gray-200">
                <ol className={`${number}`}>
                    {
                        steps.map((step,idx) => {
                            const className1 = idx === completedSteps-1? "text-secondry-button-color":"";
                            const className2 = idx === completedSteps-1? "bg-secondry-button-color text-white":"bg-gray-600 text-white";
  
                            if(idx === 0){
                                return <>
                                    <li className={`relative flex justify-center ${className1}`}>
                                        <span
                                        className={`absolute -bottom-[1.75rem] left-1/2 -translate-x-1/2 rounded-full ${className2}`}
                                        >
                                        <svg
                                            className="size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"
                                            />
                                        </svg>
                                        </span>
  
                                        {/* <span className="hidden sm:block"> {step} </span> */}
  
                                        <svg
                                        className="mx-auto size-6 sm:hidden"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        </svg>
                                    </li>
                                </>
                            }
                            if(idx === steps.length-1){
                                return <>
                                    <li className={`relative flex justify-center ${className1}`}>
                                        <span
                                        className={`absolute -bottom-[1.75rem] left-1/2 -translate-x-1/2 rounded-full ${className2}`}
                                        >
                                        <svg
                                            className="size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"
                                            />
                                        </svg>
                                        </span>
  
                                        {/* <span className="hidden sm:block"> {step} </span> */}
  
                                        <svg
                                        className="mx-auto size-6 sm:hidden"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        </svg>
                                    </li>
                                </>
                            }
                            return <>
                            <li className={`relative flex justify-center ${className1}`}>
                                <span
                                className={`absolute -bottom-[1.75rem] left-1/2 -translate-x-1/2 rounded-full ${className2}`}
                                >
                                <svg
                                    className="size-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"
                                    />
                                </svg>
                                </span>
  
                                {/* <span className="hidden sm:block"> {step} </span> */}
  
                                <svg
                                className="mx-auto size-6 sm:hidden"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                                >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                </svg>
                            </li>
                            </>
                        })
                    }
                </ol>
            </div>
        </div>
    </>
}