interface IModal {
    setIsOpen: any;
    isHeader: boolean;
    headerText?: JSX.Element[] | JSX.Element;
    children: JSX.Element[] | JSX.Element;
    modalWidth?: "full" | string
    newHeader?: boolean
}


export const Modal: React.FC<IModal> = ({newHeader,modalWidth,setIsOpen,isHeader,children,headerText}) => {


    return <>
        <div tabIndex={-1} aria-hidden="true" className="overflow-y-auto bg-black/60 overflow-x-hidden fixed top-0 right-0 left-0 z-[2000] flex justify-center items-center w-full md:inset-0 h-[100%] max-h-full">
            <div className={`relative p-4 w-full ${modalWidth? modalWidth:"max-w-md"} max-w-full max-h-full`}>
                <div className="relative bg-primary-color rounded-lg">
                    {
                        isHeader && <>
                        <div className={`${newHeader? "p-0":"px-4 py-2 md:px-8 md:py-2"} flex items-center justify-between border-b rounded-t border-secondry-color/10`}>
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex-1">
                                {headerText}
                            </h3>
                            <button onClick={(e)=> {e.preventDefault();setIsOpen(false);}} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="select-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        </>
                    }
                    

                    <div className="p-6 md:p-8">
                        {children}
                    </div>
                </div>
            </div>
        </div> 
    </>
}