import React, { useEffect, useRef, useState } from 'react';
import { Main} from "../../layout";
import { getUserActivities, updateActivity } from '../../Store/actions/activity';
import { Loading, PrimaryButton, SecondryButton, ShadowBox } from '../../components';
import { firstLetterUpperCase } from '../../utils';
import { GoalModal, PurposeModal } from './components';
import { useNavigate } from 'react-router-dom';
import { getCurrentGoal } from '../../Store/actions/goal';
import { GOALS_TYPES_OBJ } from '../../constants';
import { DialyGoalModal } from '../../components/Modals/DailyGoalModal';
import { CurrentGoalModal } from '../../components/Modals/CurrentGoalModal';
import { WeeklyGoalModal } from '../../components/Modals/WeeklyGoalModal';
import { MoonShotGoalModal } from '../../components/Modals/MoonShotGoalModal';
import { getCurrentPurpose } from '../../Store/actions';




const  Index: React.FC<any> = () => {
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const [activities, setActivities] = useState<any>(null);
  const [isPurposeModalOpen, setIsPurposeModalOpen] = useState(false);
  const [isGoalModalOpen, setIsGoalModalOpen] = useState(false);
  const [shouldDisplayDayOrWeekGoalInCognipuse, setShouldDisplayDayOrWeekGoalInCognipuse] = useState(false);
  const throttleTimeout = useRef<NodeJS.Timeout | null>(null);
  const navigation = useNavigate();
  // const [
  //   isMoonShotShow,
  //   setIsMoonShotShow,
  //   moonShotData,
  //   setMoonShotData,
  //   handleMoonShotSubmit,
  //   isUpdateMoonShotGoal,
  //   handleMoonShotGoalClick
  // ] = useMoonShotGoalLogic(activities? activities[currentActivityIndex]?._id:"",setActivities);
  const [currentGoal, setCurrentGoal] = useState<any>(null);
  const [isShowCurrentGoal, setIsShowCurrentGoal] = useState(false);
  const [dayGoal, setDayGoal] = useState<any>(null);
  const [isShowDailyGoal, setIsShowDailyGoal] = useState(false);
  const [weekGoal, setWeekGoal] = useState<any>(null);
  const [isShowWeekGoal, setIsShowWeekGoal] = useState(false);
  const [moonShotGoal, setMoonShotGoal] = useState<any>(null);
  const [isShowMoonShotGoal, setIsShowMoonShotGoal] = useState(false);
  const [purpose, setPurpose] = useState<any>(null);
  

  

  const handleDailyOrWeeklyGoalChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if(!throttleTimeout.current){
      throttleTimeout.current = setTimeout(async () => {
        await updateActivity(activities[currentActivityIndex]._id,{dayOrWeekGoal: e.target.value});
        throttleTimeout.current = null;
      }, 2000); // 1 second delay
    }           
  }

  const handleWillDisplaydayOrWekGoalInCognipuseChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setShouldDisplayDayOrWeekGoalInCognipuse(e.target.checked);
    await updateActivity(activities[currentActivityIndex]._id,{shouldDisplayDayOrWeekGoalInCognipuse: e.target.checked});
  }


  useEffect(() => {
    if(activities){
      setShouldDisplayDayOrWeekGoalInCognipuse(() => {
        return activities[currentActivityIndex].shouldDisplayDayOrWeekGoalInCognipuse
      });
    }
  }, [activities,currentActivityIndex]);
  

  useEffect(() => {
    (async () => {
      const res = await getUserActivities();
      if(res?.success){
        setActivities(res.activities);
      }
    })()
  }, []);


  const handleNewDayGoalSubmit = async (goal:any) => {
      setDayGoal(goal);
  }

  const handleNewCurrentGoalSubmit = async (goal:any) => {
      setCurrentGoal(goal);
  }

  const handleNewWeekGoalSubmit = async (goal:any) => {
    setWeekGoal(goal);
  }

  const handleNewMoonShotGoalSubmit = async (goal:any) => {
    setMoonShotGoal(goal);
  }

  useEffect(() => {
    if(activities){
      (async () => {
        const currentRes = await getCurrentGoal(GOALS_TYPES_OBJ.current_goal,activities[currentActivityIndex]._id);
        const dayRes = await getCurrentGoal(GOALS_TYPES_OBJ.day_goal,activities[currentActivityIndex]._id);
        const weekRes = await getCurrentGoal(GOALS_TYPES_OBJ.week_goal,activities[currentActivityIndex]._id);
        const moonShotRes = await getCurrentGoal(GOALS_TYPES_OBJ.moonshot_goal,activities[currentActivityIndex]._id);
        const purposeRes = await getCurrentPurpose(activities[currentActivityIndex]._id);
        
        if(currentRes.success){
          setCurrentGoal(currentRes.goal);
        }
        if(dayRes.success){
          setDayGoal(dayRes.goal);
        }
        if(weekRes.success){
          setWeekGoal(weekRes.goal);
        }
        if(moonShotRes.success){
          setMoonShotGoal(moonShotRes.goal);
        }
        if(purposeRes.success){
          setPurpose(purposeRes.purpose);
        }
      })()
    }
  }, [activities,currentActivityIndex]);

  
  if(!activities){
    return <Main><div className='w-full h-[calc(100vh-64px-57px)] flex items-center justify-center'><Loading/></div></Main>
  }


  return (
    <Main>
      <div className='h-5'/>
      <div className='w-[400px] max-w-full mx-auto'>
        <ShadowBox> 
                    {
                      activities.length > 1 && <>
                            <select value={currentActivityIndex} onChange={(e)=>{setCurrentActivityIndex(Number(e.target.value))}} className='w-full p-1 rounded-md mb-6 bg-primary-color border border-secondry-color'>
                                {
                                  activities.map((activity:any,idx:number) => (
                                    <option key={idx} value={idx}>{firstLetterUpperCase(activity.name)} {firstLetterUpperCase(activity.category)}</option>
                                  ))
                                }
                            </select>
                      </> 
                    }

                    <h3 className='text-center font-bold text-secondry-color'>My purpose for my {activities[currentActivityIndex].name} {activities[currentActivityIndex].category}</h3>
                    <p onClick={() =>setIsPurposeModalOpen(true)} className='bg-secondry-color/20 text-secondry-color rounded-lg p-4 text-center mt-3'>
                      {
                        purpose && <>
                          {purpose.name}
                        </>
                      }
                      {
                        !purpose && <>
                          Click here to craft your Purpose statement
                        </>
                      }
                    </p>

                  
                    <h3 className='text-center font-bold text-secondry-color mt-10'>Your Moonshot Goal</h3>
                    <textarea onChange={()=>{}} value={moonShotGoal? moonShotGoal.name:"No Goal Found!"} placeholder='Enter the bespoke daily or weekly goal that your XLRate coach has created for you.' className='w-full bg-secondry-color/20 text-secondry-color rounded-lg p-4 text-center mt-3'/>
                    <div className="flex justify-between">
                        <div className='flex items-center w-[70%]'>
                          <input checked={shouldDisplayDayOrWeekGoalInCognipuse} onChange={handleWillDisplaydayOrWekGoalInCognipuseChange} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-secondry-button-color bg-gray-100 border-gray-300 rounded focus:ring-secondry-button-color focus:ring-2"/>
                          <label htmlFor="checked-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Display your goal in your CogniPulse</label>
                        </div>
                        <div>
                          <PrimaryButton onClick={()=>{setIsShowMoonShotGoal(true)}} style={{padding: "1px 10px",fontSize: "14px"}}>New Goal</PrimaryButton>
                        </div>
                    </div>

                    {/* <h3 className='text-center font-bold text-secondry-color mt-10'>My current 90 day goal for  {activities[currentActivityIndex].name} {activities[currentActivityIndex].category}</h3>
                    <p onClick={()=>{setIsGoalModalOpen(true)}} className='bg-secondry-color/20 text-secondry-color rounded-lg p-4 text-center mt-3'>
                      {
                        activities[currentActivityIndex].purpose && activities[currentActivityIndex].goal.length > 0 && <>
                          {formatGoal(activities[currentActivityIndex].goal)}
                        </>
                      }
                      {
                        activities[currentActivityIndex].purpose && activities[currentActivityIndex].goal.length === 0 && <>
                          Click here to craft your 90 Day Goal
                        </>
                      }
                    </p> */}


                    <h3 className='text-center font-bold text-secondry-color mt-10'>Week Goal</h3>
                    <textarea onChange={()=>{}} value={weekGoal?.name || "No Goal Found!"} placeholder='Enter the bespoke daily or weekly goal that your XLRate coach has created for you.' className='w-full bg-secondry-color/20 text-secondry-color rounded-lg p-4 text-center mt-3'/>
                    <div className="flex justify-between">
                        <div className='flex items-center w-[70%]'>
                          <input checked={shouldDisplayDayOrWeekGoalInCognipuse} onChange={handleWillDisplaydayOrWekGoalInCognipuseChange} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-secondry-button-color bg-gray-100 border-gray-300 rounded focus:ring-secondry-button-color focus:ring-2"/>
                          <label htmlFor="checked-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Display your goal in your CogniPulse</label>
                        </div>
                        <div>
                          <PrimaryButton onClick={()=>{setIsShowWeekGoal(true)}} style={{padding: "1px 10px",fontSize: "14px"}}>New Goal</PrimaryButton>
                        </div>
                    </div>

                    <h3 className='text-center font-bold text-secondry-color mt-10'>My Day Goal</h3>
                    <textarea onChange={handleDailyOrWeeklyGoalChange} value={dayGoal?.name || "No Goal Found!"} placeholder='Enter the bespoke daily or weekly goal that your XLRate coach has created for you.' className='w-full bg-secondry-color/20 text-secondry-color rounded-lg p-4 text-center mt-3'/>
                    <div className="flex justify-between">
                        <div className='flex items-center w-[70%]'>
                          <input checked={shouldDisplayDayOrWeekGoalInCognipuse} onChange={handleWillDisplaydayOrWekGoalInCognipuseChange} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-secondry-button-color bg-gray-100 border-gray-300 rounded focus:ring-secondry-button-color focus:ring-2"/>
                          <label htmlFor="checked-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Display your goal in your CogniPulse</label>
                        </div>
                        <div>
                          <PrimaryButton onClick={()=>{setIsShowDailyGoal(true)}} style={{padding: "1px 10px",fontSize: "14px"}}>New Goal</PrimaryButton>
                        </div>
                    </div>


                    <h3 className='text-center font-bold text-secondry-color mt-10'>My Current Goal/Task</h3>
                    <textarea onChange={()=>{}} value={currentGoal?.name || "No Goal Found!"} placeholder='Enter the bespoke daily or weekly goal that your XLRate coach has created for you.' className='w-full bg-secondry-color/20 text-secondry-color rounded-lg p-4 text-center mt-3'/>
                    <div className="flex justify-between">
                        <div className='flex items-center w-[70%]'>
                          <input checked={shouldDisplayDayOrWeekGoalInCognipuse} onChange={handleWillDisplaydayOrWekGoalInCognipuseChange} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-secondry-button-color bg-gray-100 border-gray-300 rounded focus:ring-secondry-button-color focus:ring-2"/>
                          <label htmlFor="checked-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Display your goal in your CogniPulse</label>
                        </div>
                        <div>
                          <PrimaryButton onClick={()=>{setIsShowCurrentGoal(true)}} style={{padding: "1px 10px",fontSize: "14px"}}>New Goal</PrimaryButton>
                        </div>
                    </div>

                    <div className="flex justify-center mt-12 gap-2">
                      <SecondryButton style={{padding: "12px 0"}} onClick={()=>{navigation("/my-dashboard")}}>Done</SecondryButton>
                    </div>
        </ShadowBox>
      </div>
      <div className='h-5'/>
      {
        isPurposeModalOpen && <PurposeModal setIsPurposeModalOpen={setIsPurposeModalOpen}  activities={activities} currentActivityIndex={currentActivityIndex} setPurposeGlobal={setPurpose} purposeId={purpose? purpose._id:null} purposeArray={purpose? purpose.dynamicProperties.purposeArray:[]}/>
      }
      {
        isGoalModalOpen && <GoalModal setIsGoalModalOpen={setIsGoalModalOpen}  activities={activities} currentActivityIndex={currentActivityIndex} setActivities={setActivities}/>
      }
      {/* {
          isMoonShotShow && <MoonShotGoal isUpdate={isUpdateMoonShotGoal} setIsShow={setIsMoonShotShow} data={moonShotData} setData={setMoonShotData} handleSubmit={handleMoonShotSubmit}/>
      } */}
      {
        isShowDailyGoal && <DialyGoalModal handleSubmit={handleNewDayGoalSubmit} currentActivity={activities[currentActivityIndex]} setIsShow={setIsShowDailyGoal}/>
      }
      {
        isShowCurrentGoal && <CurrentGoalModal handleSubmit={handleNewCurrentGoalSubmit} currentActivity={activities[currentActivityIndex]} setIsShow={setIsShowCurrentGoal}/>
      }
      {
        isShowWeekGoal && <WeeklyGoalModal handleSubmit={handleNewWeekGoalSubmit} currentActivity={activities[currentActivityIndex]} setIsShow={setIsShowWeekGoal}/>
      }
      {
        isShowMoonShotGoal && <MoonShotGoalModal handleSubmit={handleNewMoonShotGoalSubmit} currentActivity={activities[currentActivityIndex]} setIsShow={setIsShowMoonShotGoal}/>
      }
    </Main>
  )
}

export default Index;



// function useMoonShotGoalLogic(activityId: string,setActivities:any){
//   const [isShow, setIsShow] = useState(false);
//   const [isUpdate, setIsUpdate] = useState(false);
//   const [moonShotData, setMoonShotData] = useState<any>({
//         result: "",
//         action: "",
//         impact: "",
//         time: 90
//   });

//   const handleMoonShotGoalClick = (moonShotGoal:any) => {
//     // setIsShow(true);
//     // if(moonShotGoal){
//     //   const msg = moonShotGoal.dynamicProperties;
//     //   setMoonShotData({
//     //     action: msg.action,
//     //     result: msg.result,
//     //     impact: msg.impact,
//     //     time: msg.time
//     //   }); 
//     //   setIsUpdate(true);
//     //   return;
//     // }
//     // setIsUpdate(false);
//   }


//   const handleSubmit = async () => {
//         // if(!activityId){
//         //   console.error("Activity Id Not Found!");
//         //   return;
//         // }

//         // let res = undefined;

//         // if(isUpdate){
//         //   res = await updateMoonShortGoal(activityId,moonShotData);
//         // }
//         // else {
//         //   res = await createMoonShortGoal(activityId,moonShotData);
//         // }
        
//         // if(!res){
//         //   return;
//         // }
          
//         // if(res.success){
//         //     setIsShow(false);
//         //     setActivities((prev:any) => {
//         //       return prev.map((act:any)  => act._id === activityId? {...res.activity}:act);
//         //     })
//         // }
//   }

//   return [isShow,setIsShow,moonShotData,setMoonShotData,handleSubmit,isUpdate,handleMoonShotGoalClick];
// }

