import { ShadowBox } from "../../../components";
import { Skeleton } from "./Skeleton";

export function DahsboardSkeleton(){

    return <>
        <div className="w-full flex gap-4">
          <div className="flex-1 flex flex-col gap-4">
            <div className="h-[430px]">
                <ShadowBox>
                  <Skeleton className="h-[390px]"/>
                </ShadowBox>
            </div>
            <div className="h-[430px]">
                <ShadowBox>
                  <Skeleton className="h-[390px]"/>
                </ShadowBox>
            </div>
          </div>
          <div className="flex-1">
              <ShadowBox>
                <Skeleton className="h-[836px]"/>
              </ShadowBox>
          </div>
          <div className="flex-1">
              <ShadowBox>
                <Skeleton className="h-[836px]"/>
              </ShadowBox>
          </div>
        </div>
        <div className="flex mt-4 h-[419px] gap-4">
          <div className="flex-1">
              <ShadowBox>
                <Skeleton className="h-[380px]"/>
              </ShadowBox>
          </div>
          <div className="flex-1">
              <ShadowBox>
                <Skeleton className="h-[380px]"/>
              </ShadowBox>
          </div>
        </div>
    </>
}