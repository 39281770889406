interface ITable {
    children: JSX.Element[] | JSX.Element;
    isSticky?: boolean
}

export const Table: React.FC<ITable> = ({children,isSticky}) => {

    return (
      <div className="w-full flex flex-col max-h-full">
        <div className="w-full flex-grow overflow-auto">
          <table className={`${isSticky? "relative":""} w-full table-auto w-full text-sm text-left`}>
              {children}
          </table>
        </div>
      </div>
    )
  }