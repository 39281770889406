import React, { useEffect, useState } from 'react'
import { Modal } from './Modal'
import { getUserActivities, updateActivity } from '../../Store/actions/activity';
import { PrimaryButton } from '../PrimaryButton';
import { SecondryButton } from '../SecondryButton';

interface IActivityOrderModal{
    setIsOpen: any;
}

export const ActivityOrderModal: React.FC<IActivityOrderModal> = ({setIsOpen}) => {
    const [activitiesOrder, setactivitiesOrder] = useState<any>([]);


    const handleChange = (e:any,actId:string) => {
        setactivitiesOrder((prev:any) => {
            return prev.map((act:any) => {return act._id === actId? {...act,order: Number(e.target.value)}:act})
        })
    }


    const handleSave = async () => {
      for (let index = 0; index < activitiesOrder.length; index++) {
          const act = activitiesOrder[index];
          await updateActivity(act._id,{order: act.order});
      }
      setIsOpen(false);
    }
  

    useEffect(() => {
        (async () => {
        const res = await getUserActivities();
        if(res.activities){
            setactivitiesOrder(() => res.activities?.map((act:any,idx:number) => {return {...act,order: act.order || idx+1}}));
        }
        })()
    }, []);


  return (
    <>
        <Modal setIsOpen={setIsOpen} isHeader={true} headerText={<div className='text-secondry-color'>Set Activity Order</div>}>
            <div className="flex flex-col items-start gap-2">
                {
                    activitiesOrder.map((act:any,idx:number) => 
                        <button className="bg-white/60 p-2 rounded-md w-full text-start" key={idx}>
                            <input min={1} max={9} onChange={(e) => handleChange(e,act._id)} className="w-10 border rounded-md px-1" type="number" value={act.order}/> {act.category} {act.name}
                        </button>
                    )
                }
            </div>

            <div className="flex mt-10 gap-2">
                <PrimaryButton onClick={() => setIsOpen(false)}>Cancel</PrimaryButton>
                <SecondryButton onClick={handleSave}>Save</SecondryButton>
            </div>
        </Modal>
    </>
  )
}
