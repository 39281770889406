import { DOMAIN_NAME } from "../../constants";
import {fetchRequest} from "../../utils";


interface IBody {
    percentage: number;
    user: string;
    survey: string;
    dialGoal: string;
    headerGoal: string;
    activity: string;
}


export const createGoalProgress = async (body: IBody) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/goal-progresses`,"POST",body);

    return res;
}


export const getGoalProgressOfUser = async () => {

    const res = await fetchRequest(`${DOMAIN_NAME}/goal-progresses`,"GET",{});

    return res;
}