import { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/store";
import { firstLetterUpperCase } from "../../utils";
import { PrimaryButton } from "../PrimaryButton";
import { GOAL_TYPES, GOALS_TYPES_OBJ } from "../../constants";
import { Steps } from "../Steps";
import { SecondryButton } from "../SecondryButton";
import { createGoal, getCurrentGoal } from "../../Store/actions/goal";



const STEPS = ["Where","How","What","When","Wow!"];

export interface Idata {
  where: string;
  how: string;
  what: string;
  when: string;
  parentGoal: string;
  parentGoalId: string;
}

interface ICurrentGoalModal {
  currentActivity: any;
  setIsShow: any;
  handleSubmit?: any;
}

export const CurrentGoalModal: React.FC<ICurrentGoalModal> = ({handleSubmit,currentActivity,setIsShow}) => {
  const [visibleScreenNumber, setVisibleScreenNumber] = useState(1);
  const [data, setData] = useState<Idata>({
    where: "",
    how: "",
    what: "",
    when: "",
    parentGoal: "activity",
    parentGoalId: ""
  });

  


  return (
      <Modal 
      isHeader={true} 
      headerText={<div className="text-secondry-color py-2 text-center ml-[32px]">Set Your Current Goal</div>} 
      modalWidth="max-w-xl"
      setIsOpen={setIsShow}>
          <>
            {
              visibleScreenNumber === 1 && <One setScreenNumber={setVisibleScreenNumber}/>
            }
            {
              visibleScreenNumber === 2 && <Two data={data} setData={setData} currentActivity={currentActivity} setScreenNumber={setVisibleScreenNumber}/>
            }
            {
              visibleScreenNumber > 2 && <>
                                          <ParentDropDown goalName="current_goal" data={data} setData={setData} currentActivity={currentActivity}/>
                                          {
                                            visibleScreenNumber === 3 && <ThreeWhere data={data} setData={setData} setScreenNumber={setVisibleScreenNumber}/>
                                          }
                                          {
                                            visibleScreenNumber === 4 && <FourHow data={data} setData={setData} setScreenNumber={setVisibleScreenNumber}/>
                                          }
                                          {
                                            visibleScreenNumber === 5 && <FiveWhat data={data} setData={setData} setScreenNumber={setVisibleScreenNumber}/>
                                          }
                                          {
                                            visibleScreenNumber === 6 && <SixWhen data={data} setData={setData} setScreenNumber={setVisibleScreenNumber}/>
                                          }
                                          {
                                            visibleScreenNumber === 7 && <SevenWow handleSubmit={handleSubmit} setIsShow={setIsShow} currentActivity={currentActivity} data={data} setData={setData} setScreenNumber={setVisibleScreenNumber}/>
                                          }
              </>
            }
            
          </>
      </Modal>
  )
}


interface IOne {
  setScreenNumber: any;
}

const One: React.FC<IOne> = ({setScreenNumber}) => {
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser);

  

  return <>
     <h4 className='font-bold text-secondry-color mb-4'>Good morning {firstLetterUpperCase(loggedInUser?.forename || "")} {firstLetterUpperCase(loggedInUser?.surname || "")}</h4>
     <p className='text-secondry-color mb-8'>
            What do you want to achieve right now? <br/>
            Let's craft a 'juicy' current goal and reach it up to 5x faster, 
            by unlocking your untapped potential & 'supercharging' you performance!
     </p>
     <PrimaryButton onClick={() => setScreenNumber(2)}>Next</PrimaryButton>
  </>
}



type GoalNameType = (typeof GOAL_TYPES)[number];

interface IParentDropDown {
  currentActivity: any;
  data: any;
  setData: any;
  goalName: GoalNameType;
}

export const ParentDropDown: React.FC<IParentDropDown> = ({goalName,currentActivity,data,setData}) => {
  const [dayGoal, setDayGoal] = useState<any>(null);
  const [weekGoal, setWeekGoal] = useState<any>(null);
  const [moonShotGoal, setMoonShotGoal] = useState<any>(null);
  const [isShowDropDown, setIsShowDropDown] = useState(false);

  // function encodeStrings(string1: string, string2:string): string {
  //   if(string1 === "activity"){
  //     return "activity";
  //   }

  //   return `${string1.length}:${string1}${string2.length}:${string2}`;
  // }

  // function decodeStrings(combinedString: string): string[] {
  //   const regex = /(\d+):(.+?)(?=\d+:|$)/g;
  //   const decodedStrings = [];
  //   let match;
  
  //   while ((match = regex.exec(combinedString)) !== null) {
  //     decodedStrings.push(match[2]); // Extract the string part.
  //   }
  
  //   return decodedStrings;
  // }

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const {parentGoal,parentGoalId} = e.currentTarget.dataset;

    setData((prev:Idata) => {
      return {
        ...prev,
        parentGoal,
        parentGoalId
      }
    });
  }

  // const handleChange = (e:any) => {
  //   const decodedData = decodeStrings(e.target.value);

  //   if(e.target.value === "activity"){
  //     setData((prev:Idata) => {
  //       return {
  //         ...prev,
  //         parentGoal: "activity",
  //         parentGoalId: ''
  //       }
  //     })
  //     return;
  //   }
    
  //   setData((prev:Idata) => {
  //     return {
  //       ...prev,
  //       parentGoal: decodedData[0],
  //       parentGoalId: decodedData[1]
  //     }
  //   })
  // }
  

  useEffect(() => {
    if(currentActivity){
      (async () => {
        if(goalName === "current_goal"){
          const dayRes = await getCurrentGoal(GOALS_TYPES_OBJ.day_goal,currentActivity._id);
          const weekRes = await getCurrentGoal(GOALS_TYPES_OBJ.week_goal,currentActivity._id);
          const moonShotRes = await getCurrentGoal(GOALS_TYPES_OBJ.moonshot_goal,currentActivity._id);
          
          if(dayRes.success){
            setDayGoal(dayRes.goal);
          }
          if(weekRes.success){
            setWeekGoal(weekRes.goal);
          }
          if(moonShotRes.success){
            setMoonShotGoal(moonShotRes.goal);
          }
        }
        if(goalName === "day_goal"){
          const weekRes = await getCurrentGoal(GOALS_TYPES_OBJ.week_goal,currentActivity._id);
          const moonShotRes = await getCurrentGoal(GOALS_TYPES_OBJ.moonshot_goal,currentActivity._id);
          
          if(weekRes.success){
            setWeekGoal(weekRes.goal);
          }
          if(moonShotRes.success){
            setMoonShotGoal(moonShotRes.goal);
          }
        }
        if(goalName === "week_goal"){
          const moonShotRes = await getCurrentGoal(GOALS_TYPES_OBJ.moonshot_goal,currentActivity._id);

          if(moonShotRes.success){
            setMoonShotGoal(moonShotRes.goal);
          }
        }
        setData((prev:any) => {
          if(prev.parentGoalId){
            return prev;
          }
          return {
            ...prev,
            parentGoal: `My ${firstLetterUpperCase(currentActivity.name)} ${firstLetterUpperCase(currentActivity.category)}`,
            parentGoalId: currentActivity._id
          }
        });
      })()
    }
  }, [currentActivity,goalName,setData]);

  useEffect(() => {
    document.addEventListener("click",  (e:any) => {
      e.preventDefault();
      const isThere = JSON.parse(e.target.getAttribute("data-is-drop-down"));
      
      if(isThere === true){
        // setIsShowDropDown(p => !p);
      }
      else {
        setIsShowDropDown(false);
      }
    })

    return () => {
      document.removeEventListener("click",() => {});
    };
  }, []);

  return <>
    {/* <select value={encodeStrings(data.parentGoal,data.parentGoalId)} onChange={handleChange} className='w-full bg-transparent border border-secondry-color/20 mb-8 p-2 rounded-md'>
      {
        dayGoal && <option value={encodeStrings(GOALS_TYPES_OBJ.day_goal,dayGoal._id)}>Daily Goal: {dayGoal.name}</option>
      }
      {
        weekGoal && <option value={encodeStrings(GOALS_TYPES_OBJ.week_goal,weekGoal._id)}>Weekly Goal: {weekGoal.name}</option>
      }
      {
        moonShotGoal && <option value={encodeStrings(GOALS_TYPES_OBJ.moonshot_goal,moonShotGoal._id)}>Moonshot Goal: {moonShotGoal.name}</option>
      }
      <option value="activity">My {firstLetterUpperCase(currentActivity.name)} {firstLetterUpperCase(currentActivity.category)}</option>
    </select> */}

    <div data-is-drop-down={true} onClick={() => setIsShowDropDown(p => !p)} className="relative w-full border border-secondry-color/20 min-h-[40px] mb-8 p-2 rounded-md">
        {data.parentGoal}
        {
            isShowDropDown && <>
              <div className="absolute w-full z-[1] bg-white py-4 top-[105%] left-0 rounded-md">
                  <div className="w-full flex flex-col">
                      {
                        dayGoal && <div data-parent-goal={`Day Goal: ${dayGoal.name}`} data-parent-goal-id={dayGoal._id} onClick={handleClick} className={`hover:bg-blue-600 hover:text-white px-2 py-1 cursor-pointer truncate ${data.parentGoalId === dayGoal._id? "bg-blue-600 text-white":""}`}>Day Goal: {dayGoal.name}</div>
                      }
                      {
                        weekGoal && <div data-parent-goal={`Week Goal: ${weekGoal.name}`} data-parent-goal-id={weekGoal._id} onClick={handleClick} className={`hover:bg-blue-600 hover:text-white px-2 py-1 cursor-pointer truncate ${data.parentGoalId === weekGoal._id? "bg-blue-600 text-white":""}`}>Week Goal: {weekGoal.name}</div>
                      }
                      {
                        moonShotGoal && <div data-parent-goal={`Moonshot Goal: ${moonShotGoal.name}`} data-parent-goal-id={moonShotGoal._id} onClick={handleClick} className={`hover:bg-blue-600 hover:text-white px-2 py-1 cursor-pointer truncate ${data.parentGoalId === moonShotGoal._id? "bg-blue-600 text-white":""}`}>Moonshot Goal: {moonShotGoal.name}</div>
                      }
                      {
                        currentActivity && <div data-parent-goal={`My ${firstLetterUpperCase(currentActivity.name)} ${firstLetterUpperCase(currentActivity.category)}`} data-parent-goal-id={currentActivity._id} onClick={handleClick} className={`hover:bg-blue-600 hover:text-white px-2 py-1 cursor-pointer truncate ${data.parentGoalId === currentActivity._id? "bg-blue-600 text-white":""}`}>My {firstLetterUpperCase(currentActivity.name)} {firstLetterUpperCase(currentActivity.category)}</div>
                      }
                  </div>
              </div>
            </>
        }
    </div>
  </>
}


interface ITwo {
  setScreenNumber: any;
  currentActivity: any;
  data: any;
  setData: any;
}

const Two: React.FC<ITwo> = ({setScreenNumber,currentActivity,data,setData}) => {
  

  return <>
    <h2 className='text-secondry-color text-lg font-bold mb-6'>
      Why does this matter? 
      Where will the results of your Current goal make the biggest difference?
    </h2>


    <ParentDropDown goalName="current_goal" data={data} setData={setData} currentActivity={currentActivity}/>

    <PrimaryButton onClick={() => setScreenNumber(3)}>Next</PrimaryButton>
  </>
}




interface IThreeWhere {
  setScreenNumber: any;
  data: Idata;
  setData: any;
}

const ThreeWhere: React.FC<IThreeWhere> = ({setScreenNumber,data,setData}) => {
  
  return <>
        {/* <Title/>
        <div className="h-6"/> */}
        <Steps completedSteps={1} steps={STEPS}/>

        <div className="mt-8 flex flex-col">
            <label className="text-secondry-color/60 font-semibold" htmlFor="moonShortGoalPreview">Current Goal Preview</label>
            <input id="moonShortGoalPreview" className="rounded-md px-3 py-2 bg-secondry-color/20 text-secondry-color" onChange={() => {}} value={data.where} type="text"/>
        </div>
        <div className="relative mt-8 flex flex-col">
            <label className="text-secondry-color/60 font-semibold" htmlFor="moonShortGoalPreview">Where will you focus the change you want?</label>
            <input id="moonShortGoalPreview" className="rounded-md px-3 py-2 bg-secondry-color/20 text-secondry-color" value={data.where} onChange={(e) => setData((p:Idata) => {return {...p,where: e.target.value}})} placeholder="e.g. Revenue, Clients" type="text"/>
            <small className="text-secondry-color/60">What’s the one area where achieving something big will have the greatest impact on your bigger goals or purpose? (Choose an area that will have an outsized impact!)</small>
        </div>
        <div className="h-10"/>
        <div className="flex gap-5">
            <PrimaryButton onClick={() => setScreenNumber(2)}>Back</PrimaryButton>
            <SecondryButton onClick={() => setScreenNumber(4)}>Next</SecondryButton>  
        </div>
  </>
}


interface IFourHow {
  setScreenNumber: any;
  data: Idata;
  setData: any;
}

const FourHow: React.FC<IFourHow> = ({setScreenNumber,data,setData}) => {
  
  return <>
        {/* <Title/>
        <div className="h-6"/> */}
        <Steps completedSteps={2} steps={STEPS}/>
        <div className="mt-8 flex flex-col">
            <label className="text-secondry-color/60 font-semibold" htmlFor="moonShortGoalPreview">Current Goal Preview</label>
            <input id="moonShortGoalPreview" className="rounded-md px-3 py-2 bg-secondry-color/20 text-secondry-color" onChange={() => {}} value={`${data.how} ${data.where}`} type="text"/>
        </div>
        <div className="relative mt-8 flex flex-col">
            <label className="text-secondry-color/60 font-semibold" htmlFor="moonShortGoalPreview">'How' will the change be driven? </label>
            <input id="moonShortGoalPreview" className="rounded-md px-3 py-2 bg-secondry-color/20 text-secondry-color" value={data.how} onChange={(e) => setData((p:Idata) => {return {...p,how: e.target.value}})} placeholder="e.g : Increase, Improve, Reduce." type="text"/>
            <small className="text-secondry-color/60">Enter one 'action' will drive the biggest change?</small>
            
        </div>
        <div className="h-10"/>
        <div className="flex gap-5">
            <PrimaryButton onClick={() => setScreenNumber(3)}>Back</PrimaryButton>
            <SecondryButton onClick={() => setScreenNumber(5)}>Next</SecondryButton>  
        </div>
  </>
}



interface IFiveWhat {
  setScreenNumber: any;
  data: Idata;
  setData: any;
}

const FiveWhat: React.FC<IFiveWhat> = ({setScreenNumber,data,setData}) => {
  
  return <>
        {/* <Title/>
        <div className="h-6"/> */}
        <Steps completedSteps={3} steps={STEPS}/>
        <div className="mt-8 flex flex-col">
            <label className="text-secondry-color/60 font-semibold" htmlFor="moonShortGoalPreview">Current Goal Preview</label>
            <input id="moonShortGoalPreview" className="rounded-md px-3 py-2 bg-secondry-color/20 text-secondry-color" onChange={() => {}} value={`${data.how} ${data.where} ${data.what}`} type="text"/>
        </div>
        <div className="relative mt-8 flex flex-col">
            <label className="text-secondry-color/60 font-semibold" htmlFor="moonShortGoalPreview">What 'change' will measure success with?</label>
            <input id="moonShortGoalPreview" className="rounded-md px-3 py-2 bg-secondry-color/20 text-secondry-color" value={data.what} onChange={(e) => setData((p:Idata) => {return {...p,what: e.target.value}})} placeholder="e.g : By 20%, Add 10 clients, Lose 5kg" type="text"/>
            <small className="text-secondry-color/60">Make your change measurable!</small>
            
        </div>
        <div className="h-10"/>
        <div className="flex gap-5">
            <PrimaryButton onClick={() => setScreenNumber(4)}>Back</PrimaryButton>
            <SecondryButton onClick={() => setScreenNumber(6)}>Next</SecondryButton>  
        </div>
  </>
}


interface ISixWhen {
  setScreenNumber: any;
  data: Idata;
  setData: any;
}

const SixWhen: React.FC<ISixWhen> = ({setScreenNumber,data,setData}) => {
  
  return <>
        {/* <Title/>
        <div className="h-6"/> */}
        <Steps completedSteps={4} steps={STEPS}/>
        <div className="mt-8 flex flex-col">
            <label className="text-secondry-color/60 font-semibold" htmlFor="moonShortGoalPreview">Current Goal Preview</label>
            <input id="moonShortGoalPreview" className="rounded-md px-3 py-2 bg-secondry-color/20 text-secondry-color" onChange={() => {}} value={`${data.how} ${data.where} ${data.what} ${data.when}`} type="text"/>
        </div>
        <div className="mt-8 flex flex-col">
            <label className="text-secondry-color/60 font-semibold" htmlFor="moonShortGoalPreview">When will you achieve this goal?</label>
            <input id="moonShortGoalPreview" className="text-md rounded-md px-3 py-2 bg-secondry-color/20 text-secondry-color" value={`${data.when}`} onChange={(e)=>{setData((prev:any)=>{return{...prev,when: e.target.value}})}} placeholder="e.g. by 3pm" type="text"/>
            <small className="text-secondry-color/60">Make your change measurable</small>
        </div>
        <div className="h-10"/>
        <div className="flex gap-5">
            <PrimaryButton onClick={() => setScreenNumber(5)}>Back</PrimaryButton>
            <SecondryButton onClick={() => setScreenNumber(7)}>Next</SecondryButton>  
        </div>
  </>
}


interface ISevenWow {
  setScreenNumber: any;
  data: Idata;
  setData: any;
  currentActivity: any;
  setIsShow: any;
  handleSubmit: any;
}

const SevenWow: React.FC<ISevenWow> = ({handleSubmit,setScreenNumber,data,currentActivity,setIsShow}) => {
  const [finalCurrntGoal, setFinalCurrntGoal] = useState(`${data.how} ${data.where} ${data.what} ${data.when}`);


  const handleSubmitFinal = async () => {
    const body = {
      name: finalCurrntGoal,
      type: GOALS_TYPES_OBJ.current_goal,
      dynamicProperties: data,
      startDate: new Date(),
      activity: currentActivity._id
    }

    const res = await createGoal(body);

    if(res.success){
      setIsShow(false);
      if(handleSubmit){
        handleSubmit(res.goal);
      }
    }
  }
  
  return <>
        {/* <Title/>
        <div className="h-6"/> */}
        <Steps completedSteps={5} steps={STEPS}/>
        <div className="mt-8 flex flex-col">
            <label className="text-secondry-color/60 font-semibold" htmlFor="moonShortGoalPreview">Your Current Goal So Far</label>
            <input id="moonShortGoalPreview" className="rounded-md px-3 py-2 bg-secondry-color/20 text-secondry-color" onChange={(e) => {setFinalCurrntGoal(e.target.value)}} value={finalCurrntGoal} type="text"/>
        </div>
        <div className="mt-8 flex flex-col">
            <p className="text-md text-secondry-color">Does this Current goal make you want to take action right now?</p>
        </div>
        <div className="h-10"/>
        <div className="flex gap-5">
            <SecondryButton onClick={() => setScreenNumber(3)}>Not Quite, I Want to Refine It</SecondryButton>  
            <PrimaryButton onClick={handleSubmitFinal}>Yes! Let's go!</PrimaryButton>
        </div>
  </>
}



