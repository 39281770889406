import React,{useEffect,useState} from "react";
import { toast } from "react-toastify";
import {
  includeExcludeSurveys
} from "../../../Store/actions";
import {XL_FACTORS} from "../../../constants";
import {Tr,Td} from "../../../components";
import {mapper__100_100_to_0_100} from "../../../utils";
import { generateHash } from "../utils";

interface iCompletedSurvey {
  survey: any;
  index: number;
  group: any;
  isImpactSurvey: boolean;
  isDataAnonymized: boolean;
}

export const CompletedSurvey: React.FC<iCompletedSurvey> = ({ isDataAnonymized,survey, index ,group,isImpactSurvey}) => {

  const [isExcluded, setIsExcluded] = useState(false);
  const title = survey?.user?.activities.find((ac:any) => ac.category === survey.surveyName)?.name;

  const mapRange =  (value:any, inMin:any, inMax:any, outMin:any, outMax:any)=> {
    const ans = ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    return ans.toFixed(2)
  }

  const factrs = isImpactSurvey? [XL_FACTORS[0],XL_FACTORS[1]]:[...XL_FACTORS];
  const factors = factrs.map(XLFactor => <Td><span >{survey[XLFactor] ? mapRange(survey[XLFactor].rating.$numberDecimal,1,7,0,100) : "NA"}</span></Td>)
  const hash = generateHash(survey.user._id);
  

  const onIsExcludedChange = async (id:string)=>{
    const res = await includeExcludeSurveys(id,{ isexcluded: !isExcluded})
    
    if(res.success){
      toast.success(res.message);
      setIsExcluded(!isExcluded);
      // setCompletedSurveys(prev => {
      //   return prev.map(survey => survey._id === id? {...survey,isexcluded: !isExcluded}:survey)
      // })
    }
  }

  const calcullateTotalDuration = (survey:any)=> {
    let totalTime = 0;

    for (const key in survey) {
      if(XL_FACTORS.indexOf(key) !== -1){
          const ans = survey[key];
          totalTime += (ans.endtime - ans.starttime); 
      }
    }
 
    return parseFloat(`${(totalTime/1000)/60}`).toFixed(2) + " Mins";
  }


  const ExtraData = () => {

    if(isImpactSurvey){
      return <>
      <Td >{survey?.progress?.percentage}</Td>
      <Td >{survey?.progress?.currentGoalOrTask}</Td>
      </>;
    }

    return <>
          <Td >{mapper__100_100_to_0_100(survey?.lxscore).toFixed(2)}</Td>

          <Td >{survey?.newXLScore?.toFixed(2)}</Td>

          <Td >{survey?.flowScore?.toFixed(2)}</Td>

          <Td >{mapper__100_100_to_0_100(survey?.minLXScore).toFixed(2)}</Td>

          <Td >{mapper__100_100_to_0_100(survey?.maxLXScore).toFixed(2)}</Td>

          <Td>
            {
              isDataAnonymized? <>{hash}</>:<>
                {survey?.event?.description}
                <div className="flex flex-wrap gap-1 mt-2">
                    {
                      survey?.event?.tags?.map((tag:any,idx:number) => (
                        <span key={idx} className="bg-blue-600 text-white p-1 rounded-md">{tag}</span>
                        ))
                    }
                </div>
              </>
            }
          </Td>

          <Td >{survey?.xlChangeFromLastScore}</Td>

          <Td ><input type='checkbox'onChange={()=>onIsExcludedChange(survey?._id)} checked={isExcluded}  className='exclude-btn'/></Td>

          <Td>
              <div className="flex flex-wrap gap-1 mt-2">
                {
                  survey?.event?.feelings?.map((feeling:any,idx:number) => (
                    <span key={idx} className="bg-blue-600 text-white p-1 rounded-md">{feeling}</span>
                    ))
                }
              </div>
          </Td>
    </>
  }

  useEffect(() => {
    if(survey.isexcluded){
      setIsExcluded(true)
    }
    else{
      setIsExcluded(false)
    }
  }, [setIsExcluded,survey.isexcluded]);

  

  return (

    <Tr>
      <Td>{`${index + 1}`}</Td>

      <Td>{isDataAnonymized? hash:`${survey?.user?.forename} ${survey?.user?.surname}`}</Td>

      <Td >{isDataAnonymized? hash:survey?.user?.email}</Td>

      <Td >org</Td>

      <Td >dept</Td>
 
      <Td>
        <select
          className="form-control category-select"
          id="exampleFormControlSelect1"
        >
          <option>{group?.groupname}</option>
        </select>
      </Td>
    
      <Td >{survey.surveyName ? `${survey?.surveytitle?.title}`:survey?.surveytitle?.title}</Td>
      
      <Td >{survey.surveyName}</Td>

      <Td >{title}</Td>

      <Td >{new Date(survey?.createdAt).toLocaleString().split(",")[0]}</Td>

      <Td >{new Date(survey?.createdAt).toLocaleString().split(",")[1]}</Td>
 
      <Td >{calcullateTotalDuration(survey)}</Td>

      <Td >{survey.surveyPeriod?  `${new Date(new Date(survey?.createdAt).setMinutes(new Date(survey.createdAt).getMinutes()-survey.surveyPeriod)).toLocaleString().split(",")[1]}`:"NA"}</Td>


      {<>{factors}</>}

      <ExtraData/>
    </Tr>
  );
}

