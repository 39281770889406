import { useEffect,useState } from "react";
import {
  updateLoggedInUser,
  getTodaysEvents,
  getUserBetaDashboardData,
  getExploitationDurationsOfUser
 } from "../../Store/actions";
import {
  useLocation
} from "react-router-dom";
import {
  EventModal, 
  Loading
} from "../../components";
import {
  FormSkeleton,
  YourInsightsFullView,
  Tabs,
  InstantInsights,
  PeakStateActionChallenges,
  MobileView,
  DesktopView
} from "./components";
import { Main } from "../../layout";
import { useDispatch, useSelector } from "react-redux";
import { CogniPulsePhySelPhySelfieImpulseProgressModal } from "../../components/Modals";
import { getUserActivities } from "../../Store/actions/activity";
import { AppDispatch, RootState } from "../../Store/store";
import { DahsboardSkeleton } from "./components/DahsboardSkeleton";
import { TabsSkeleton } from "./components/TabsSkeleton";
// import { getCurrentGoal } from "../../Store/actions/goal";
// import { GOALS_TYPES_OBJ } from "../../constants";
// import { DialyGoalModal } from "../../components/Modals/DailyGoalModal";
// import { MoonShotGoalModal } from "../../components/Modals/MoonShotGoalModal";
import { toast } from "react-toastify";



const Index: React.FC<any> = ()  => {
  const location = useLocation();
  const [showAddEvent, setShowAddEvent] = useState(true);
  const [showProgress, setShowProgress] = useState(()=> location.state?.loading);
  const [numberOfCompletedPhycheSelfies, setNumberOfCompletedPhycheSelfies] = useState(null);
  const user = useSelector((state:RootState) => state.loggedInUser);
  const [isPhySelfieChanged, setIsPhySelfieChanged] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<any>("");

  

  useEffect(() => {
    (async ()=> {
      if(location.state?.loading){
        const res = await getTodaysEvents();
        if(res.success){
          // console.log(res);
          setNumberOfCompletedPhycheSelfies(res.numberOfCompletedPhycheSelfies);
        }
      }
    })()
  }, [showAddEvent,location.state?.loading]);

  if(!user && !location.state?.loading){
    return <div><Loading/></div>
  }

  if(!user && location.state?.loading){
    return <FormSkeleton/>
  }
  
  // if(showAddEvent && location.state?.loading){
  if(showAddEvent && location.state?.loading){
    return <Main>
                <EventModal
                  isOpen={showAddEvent}
                  setIsOpen={setShowAddEvent}
                  userId={user._id}
                  isManagemaneEvent={false}
                  isUpdate={null}
                  data={null}
                  setEvents={null}
                  eventId={null}
                  surveyTitle={null}
                  setIsPhySelfieChanged={setIsPhySelfieChanged}
                  surveyId={location.state.newCognipulseId}
                  surveyName={location.state.surveyName || selectedActivity.category}
                />
          </Main>
  }

  
  if(showProgress){
    const max = graterNumber(location.state?.numberOfCompletedSurveys,location.state?.numberOfNotifications,numberOfCompletedPhycheSelfies,location.state?.numberOfCompletedImpactSurveys);
    return <Main>
                    <CogniPulsePhySelPhySelfieImpulseProgressModal 
                        numberOfNotifications={max || 1}
                        numberOfCompletedSurveys={location.state?.numberOfCompletedSurveys}
                        numberOfCompletedImpactPulse={location.state?.isNonCogniPulse? location.state?.numberOfCompletedImpactSurveys:location.state?.numberOfCompletedImpactSurveys}
                        numberOfCompletedPhycheSelfies={numberOfCompletedPhycheSelfies}
                        isCogniPuseChanged={location.state?.isCogniPuseChanged}
                        isPhySelfieChanged={isPhySelfieChanged}
                        isImPuseChanged={location.state?.isImPuseChanged}
                        setShowProgress={setShowProgress}
                    />
            </Main>
  }

  if(user && user.canAccessDashboard === false){
    return <div className="h-[93vh] flex justify-center items-center">
      <p>Thank you for tracking you state. Your data will be available soon!</p>
    </div>
  }

  return <><NewDashboard selectedActivity={selectedActivity} setSelectedActivity={setSelectedActivity}/></>
}

export default Index;

const NewDashboard: React.FC<any> = ({selectedActivity,setSelectedActivity}) => {
  const location = useLocation();
  const [deshboardData,setDeshboardData] = useState<any>(null);
  const [isLoading,setIsLoading] = useState(true);
  const [isAnimation,setIsAnimation] = useState(()=> location.state?.loading? true:false);
  const [,setSecVis] = useState({
    actionChallenges: false,
    streamGraph: false
  });
  const [isYourInsightsFullViewOn,setIsYourInsightsFullViewOn] = useState(false);
  const [currentTourStep] = useState(null);
  const dispatch: AppDispatch = useDispatch();
  const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
  const [, setIsShowOnboarding] = useState(()=> loggedInUser.isDashboardTutorialFinished === false? true:false);
  const [clickedOn] = useState("");
  const [isInstantInsightsOpen,setIsInstantInsightsOpen] = useState(false);
  const [isPeakStateActionChallengesOpen,setIsPeakStateActionChallengesOpen] = useState(false);
  const [activities, setActivities] = useState([]);
  const user = useSelector((state:RootState) => state.loggedInUser);
  const isMobileScreen = useIsMobile();
  const [expliotationDuration, setExpliotationDuration] = useState([]);
  

  useEffect(() => {
    if(selectedActivity){
      (async () => {
        //// prev query 
        // const surveyName = utf8ToHex(`${selectedActivity.category || location.state?.surveyName}`);
        const query = `activity=${selectedActivity._id}`;
        // const query = `activityId=${selectedActivity._id}`;
        // console.log(query);
        
        setIsLoading(true);
        const res = await getUserBetaDashboardData(query);
        setIsLoading(false);
        console.log(res);
  
        if(res.success){
          setDeshboardData({...res});
          dispatch(updateLoggedInUser({hasSeenDashboardFirstTime: true}));
        }
        else {
          toast.error(res.message);
        }
        setIsLoading(false);
      })();
    } 
  }, [user?._id,selectedActivity,dispatch,location.state?.surveyName]);

  useEffect(() => {
    if(location.state?.payload?.isShowDashboard){
      setIsShowOnboarding(true);
    }
  }, [location.state?.payload?.isShowDashboard]);

  useEffect(() => {
    (async () => {
      const res = await getUserActivities();
      if(res?.success){
        setActivities(res.activities);
        const ct = res.activities.find((ac:any) => ac.name === location.state?.activityId);
        if(ct){
          setSelectedActivity(ct)
        }
        else {
          setSelectedActivity(res.activities[0])
        }
      }
    })()
  }, [location.state?.activityId,setSelectedActivity]);

  useEffect(() => {
    (async () => {
      if(selectedActivity){
        const res = await getExploitationDurationsOfUser(selectedActivity._id);
        if(res.success){
          console.log(selectedActivity);
          console.log(res.exploitationDurations);
          setExpliotationDuration(res.exploitationDurations);
        }
      }
    })()
  }, [selectedActivity]);
  
  if(!deshboardData){
    return <Main>
      <TabsSkeleton/>
      <DahsboardSkeleton/>
    </Main>
  }

  if(isLoading){
      return <>
      <Main>
        <Tabs setActivities={setActivities} activities={activities} selectedActivity={selectedActivity} setCurrentActivity={setSelectedActivity}/>
        <DahsboardSkeleton/>
      </Main>
      </>
  }

  const {
    XLScore,
    scoreOfAllfactors,
    percentageOfChangeOfFactors,
    historiclData,
    XLChange,
    higestDiffrance,
    factorsMinMax,
    currentActionChallengesWithSteps,
    flowScore,
    flowChange,
    goalProgress
  }:any = deshboardData;
  
  

  
  
  if(isYourInsightsFullViewOn){
    return <YourInsightsFullView 
            deshboardData={deshboardData} 
            selectedActivity={selectedActivity} 
            XLChange={XLChange} 
            historiclData={historiclData} 
            factorsMinMax={factorsMinMax} 
            percentageOfChangeOfFactors={percentageOfChangeOfFactors}
            setIsOpen={setIsYourInsightsFullViewOn}
            expliotationDuration={expliotationDuration}
          />
  }

  if(isInstantInsightsOpen){
    return <InstantInsights 
              higestDiffrance={higestDiffrance} 
              currentActionChallengesWithSteps={currentActionChallengesWithSteps} 
              setSecVis={setSecVis} 
              isAnimation={isAnimation} 
              user={user} 
              selectedActivity={selectedActivity} 
              factorsMinMax={factorsMinMax} 
              percentageOfChangeOfFactors={percentageOfChangeOfFactors} 
              scoreOfAllfactors={scoreOfAllfactors}
              setIsOpen={setIsInstantInsightsOpen}
            />
  }

  if(isPeakStateActionChallengesOpen){
    return <PeakStateActionChallenges 
              clickedOn={clickedOn} 
              selectedActivity={selectedActivity} 
              setIsOpen={setIsPeakStateActionChallengesOpen} 
           />
  }


  if(!deshboardData?.len){
    return <>
        <Main>
            <Tabs setActivities={setActivities} activities={activities} selectedActivity={selectedActivity} setCurrentActivity={setSelectedActivity}/>
            <div className="mt-10">
              <h1 className="text-2xl text-center font-bold text-secondry-color">Empty!</h1>
            </div>
        </Main>
    </>
  }
  
  return (
    <>
        <Main>
            <Tabs setActivities={setActivities} activities={activities} selectedActivity={selectedActivity} setCurrentActivity={setSelectedActivity}/>
           
            {
              !isMobileScreen && <>
                    <DesktopView 
                      XLChange={XLChange}
                      XLScore={XLScore}
                      selectedActivity={selectedActivity}
                      isAnimation={isAnimation}
                      isYourInsightsFullViewOn={isYourInsightsFullViewOn}
                      currentTourStep={currentTourStep}
                      historiclData={historiclData}
                      setIsYourInsightsFullViewOn={setIsYourInsightsFullViewOn}
                      currentActionChallengesWithSteps={currentActionChallengesWithSteps}
                      higestDiffrance={higestDiffrance}
                      user={user}
                      factorsMinMax={factorsMinMax} 
                      percentageOfChangeOfFactors={percentageOfChangeOfFactors} 
                      scoreOfAllfactors={scoreOfAllfactors}
                      deshboardData={deshboardData}
                      flowScore={flowScore}
                      flowChange={flowChange}
                      dialGoal={deshboardData?.latestSurvey?.dialGoal}
                      goalProgress={goalProgress}
                    />
              </>
            }
            {
              isMobileScreen && <>
                  <MobileView
                    XLChange={XLChange}
                    XLScore={XLScore}
                    selectedActivity={selectedActivity}
                    isAnimation={isAnimation}
                    isYourInsightsFullViewOn={isYourInsightsFullViewOn}
                    currentTourStep={currentTourStep}
                    historiclData={historiclData}
                    setIsYourInsightsFullViewOn={setIsYourInsightsFullViewOn}
                    currentActionChallengesWithSteps={currentActionChallengesWithSteps}
                    higestDiffrance={higestDiffrance}
                    factorsMinMax={factorsMinMax} 
                    percentageOfChangeOfFactors={percentageOfChangeOfFactors} 
                    scoreOfAllfactors={scoreOfAllfactors}
                    setIsAnimation={setIsAnimation}
                    flowScore={flowScore}
                    flowChange={flowChange}
                  />
              </>
            }
        </Main>
        {/* {
          (isShowMoonShotGoalPopup && !isShowDailyGoalPopup) && <MoonShotGoalModal setIsShow={setIsShowMoonShotGoalPopup} currentActivity={selectedActivity}/>
        }
        {
          isShowDailyGoalPopup && <DialyGoalModal setIsShow={setIsShipDailyGoalPopup} currentActivity={selectedActivity}/>
        } */}
    </>
  )
}

function graterNumber(...rest:any){
  return  rest.filter((v:any) => typeof v === 'number').sort().reverse()[0];
}

function useIsMobile(){
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500? true:false);


  useEffect(() => {

      window.addEventListener("orientationchange",(e:any) => {
        if(e.target.screen.orientation.type === "portrait-primary"){
          setIsMobile(true);
        }
        else {
          setIsMobile(false);
        }
      })

    return ()=> {
      window.removeEventListener("orientationchange",() => {
        setIsMobile(false);
      });
    }
  }, []);

  return isMobile
}

// function useMoonShotGoalLogic(activityId: string){
//   const [isShow, setIsShow] = useState(false);

//   useEffect(() => {
//       if(activityId){
//         (async () => {
//           const res = await getCurrentGoal(GOALS_TYPES_OBJ.moonshot_goal,activityId);
          
//           console.log(res);
          

//           if(res.success){
//             if(!res.goal){
//               setIsShow(true);
//             }
//           }
//         })()
//       }
//   }, [activityId]);

//   return [isShow,setIsShow];
// }

// function useDailyGoalPopupLogic(activityId:any){
//   const [isShow, setIsShow] = useState(false);

//   useEffect(() => {
//     (async () => {
//       const res = await getCurrentGoal(GOALS_TYPES_OBJ.day_goal,activityId);
//       if(res.success && !res.goal){
//         setIsShow(true)
//       }
//     })()
//   }, [activityId]);

//   return [isShow,setIsShow];
// }













