import { XL_FACTORS } from "../../../constants";
import { mapper__100_100_to_0_100 } from "../../../utils";
import { ImpactTableHeaders, sdTableHeader, tableHeaders } from "../constants";




export function prepareCSVData(completedSurveys:any,groups:any,isImpactSurvey:boolean,isDataAnonymized: boolean){


    const calcullateTotalDuration = (survey:any)=> {
      let totalTime = 0;
  
      for (const key in survey) {
        if(XL_FACTORS.indexOf(key) !== -1){
            const ans = survey[key];
            totalTime += (ans.endtime - ans.starttime); 
        }
      }
   
      return parseFloat(`${(totalTime/1000)/60}`).toFixed(2) + " Mins";
    }
  
    const mapRange =  (value:any, inMin:any, inMax:any, outMin:any, outMax:any)=> {
      const ans = ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
      return ans.toFixed(2)
    }
  
    if(!completedSurveys){
      return null;
    }
  
    const headers = isImpactSurvey? ImpactTableHeaders:tableHeaders

    const array = [[...headers,"Survey Name"]];

    completedSurveys.forEach(async (survey:any,i:number) => {
      const group = groups?.find((gp:any) => {
        const user = gp.users?.find((user:any) => user._id === survey?.user?._id);
        if(user){
          return gp
        }
        return undefined 
      })
      const duration = calcullateTotalDuration(survey);
      
      const hash =  generateHash(survey.user?._id);

      

      const factrs = isImpactSurvey? [XL_FACTORS[0],XL_FACTORS[1]]:[...XL_FACTORS];
      const factors = factrs.map(XLFactor => {
        return survey[XLFactor] ? mapRange(survey[XLFactor].rating.$numberDecimal,1,7,0,100) : "NA";
      });
      // const eventTags = survey?.event?.tags?.map((tag:any) => tag);
      const eventFeelings = survey?.event?.feelings?.map((tag:any) => tag);

      const extras = !isImpactSurvey? [
          mapper__100_100_to_0_100(survey?.lxscore).toFixed(2),
          survey?.newXLScore?.toFixed(2),
          survey?.flowScore?.toFixed(2),
          survey.minLXScore,  
          survey.maxLXScore,
          hash, //`${survey?.event?.description} ${eventTags?.join(",")}`,
          survey?.xlChangeFromLastScore,
          survey?.isexcluded,
          eventFeelings?.join(",")
      ]:[];

    
      const arr = [
        i+1,
        hash, //`${survey.user?.forename} ${survey.user?.surname}`,
        hash, // survey.user?.email,
        survey.user?.organizationName,  
        survey.user?.departent,  
        group?.groupname,  
        survey?.surveytitle?.title,
        survey.surveyName,
        survey?.user?.activities.find((ac:any) => ac.category === survey.surveyName)?.name || "",  
        new Date(survey?.createdAt).toLocaleString().split(",")[0],  
        new Date(survey?.createdAt).toLocaleString().split(",")[1].trim(),  
        duration,
        new Date(new Date(survey?.createdAt).setMinutes(new Date(survey.createdAt).getMinutes()-survey.surveyPeriod)).toLocaleString().split(",")[1],
        ...factors,
        ...extras,
        survey.surveyName 
      ]

  
      array.push(arr);
    });

 

    return array;
}
  
export function prepareCSVData1(completedSurveys:any,groups:any){
  
  
    if(!completedSurveys){
      return null;
    }
  
    const array = [sdTableHeader];
  
    completedSurveys.forEach((item:any,i:number) => {
      const survey = item.data;
      const group = groups?.find((gp:any) => {
        const user = gp.users?.find((user:any) => user._id === survey?.user?._id);
        if(user){
          return gp
        }
        return undefined 
      })
  
      const arr = [
        i+1,
        `${survey.user?.forename} ${survey.user.surname}`,
        survey.user?.email,
        survey.user?.organizationName,  
        survey.user?.departent,  
        group?.groupname,  
        survey?.surveytitle?.title,  
        new Date(item.date).toLocaleString().split(",")[0],  
        new Date(item.date).toLocaleString().split(",")[1].trim(),  
        Number(item.OPQ)
      ]
  
      array.push(arr);
    })
  
    return array;
       }
  
export function prepareCSVData2(events:any){
  
  
    const array = [[
      "#",
      "email",
      "Date",
      "Time",
      "Description",
      "SurveyName",
      "Tags",
      "Event Date",
      "Event Time",
      "Feelings"
    ]];
  
    events.forEach((item:any,i:number) => {
  
      const eventTags = item?.tags?.map((tag:any) => tag);
      const eventFeelings = item?.feelings?.map((tag:any) => tag);
  
      const arr = [
        i+1,
        item.user?.email,
        new Date(item.createdAt).toLocaleString().split(",")[0],
        new Date(item.createdAt).toLocaleString().split(",")[1].trim(),
        item.description,
        item.surveyName,
        eventTags.join(","),
        new Date(item.date).toLocaleString().split(",")[0],
        new Date(item.date).toLocaleString().split(",")[1].trim(),
        eventFeelings.join(",")
      ]
  
      array.push(arr);
    });
  
    return array;
       }
  
    
export function generateHash(string: string){
  const first = string.substring(0,12).split("").reverse().join("");
  const last = string.substring(12,24).split("").reverse().join("");;
  return `${last}${string}${first}`;
}
