import { toast } from "react-toastify";
import {fetchRequest} from "../../utils";


const root = window.location.hostname === "localhost"? "http://localhost:5000":"https://tenxproductivityreactapp.herokuapp.com";


export const getLeafToCOCNodesByLeafNodeId = async (leafNodeId:string) =>{
    try {
        const res = await fetchRequest(`${root}/api/v1/xlrate/nodes/${leafNodeId}`,"GET",{});

        return res;
    } catch (error:any) {
        toast.error(error.message);
    }
}


export const getExploitationDurationsOfUser = async (activityId:string) =>{
    try {
        const res = await fetchRequest(`${root}/api/v1/xlrate/exploitation-durations/${activityId}`,"GET",{});

        return res;
    } catch (error:any) {
        toast.error(error.message);
        return {
            success: false
        }
    }
}




