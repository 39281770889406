import { Main } from "../../../layout"
import { checkMark, flame } from "../../../assets";
import { SecondryButton, ShadowBox } from "../../../components";
import { NUMBER_WITH_DAYS } from "../../../constants";
import { useNavigate } from "react-router-dom";



export interface IStreak {
    user: string;
    startDate: Date;
    endDate: Date | null;
    currentStreak: number;
    longestStreak: number;
    preferredDaysPerWeek: number[];
    daysUsedThisWeek: number[];
    lastStreakDate: Date | null;
  }


interface ISt {
    streakData: IStreak | null;
    navigatorStateData: any
}

export const Streak: React.FC<ISt> = ({navigatorStateData,streakData}) => {
    const navigate = useNavigate();
    
    const handleOk = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/my-dashboard`, { state: navigatorStateData});
    }
  
    if(!streakData){
      return null;
    }  
  
    // console.log(streakData);
    

    const flameWidth = 50+((streakData.currentStreak <= 30? streakData.currentStreak:30)*10);


    return <Main>
        <div className="w-full h-auto min-h-[calc(100vh-64px)] flex justify-center items-center">
            <ShadowBox>
                <div className="mx-auto max-w-[450px] pt-10 border">
                    <div className="mb-4 flex flex-col items-center">
                        {
                            streakData.currentStreak !== 0 && (<>
                                <div className="flex justify-center items-end">
                                    <img src={flame} alt="flame" width={flameWidth} height={flameWidth}/>
                                    <h1 style={{WebkitTextStroke: "3px black",WebkitTextFillColor: "transparent"}} className='text-[30px] mb-2 absolute leading-none text-center text-blue-200 mb-0'>{streakData.currentStreak}</h1>
                                </div>
                                <h2 className='text-center text-[25px] font-bold text-secondry-color'>day streak!</h2>
                                <p className='mb-0 text-secondry-color text-[14px] text-center' >You're on fire! Keep the self improvement flame lit every day!</p>
                                
                            </>) 
                        }
                        
                        {
                            streakData.currentStreak === 0 && (<>
                            <img src="https://media.tenor.com/8McIGu0Tf_QAAAAi/fire-joypixels.gif" alt="flame" width={30} height={30}/>
                            <p className='mb-0 text-green-600 text-[14px] text-center mt-3'>Nice! You just ignited the flame of self-improvement. Keep fanning it every day and watch it grow.</p>
                            </>)
                        }
                    </div>

                    <div className="border-[2px] border-secondry-color/20 rounded p-2 mb-14">
                        <div className="flex w-full">
                            {
                                streakData.preferredDaysPerWeek.map((day) => (
                                <div className="flex-1 font-bold text-[16px] h-[30px] flex justify-center items-center">{NUMBER_WITH_DAYS[day as keyof typeof NUMBER_WITH_DAYS]}</div>
                                ))
                            }
                        </div>

                        <div className="flex w-full border-b pb-2 relative text-secondry-color">
                        {
                            streakData.preferredDaysPerWeek.map((d:any,i: number) => {
                                if(streakData.daysUsedThisWeek.includes(d)){
                                    return <div className={`flex-1 h-[30px] flex justify-center items-center`}>
                                                <div className="w-[30px] h-[30px] bg-gray-200 rounded-full flex justify-center items-center bg-green-600">
                                                    <img src={checkMark} className="w-[60%] h-[60%]" alt="check-mark"/>
                                                </div>
                                            </div>
                                }
                                return <div className={`flex-1 h-[30px] flex justify-center items-center`}>
                                            <div className="w-[30px] h-[30px] bg-gray-200 rounded-full bg-secondry-color/20"/>
                                        </div>
                            })
                        }
                        </div>

                        <div className="pt-2">
                            <p className='mb-0 text-center text-secondry-color'>Track your XL score every day to build your continuous improvement streak</p>
                        </div>
                    </div>
                    <SecondryButton style={{padding: "12px 0"}} onClick={handleOk}>OK</SecondryButton>
                </div>
            </ShadowBox>
        </div>
    </Main>
}