import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { getAllSurveyTitles, getUniversalSurveyTitle, getUserById } from "../../../Store/actions";
import { getUserActivities } from "../../../Store/actions/activity";
import { useState } from "react";
import { SecondryButton } from "../../../components";
// import ClipLoader from "react-spinners/ClipLoader";


interface IImpulseButton {
    selectedActivity: any;
}

export const ImpulseButton: React.FC<IImpulseButton> = ({selectedActivity}) => {
    const navigator = useNavigate();
    const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
    const [isLoading, setIsLoading] = useState(false);

    const handleImpulse = async (e:any) => {
        e.preventDefault();
        if(isLoading){
          return;
        }
        // setIsLoading(true);
        const resUser = await getUserById(loggedInUser._id);
        const res = await getAllSurveyTitles();
        const universalRes =  await getUniversalSurveyTitle();
        const resAct = await getUserActivities();
        
        
        if(resUser.success && res.success){
          const impactSurveyNames = ["Impact","impact survey"];
          let selectedSurvey:any = res.surveyTitles.find((st:any) => resUser.user?.unRegularSurveys.indexOf(st._id) !== -1);
          selectedSurvey = selectedSurvey || res.surveyTitles.find((st:any) => impactSurveyNames.indexOf(st.title) !== -1);
          const activity = resAct.activities.find((item:any) => item.name === selectedActivity.name);
          
          
          if(selectedSurvey){
            navigator(`/survey/${loggedInUser._id}/${selectedSurvey._id}/${new Date().getTime()}?new=true&isNonCogniPulse=true&surveyName=${activity.category}&title=${activity.name}&activityId=${activity._id}`);
          }
          else {
            navigator(`/survey/${loggedInUser._id}/${universalRes.surveyTitle._id}/${new Date().getTime()}?surveyName=${activity.category}&title=${activity.name}&activityId=${activity._id}&new=true`);
          }
        }
        else {
          toast.error("Something went wrong!");
        }
        setIsLoading(false);
    }
    
    return <>
        <>
            {
              !isLoading && <>
                          {/* <img onClick={handleImpulse}  id="impact" src={imPulse} alt='imPulse' className='h-[50px] cursor-pointer'/>
                          <span onClick={handleImpulse}   id="impact"  className='cursor-pointer'>ImPulse</span> */}
                <SecondryButton style={{fontSize: "14px",padding:  "6px 2px"}} onClick={handleImpulse}>ImPulse</SecondryButton>
              </>
            }
            {/* {
              isLoading && <SecondryButton style={{fontSize: "14px",padding:  "6px 2px",display: "flex",justifyContent: "center",alignItems: "center"}}><ClipLoader color={"#ffffff"} size={18}/></SecondryButton>
            } */}
        </>
    </>
}


