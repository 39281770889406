import { DOMAIN_NAME } from "../../constants";
import {fetchRequest} from "../../utils";



const ABSOLUTE_PATH = `${DOMAIN_NAME}/streaks`

export const getMyStreak = async (category: string) => {

    const res = await fetchRequest(`${ABSOLUTE_PATH}/me/${category}`,"GET",{});

    return res;

}

