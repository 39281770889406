import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import { getAllSurveyTitles, getUniversalSurveyTitle, getUserById } from "../../../Store/actions";
import { getUserActivities } from "../../../Store/actions/activity";
import { useState } from "react";
import { SecondryButton } from "../../../components";
// import ClipLoader from "react-spinners/ClipLoader";


interface ICogniPulseButton {
    selectedActivity: any;
}

export const CogniPulseButton: React.FC<ICogniPulseButton> = ({selectedActivity}) => {
    const navigator = useNavigate();
    const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
    const [isLoading, setIsLoading] = useState(false);

    const handleCognipulseClick = async (e:any) => {
        e.preventDefault();

        if(isLoading){
            return;
        }
        // setIsLoading(true);

        const resUser = await getUserById(loggedInUser._id);
        const res = await getAllSurveyTitles();
        const universalRes =  await getUniversalSurveyTitle();
        const resAct = await getUserActivities();
        

        if(resUser.success && res.success){
          const activity = resAct.activities.find((item:any) => item.name === selectedActivity.name);
          navigator(`/survey/${loggedInUser._id}/${universalRes.surveyTitle._id}/${new Date().getTime()}?surveyName=${activity.category}&title=${activity.name}&activityId=${activity._id}&new=true`);
        }
        else {
          toast.error("Something went wrong!");
        }
        setIsLoading(false);
    }

    return <>
        <>
            {
                !isLoading && <>
                    {/* <img onClick={handleCognipulseClick}  src={cogniPulse} alt='cogniPulse' className='h-[50px] cursor-pointer'/>
                    <span onClick={handleCognipulseClick}  className='cursor-pointer'>CogniPulse</span> */}
                     <SecondryButton style={{fontSize: "14px",padding:  "6px 2px"}} onClick={handleCognipulseClick}>CogniPulse</SecondryButton>
                </>
            } 
            {/* {
              isLoading && <SecondryButton style={{fontSize: "14px",padding:  "6px 2px",display: "flex",justifyContent: "center",alignItems: "center"}}><ClipLoader color={"#ffffff"} size={18}/></SecondryButton>
            } */}
        </>
    </>
}


