import { useEffect, useRef, useState } from "react";
import { Factors, PrimaryButton, InfoIconWithToolTip, EventModal, SecondryButton } from "../../../components";

import {
      allSurveysWithXLScoresToHistoricalSPQ,
      firstLetterUpperCase,
      mapper__100_100_to_0_100,
} from "../../../utils";
import {FullView, ScoreInBrainWithLabels} from "./index";
// import {Main} from "../../../layout";
import { Chart as ChartJS} from "chart.js/auto";
import zoomPlugin from 'chartjs-plugin-zoom';
import { useSelector } from "react-redux";
import { FACTOR_COLORS, PRIMARY_COLOR, SECONDRY_BUTTON_COLOR, SECONDRY_COLOR, XL_FACTORS } from "../../../constants";
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line } from "react-chartjs-2";
import { useScrollToTop } from "../../../hooks";
import dayjs from 'dayjs';
import isBetween from "dayjs/plugin/isBetween";
import { EMOJIES } from "../../../constants/constants";
import { XLFactorsTooltipText } from "../constant";
import { RootState } from "../../../Store/store";
import { FlowScoreBrainWithLabels } from "./FlowScoreBrainWithLabels";
import { mapper_0_100_to__100_100 } from "../../../utils/mapper_0_100_to__100_100";
  

  dayjs.extend(isBetween);
  ChartJS.register(zoomPlugin);
  ChartJS.register(annotationPlugin);


  interface IYourInsightsFullView {
    deshboardData: any;
    XLChange: any;
    selectedActivity: any;
    factorsMinMax: any;
    percentageOfChangeOfFactors: any;
    setIsOpen: any;
    historiclData: any;
    expliotationDuration: any;
  }

  export const YourInsightsFullView: React.FC<IYourInsightsFullView> = ({expliotationDuration,deshboardData,XLChange,selectedActivity,factorsMinMax,percentageOfChangeOfFactors,setIsOpen,historiclData}) => {
      const streamGraphDiv = useRef(null);
      const XLScoreContainer = useRef(null);
      const FlowScoreContainer = useRef(null);
      const [events, setEvents] = useState(deshboardData.events || []);
      const data = [...events.map((ev:any) => {return {...ev,year: ev.date,isEvent: true}}),...historiclData].sort((a,b) => new Date(a.year).getTime() - new Date(b.year).getTime())
      const [currentCognipulseIndex, setCurrentCognipulseIndex] = useState(data.findIndex(item => !item.isEvent && new Date(item.year).getTime() === new Date(historiclData[historiclData.length-1].year).getTime()));
      const [currentIndex, setCurrentIndex] = useState(data.findIndex(item => !item.isEvent && new Date(item.year).getTime() === new Date(historiclData[historiclData.length-1].year).getTime()));
      const [brushSelection, setBrushSelection] = useState<any>({range: [data[0].year,data[data.length-1].year]});
      const CURRENT_FACTORS = data[currentCognipulseIndex];
      const IS_TODAY = dayjs(CURRENT_FACTORS?.year).format("yy-mm-dd") === dayjs().format("yy-mm-dd");
      const SELECTED_RANGES = brushSelection? historiclData.map((item:any,index:number) => {return {...item,index}}).filter((item:any) => dayjs(item.year).isBetween(dayjs(brushSelection.range[0]),dayjs(brushSelection.range[1]))):historiclData;
      const SUM_OF_XL_SCORES = SELECTED_RANGES.reduce((a:any,b:any)=> a + b.XLScore,0)/SELECTED_RANGES.length;
      const MOST_FREQUENT_FACTORS = useFrequencyOfFactorsCalculator(SELECTED_RANGES);
      const loggedInUser = useSelector((state:RootState) => state.loggedInUser);
      const [isOpenAddEventsModal, setIsOpenAddEventsModal] = useState(false);
      const [isUpdateEvent, setIsUpdateEvent] = useState(false);
      const [eventData, setEventData] = useState(false);
      const [eventId, setEventId] = useState(null);
      const NEXT_DISABLED = currentIndex === null || currentIndex === data.length-1;
      const PREV_DISABLED = currentIndex === 0;
      const [streamGraphChartData, setstreamGraphChartData] = useState(()=> {
          return {
                    labels: historiclData.map((item:any) => new Date(item.year)),
                    datasets: [
                        {
                          type: "line",
                          label: "Innovation",
                          data: deshboardData.impactSurveyData?.map((ev:any) => {return {x: new Date(ev.createdAt),y: ev.innovation}}),
                          borderColor: "#F7FE85",
                          backgroundColor: "#F7FE85",
                          stack: 'combined',
                          pointRadius: 0,
                          tension: .4,
                          hidden: true
                        },
                        {
                          type: "line",
                          label: "Productivity",
                          data: deshboardData.impactSurveyData?.map((ev:any) => {return {x: new Date(ev.createdAt),y: ev.productivity}}),
                          borderColor: "#5394FB",
                          backgroundColor: "#5394FB",
                          // stack: 'combined',
                          pointRadius: 0,
                          tension: .4,
                          hidden: true
                        },
                        {
                          type: "line",
                          label: "CogniFlow",
                          data: deshboardData.impactSurveyData?.map((ev:any) => {return {x: new Date(ev.createdAt),y: ev.cogniFlow}}),
                          borderColor: "#B453FB",
                          backgroundColor: "#B453FB",
                          stack: 'stack1',
                          pointRadius: 0,
                          tension: .4
                        },
                        {
                          type: "scatter",
                          label: "Events",
                          data: deshboardData.events?.map((ev:any) => {return {x: new Date(ev.date),y:-85,description: ev.description,tags: ev.tags}}),
                          borderColor: "#db03fc",
                          backgroundColor: "#db03fc",
                          // stack: 'combined',
                        },
                        {
                          type: "scatter",
                          label: "Action Challenges",
                          data: deshboardData.actionChallenges?.map((ac:any) => {return {x: new Date(ac.createdAt),y:-95,steps: ac.steps,actionChallenge: ac.actionChallenge}}),
                          borderColor: "#4F46E5",
                          backgroundColor: "#4F46E5",
                          stack: 'combined',
                        },
                        {
                          type: "line",
                          label: "Goal Progress",
                          data: deshboardData.goalProgress?.map((gp:any) => {return {x: new Date(gp.createdAt),y: mapper_0_100_to__100_100(gp.percentage)}}),
                          borderColor: "#ff0000",
                          backgroundColor: "#ff0000",
                          // stack: 'combined',
                        },
                        {
                          type: "bar",
                          label: "Test Point",
                          data: expliotationDuration?.map((ed:any) => {return {x: [new Date(ed.startTime),new Date(ed.endTime)],y: -60,objective: ed.objectivesTillLifeDivisions[ed?.objectivesTillLifeDivisions?.length-1]}}),
                          borderColor: "#0000ff",
                          backgroundColor: "#0000ff",
                          // stack: 'combined',
                        },
                    ]
                }
      });
      const [chart, setChart] = useState<any>(null);
      const [isEventsChecked,setIsEventsChecked] = useState(false);
      const allSurveysWithXLScores = historiclData.map((item:any) => {return {createdAt: item.year,XLScore: item.XLScore}});
      const historicalOPQ = allSurveysWithXLScoresToHistoricalSPQ(allSurveysWithXLScores);
      const [selectedEvents, setSelectedEvents] = useState<any>([]);
      useEventsChangeEffect({events,setstreamGraphChartData});
      useHistoricalDataChangeEffect({historiclData,setstreamGraphChartData});
      useScrollToTop();

      

      const handlePrev = () => {

            const prevIndex = currentIndex-1;
            const prevItem = data[prevIndex];

            if(!prevItem){
              return;
            }

            const prevItemDate = new Date(prevItem.year);
            chart.options.plugins.annotation.annotations.line1.xMin = prevItemDate;
            chart.options.plugins.annotation.annotations.line1.xMax = prevItemDate;
            chart.update();
            setBrushSelection({range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]})
            setCurrentIndex(prevIndex);  

            if(prevItem.isEvent){
              setSelectedEvents([prevItem]);
              return;
            }
            setSelectedEvents([]);
            setCurrentCognipulseIndex(prevIndex);
   

            // old code 
          // const currentCognipulseDate = new Date(historiclData[currentCognipulseIndex].year);
          // const previousCognipulse = historiclData[currentCognipulseIndex-1];
          // const previousCognipulseDate = new Date(previousCognipulse.year);
          // const eventsInBetweenPreviousAndCurrentCognipulse = events.filter((event:any) => new Date(event.createdAt).getTime() > previousCognipulseDate.getTime() && new Date(event.createdAt).getTime() < currentCognipulseDate.getTime() && new Date(event.createdAt).getTime() < new Date(chart.options.plugins.annotation.annotations.line1.xMin).getTime())
          // // .sort((a:any,b:any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        

          // if(eventsInBetweenPreviousAndCurrentCognipulse.length > 0){
          //   const currentEvent = eventsInBetweenPreviousAndCurrentCognipulse[eventsInBetweenPreviousAndCurrentCognipulse.length-1];
          //   setSelectedEvents([currentEvent]);
          //   if(currentEvent.survey !== previousCognipulse.surveyId){
          //     const currentEventDate = new Date(currentEvent.createdAt);
          //     chart.options.plugins.annotation.annotations.line1.xMin = currentEventDate;
          //     chart.options.plugins.annotation.annotations.line1.xMax = currentEventDate;
          //     chart.update();
          //     return;
          //   }
          // }
          
          // chart.options.plugins.annotation.annotations.line1.xMin = previousCognipulseDate;
          // chart.options.plugins.annotation.annotations.line1.xMax = previousCognipulseDate;
          // chart.update();

          // const selection = {
          //     range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
          // };

          // setBrushSelection(selection);

          // setCurrentCognipulseIndex(prev => {
          //     if(prev === 0){
          //         return 0;
          //     }
          //     return prev-1;
          // });
      }
    
      const handleNext = () => {

          const nextIndex = currentIndex+1;
          const nextItem = data[nextIndex];

          if(!nextItem){
            return;
          }

          const nextItemDate = new Date(nextItem.year);
          chart.options.plugins.annotation.annotations.line1.xMin = nextItemDate;
          chart.options.plugins.annotation.annotations.line1.xMax = nextItemDate;
          chart.update();

          setBrushSelection({range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]})
          setCurrentIndex(nextIndex);  

          if(nextItem.isEvent){
            setSelectedEvents([nextItem]);
            return;
          }
          setSelectedEvents([]);
          setCurrentCognipulseIndex(nextIndex);

          // old code
          // const currentCognipulseDate = new Date(historiclData[currentCognipulseIndex].year);
          // const nexCognipulse = historiclData[currentCognipulseIndex+1];

          // let nexCognipulseDate:any = null;
          // let eventsInBetweenNextAndCurrentCognipulse:any = null;

          // if(nexCognipulse){
          //   nexCognipulseDate = new Date(nexCognipulse.year);
          //   eventsInBetweenNextAndCurrentCognipulse = events.filter((event:any) => new Date(event.createdAt).getTime() < nexCognipulseDate.getTime() && new Date(event.createdAt).getTime() > currentCognipulseDate.getTime() && new Date(event.createdAt).getTime() > new Date(chart.options.plugins.annotation.annotations.line1.xMin).getTime())
          // }
          // else {
          //   eventsInBetweenNextAndCurrentCognipulse = events.filter((event:any) => new Date(event.createdAt).getTime() > currentCognipulseDate.getTime() && new Date(event.createdAt).getTime() > new Date(chart.options.plugins.annotation.annotations.line1.xMin).getTime())
          // }


          // if(eventsInBetweenNextAndCurrentCognipulse.length > 0){
          //   const currentEvent = eventsInBetweenNextAndCurrentCognipulse[0];
          //   setSelectedEvents([currentEvent]);
          //   if((currentEvent.survey !== nexCognipulse?.surveyId) || !nexCognipulse){
          //     const currentEventDate = new Date(currentEvent.createdAt);
          //     chart.options.plugins.annotation.annotations.line1.xMin = currentEventDate;
          //     chart.options.plugins.annotation.annotations.line1.xMax = currentEventDate;
          //     chart.update();
          //     return;
          //   }
          // }
          
          
          // chart.options.plugins.annotation.annotations.line1.xMin = nexCognipulseDate;
          // chart.options.plugins.annotation.annotations.line1.xMax = nexCognipulseDate;
          // chart.update();

          // const selection = {
          //     range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
          // };

          // setBrushSelection(selection);

          // setCurrentCognipulseIndex(prev => {
          //     if(prev === historiclData.length-1){
          //         return historiclData.length-1;
          //     }
          //     return prev+1;
          // })
      }
    
      const handleKeyDown = (e:any) => {
          if(e.key === "ArrowLeft" && !PREV_DISABLED){
              handlePrev();
          }
          if(e.key === "ArrowRight" && !NEXT_DISABLED){
              handleNext();
          }
      }

      const handleChangeView = (e:any) => {
          let startDate = new Date();
          let endDate = dayjs(data[data.length-1].year).add(30,"minute").toDate();

          if(e.target.innerText.trim() === "Day"){
            const daysTasks = expliotationDuration.filter((ed:any) => dayjs(ed.startTime).isBetween(dayjs().startOf("day"),dayjs().endOf("day")));
            const firstExp = daysTasks[0];
            const lastExp = daysTasks[daysTasks.length-1];
            // const lastDayDPs = data.filter(dp => dayjs(dp.year).isBetween(dayjs(endDate).startOf("day"),dayjs(endDate).endOf("day")))          
            startDate = dayjs(firstExp.startTime).subtract(1,"minute").toDate();
            endDate = dayjs(lastExp.endTime).add(1,"minute").toDate();
          }
          else if(e.target.innerText.trim() === "Week") {
            startDate = dayjs(endDate).subtract(7, 'day').startOf("day").toDate();
          }
          else {
            startDate = dayjs(endDate).subtract(30, 'day').startOf("day").toDate();
          }

     

          chart.options.scales.x.min = startDate;
          chart.options.scales.x.max  = endDate;
          chart.options.plugins.annotation.annotations.line1.xMin = endDate;
          chart.options.plugins.annotation.annotations.line1.xMax = endDate;
          chart.update();
          const index = data.findIndex(item => new Date(item.year).getTime() === new Date(historiclData[historiclData.length-1].year).getTime());
        

          if(typeof(index) === "number"){
            if(!data[index].isEvent){
              setCurrentCognipulseIndex(index);
            }
            setCurrentIndex(index);
          }

          setBrushSelection({range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]})
      }


      return (
          <>
          <FullView setIsOpen={setIsOpen} title={`Your Cognitive Rhythms & Factors: ${firstLetterUpperCase(selectedActivity?.name)} ${firstLetterUpperCase(selectedActivity?.category)}`}>
              <div tabIndex={0} onKeyDown={handleKeyDown} className="w-full flex flex-col items-center">
                  <div className="w-full flex flex-col lg:flex-row">
                      <div className="p-2 w-full lg:w-[80%]">
                              <div className="w-full h-[280px] md:h-[400px]" ref={streamGraphDiv}>
                                  <StreamGraph
                                      streamGraphChartData={streamGraphChartData}
                                      events={events}
                                      setEventData={setEventData}
                                      setEventId={setEventId}
                                      setIsUpdateEvent={setIsUpdateEvent}
                                      setIsOpenAddEventsModal={setIsOpenAddEventsModal}
                                      chart={chart}
                                      setChart={setChart}
                                      setBrushSelection={setBrushSelection}
                                      setCurrentCognipulseIndex={setCurrentCognipulseIndex}
                                      setCurrentIndex={setCurrentIndex}
                                      data={data}
                                  />
                              </div>
                              <div className="mt-4">
                                  <div className="p-2 rounded-md shadow-sm bg-secondry-color/20 min-w-[100px] text-secondry-color">
                                      <div className="w-full">
                                          <div className="w-full mb-2 flex gap-2">
                                              <div className="">
                                                <SecondryButton onClick={()=>setIsOpenAddEventsModal(true)}>Add Psy-Selfie</SecondryButton>
                                              </div>
                                              <div className="">
                                                <SecondryButton onClick={handleChangeView}>Day</SecondryButton>
                                              </div>
                                              <div className="">
                                                <SecondryButton onClick={handleChangeView}>Week</SecondryButton>
                                              </div>
                                              <div className="">
                                                <SecondryButton onClick={handleChangeView}>Month</SecondryButton>
                                              </div>
                                              <div  className="flex-1">
                                                <PrimaryButton disabled={PREV_DISABLED} onClick={handlePrev}>Prev</PrimaryButton>
                                              </div>
                                              <div className="flex-1">
                                                <PrimaryButton disabled={NEXT_DISABLED} onClick={handleNext}>Next</PrimaryButton>
                                              </div>
                                          </div>
                                          <div className="w-full">
                                              <span className='font-bold'>Selected Range:</span>
                                              <span className='ml-[60px]'>{dayjs(brushSelection?.range[0]).format("DD/MM/YYYY")}</span>
                                              <span className='mx-1'>-</span>
                                              <span>{dayjs(brushSelection?.range[1]).format("DD/MM/YYYY")}</span>
                                          </div>
                                      </div>
                                      <div className="">
                                                          <span className='font-bold'>Average XLScore:</span>
                                                          <span className='ml-12'>{parseFloat(`${mapper__100_100_to_0_100(SUM_OF_XL_SCORES)}`).toFixed(2)}</span>
                                      </div>
                                      <div className="">
                                                          <span className='font-bold'>Highest freq Factor/s:</span>
                                                          <span className='ml-5'>{MOST_FREQUENT_FACTORS}</span>
                                      </div>
                                      <div className="pt-2">
                                                          <ToggleButton isChecked={isEventsChecked} setIsChecked={setIsEventsChecked}/>
                                                          <div className="flex flex-col gap-2">
                                                              {
                                                                  isEventsChecked && selectedEvents.map((event:any,idx:number) => (
                                                                      <div key={idx} className="border p-2 bg-primary-color rounded-md">
                                                                          <div className="mb-2">
                                                                            <span className="font-bold">Date: </span> 
                                                                            {dayjs(event.date).format("llll") || "Not Set"}
                                                                          </div>

                                                                          <div>
                                                                            <span className="font-bold">Desc: </span> 
                                                                            {event.description || "Not Set"}
                                                                          </div>

                                                                          <div className="my-4">
                                                                            <span className="font-bold">Tags: </span>
                                                                            {
                                                                            event.tags?.length === 0 && "Not Set"  
                                                                            }
                                                                            {
                                                                                event.tags.map((tag:any,idx1:number) => (
                                                                                    <span key={idx1} className="bg-primary-button-color text-white px-2 ml-2 rounded-md p-1">{tag}</span>
                                                                                ))
                                                                            }
                                                                          </div>

                                                                          <div>
                                                                            <span className="font-bold">Feelings: </span>
                                                                            {
                                                                                event.feelings.length === 0 && "Not Set"  
                                                                            }
                                                                            {
                                                                                event.feelings.map((emoji:any,idx1:number) => (
                                                                                    <span key={idx1} title={emoji} className="bg-primary-button-color text-white px-2 ml-2 rounded-md p-1">
                                                                                      {EMOJIES.find(em => em.name === emoji)?.emoji || emoji}
                                                                                    </span>
                                                                                ))
                                                                            }
                                                                          </div>
                                                                          
                                                                      </div>
                                                                  ))
                                                              }
                                                          </div>
                                      </div>
                                  </div>
                                  <div className="w-full mt-4">
                                      <div className="pb-1 flex justify-between items-center border-b border-secondry-color/10 mb-2">
                                          <h2 className='text-[11px] md:text-[16px] rounded font-bold text-secondry-color'>Your Personal Growth & Resilience: {firstLetterUpperCase(selectedActivity?.name)} {firstLetterUpperCase(selectedActivity?.category)} <InfoIconWithToolTip content={"This graph visualizes your psychological development as you adapt to events and challenges. It reflects the transformative journey of adapting, learning, and growing from each experience you encounter."}/></h2>
                                      </div>
                                      <PersonalGrowthGraph historicalOPQ={historicalOPQ}/>
                                  </div>
                              </div>
                      </div>
                      <div className="p-2 w-full lg:w-[20%] flex flex-col md:flex-row lg:flex-col">
                              <h1 className="text-center font-bold text-secondry-color">XL Score</h1>
                              <div ref={XLScoreContainer} style={{transform: "scale(1)"}} className="w-full h-[250px] mx-auto max-w-[250px] flex flex-col justify-center items-center">
                                  <ScoreInBrainWithLabels radius={71} currentTourStep={()=>{}} isBrainAnimate={false} setIsAnimationEnds={()=>{}} containerRef={XLScoreContainer} XLScore={CURRENT_FACTORS.XLScore} XLChange={CURRENT_FACTORS.XLChange}/>  
                              </div>
                              <h1 className="text-center font-bold text-secondry-color">Flow Score</h1>
                              <div ref={FlowScoreContainer} style={{transform: "scale(1)"}} className="w-full h-[270px] mx-auto max-w-[250px] flex flex-col justify-center items-center">
                                  <FlowScoreBrainWithLabels radius={71} currentTourStep={()=>{}} isBrainAnimate={false} setIsAnimationEnds={()=>{}} containerRef={FlowScoreContainer} XLScore={CURRENT_FACTORS._per_flowScore} XLChange={CURRENT_FACTORS.flowChange}/>  
                              </div>
                              {/* <div className="border px-4 flex items-center bg-gray-400 text-white"><h4 className="mb-0">OPQ: {parseFloat(currentOPQ).toFixed(2)}</h4></div> */}
                              <div className="flex mx-auto max-w-[300px] flex-col w-full p-2">
                                  <p className="title flex justify-center gap-2 items-center text-secondry-color" ><span>{IS_TODAY? "Current":dayjs(CURRENT_FACTORS.year).format("DD/MM/YYYY")} XL factors</span><InfoIconWithToolTip content={XLFactorsTooltipText}/></p>
                                  <Factors 
                                      Fun={CURRENT_FACTORS?._per_Fun}
                                      Purpose={CURRENT_FACTORS?._per_Purpose}
                                      Growth={CURRENT_FACTORS?._per_Growth}
                                      Emotives={Math.abs(CURRENT_FACTORS?._per_Emotives)}
                                      Utility={Math.abs(CURRENT_FACTORS?._per_Utility)}
                                      Apathy={Math.abs(CURRENT_FACTORS?._per_Apathy)}
                                      factorsMinMax={factorsMinMax}
                                      percentageOfChangeOfFactors={percentageOfChangeOfFactors}
                                  />
                              </div>
                      </div>
                      <EventModal
                          isOpen={isOpenAddEventsModal}
                          setIsOpen={setIsOpenAddEventsModal}
                          userId={loggedInUser._id}
                          isUpdate={isUpdateEvent}
                          data={eventData}
                          setEvents={setEvents}
                          eventId={eventId}
                          isManagemaneEvent={false}
                          surveyTitle={null}
                          setIsPhySelfieChanged={()=>{}}
                          surveyName={selectedActivity.category}
                      />
                  </div>
              </div>
          </FullView>
      </>
      )
  }

  
  function useFrequencyOfFactorsCalculator(selectedRanges:any){
      const FREQUENCY_OF_FACTORS:any = {}
      const [incDec] = useIsIncreaseOrDicrease();
    
      selectedRanges.forEach((item:any) => {
          if(FREQUENCY_OF_FACTORS[item.highestDiffrence.factor as keyof typeof FREQUENCY_OF_FACTORS]){
              FREQUENCY_OF_FACTORS[item.highestDiffrence.factor] = FREQUENCY_OF_FACTORS[item.highestDiffrence.factor as keyof typeof FREQUENCY_OF_FACTORS]+1;
          }
          else {
              FREQUENCY_OF_FACTORS[item.highestDiffrence.factor] = 1;
          }
      })
    
      let mostFrequent = undefined;
      const MOST_FREQUENT_WITH_SAME_WEATAGE = [];
    
      for (const key in FREQUENCY_OF_FACTORS) {
          if(!mostFrequent || mostFrequent.freq < FREQUENCY_OF_FACTORS[key]){
              mostFrequent = {factor: key, freq: FREQUENCY_OF_FACTORS[key]};
          }
          if(mostFrequent.freq < FREQUENCY_OF_FACTORS[key]){
              MOST_FREQUENT_WITH_SAME_WEATAGE.push({factor: key, freq: FREQUENCY_OF_FACTORS[key]});
          }
      }
    
      if(!mostFrequent){
          return `No Factors in this range`;
      }
    
      let finalString = `${incDec(mostFrequent.factor)} ${mostFrequent.factor}`;
    
      MOST_FREQUENT_WITH_SAME_WEATAGE.forEach(item => {;
          finalString += ` & ${incDec(item.factor)} ${item.factor}`;
      })
      
      return finalString;
  }
    
  function useIsIncreaseOrDicrease(){
      const  incDec = (factor:any) => {
          return ["Fun","Purpose","Growth"].indexOf(factor) === -1? "Decrease":"Increase"
      }
      
      return  [incDec];
  }

  function useEventsChangeEffect({events,setstreamGraphChartData}:any){

      useEffect(() => {
        if(events){
          setstreamGraphChartData((prev:any) => {
            const rem = prev.datasets.filter((dataSet:any) => dataSet.label !== "Events");
    
            return {
              ...prev,
              datasets: [
                {
                  type: "scatter",
                  label: "Events",
                  data: events.map((ev:any) => {return {x: new Date(ev.date),y:-85,description: ev.description,tags: ev.tags}}),
                  borderColor: "#db03fc",
                  backgroundColor: "#db03fc",
                  stack: 'combined',
                },
                ...rem
              ]
            }
          })
        }
      }, [events,setstreamGraphChartData]);
    
      return null;
  }
    
  function useHistoricalDataChangeEffect({historiclData,setstreamGraphChartData}:any){
    
    
      useEffect(() => {
        if(historiclData){
          setstreamGraphChartData((prev:any) => {
            
            const rem = prev.datasets.filter((dataSet:any) => [...XL_FACTORS,"XLScore","FlowScore"].indexOf(dataSet.label) === -1);
      
            return {
              ...prev,
              datasets: [
                ...rem,
                {
                  type: 'line',
                  label: "XLScore",
                  data: historiclData.map((item:any) => {return {x: new Date(item.year),y: mapper_0_100_to__100_100(item["XLScore"])}}),
                  borderColor: FACTOR_COLORS["XLSCORE"],
                  backgroundColor: FACTOR_COLORS["XLSCORE"],
                  pointRadius: 0,
                  fill: false,
                  stack: "1",
                  tension: .4
                },
                {
                  type: 'line',
                  label: "FlowScore",
                  data: historiclData.map((item:any) => {return {x: new Date(item.year),y: item["flowScore"]}}),
                  borderColor: "#008080",
                  backgroundColor: "#008080",
                  pointRadius: 0,
                  fill: false,
                  stack: "2",
                  tension: .4
                },
                ...XL_FACTORS.slice(0,6).map(factor => {
                    return {
                      type: 'line',
                      label: factor,
                      data: historiclData.map((item:any) => {return {x: new Date(item.year),y: item[factor]}}),
                      borderColor: FACTOR_COLORS[factor.toLocaleUpperCase() as keyof typeof FACTOR_COLORS],
                      backgroundColor: FACTOR_COLORS[factor.toLocaleUpperCase() as keyof typeof FACTOR_COLORS],
                      fill: true,
                      pointRadius: 0,
                      tension: .4,
                      hidden: true
                    }
                })
              ]
            }
          });
        }
      }, [historiclData,setstreamGraphChartData]);
    
      return null;
  }

  interface IStreamGraph {
    setCurrentCognipulseIndex: any;
    setBrushSelection: any;
    chart: any;
    setChart: any;
    streamGraphChartData: any;
    events: any;
    setEventData: any;
    setEventId: any;
    setIsUpdateEvent: any;
    setIsOpenAddEventsModal: any;
    setCurrentIndex: any;
    data: any;
  }

  const StreamGraph: React.FC<IStreamGraph> = ({data,setCurrentIndex,setCurrentCognipulseIndex,setBrushSelection,chart,setChart,streamGraphChartData,events,setEventData,setEventId,setIsUpdateEvent,setIsOpenAddEventsModal}) => {
      const chartRef = useRef<any>(null);


      function handeDoubleClick(){
          const startDate = streamGraphChartData.labels[0];
          const endDate = streamGraphChartData.labels[streamGraphChartData.labels.length-1];
          chart.options.scales.x.min = new Date(startDate);
          chart.options.scales.x.max  = new Date(endDate);
          chart.update();
          chart.resetZoom();
      }

      function handleOnClick(e:any){
          const points = chart.getElementsAtEventForMode(e,'nearest',{intersect: true},true);
          const data = points[0];
          if(!data){
              return;
          }
          // if user click on event circle 
          if(data.datasetIndex === 0){
              const {index} = data;
              const event = events[index];
              setEventData(event);
              setEventId(event._id);
              setIsUpdateEvent(true);
              setIsOpenAddEventsModal(true);
              return;
          }
      }

      useEffect(() => {
          let chart = undefined;
          if(streamGraphChartData){
              
              const getOrCreateTooltip = (chart:any) => {
                  let tooltipEl = chart.canvas.parentNode.querySelector('div');
                
                  if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
                    tooltipEl.style.borderRadius = '3px';
                    tooltipEl.style.color = 'white';
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.pointerEvents = 'none';
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.transform = 'translate(-50%, 0)';
                    tooltipEl.style.transition = 'all .1s ease';
                
                    const table = document.createElement('table');
                    table.style.margin = '0px';
                
                    tooltipEl.appendChild(table);
                    chart.canvas.parentNode.appendChild(tooltipEl);
                  }
                
                  return tooltipEl;
              };
                
              const externalTooltipHandler = (context:any) => {
                  // Tooltip Element
                  const {chart, tooltip} = context;
                  const tooltipEl = getOrCreateTooltip(chart);
                
                  // Hide if no tooltip
                  if (tooltip.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                  }
                
                  // Set Text
                  if (tooltip.body) {
                    const titleLines = tooltip.title || [];
                    const bodyLines = tooltip.body.map((b:any) => b.lines);
                
                    const tableHead = document.createElement('thead');
                
                    titleLines.forEach((title:any,i:any) => {
                      const tr: HTMLTableRowElement = document.createElement('tr');
                      tr.style.borderWidth = '0';
                
                      const th: HTMLTableHeaderCellElement = document.createElement('th');
                      th.style.borderWidth = "0";
                      const text = document.createTextNode(dayjs(tooltip.dataPoints[i].raw.x).format("ll"));
    
                      th.appendChild(text);
                      tr.appendChild(th);
                      tableHead.appendChild(tr);
                    });
                
                    const tableBody = document.createElement('tbody');
                    bodyLines.forEach((body:any, i:any) => {
                      const colors = tooltip.labelColors[i];
                
                      const span = document.createElement('span');
                      span.style.background = colors.backgroundColor;
                      span.style.borderColor = colors.borderColor;
                      span.style.borderWidth = '2px';
                      span.style.marginRight = '10px';
                      span.style.height = '10px';
                      span.style.width = '10px';
                      span.style.display = 'inline-block';
                
                      const tr: HTMLTableRowElement = document.createElement('tr');
                      tr.style.backgroundColor = 'inherit';
                      tr.style.borderWidth = '0';
                
                      const td: HTMLTableDataCellElement = document.createElement('td');
                      td.style.borderWidth = '0';
                
                      if(tooltip.dataPoints[i].dataset.label === "Events"){
                        const rawData = tooltip.dataPoints[i].raw;
                      //   console.log(rawData);
                        const text = document.createTextNode(rawData.description);
                        td.appendChild(span);
                        td.appendChild(text);
                        rawData.tags.forEach((tag:any) => {
                          const button = document.createElement("button");
                          button.innerText = tag;
                          button.style.backgroundColor = SECONDRY_BUTTON_COLOR;
                          button.style.marginLeft = "2px";
                          button.style.marginBottom = "2px";
                          button.style.padding = "0px 10px";
                          button.style.color = "#ffffff";
                          button.style.borderRadius = "5px"
                          td.appendChild(button)
                        });
                        tr.appendChild(td);
                        tableBody.appendChild(tr);
                      }
                      else if(tooltip.dataPoints[i].dataset.label === "Action Challenges"){
                        const rawData = tooltip.dataPoints[i].raw;
                        const text = document.createTextNode(rawData.actionChallenge);
                        td.appendChild(span);
                        td.appendChild(text);
                        rawData.steps.forEach((step:any) => {
                          const button = document.createElement("button");
                          button.innerText = step.step;
                          button.style.backgroundColor = SECONDRY_BUTTON_COLOR;
                          button.style.marginLeft = "2px";
                          button.style.marginBottom = "2px";
                          button.style.padding = "0px 10px";
                          button.style.color = "#ffffff";
                          button.style.borderRadius = "5px"
                          td.appendChild(button)
                        });
                        tr.appendChild(td);
                        tableBody.appendChild(tr);
                      }
                      else {
                        const text = document.createTextNode(body);
                
                        td.appendChild(span);
                        td.appendChild(text);
                        tr.appendChild(td);
                        tableBody.appendChild(tr);
                      }
                    });
                
                    const tableRoot = tooltipEl.querySelector('table');
                
                    // Remove old children
                    while (tableRoot.firstChild) {
                      tableRoot.firstChild.remove();
                    }
                
                    // Add new children
                    tableRoot.appendChild(tableHead);
                    tableRoot.appendChild(tableBody);
                  }
                
                  const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
                
                  // Display, position, and set styles for font
                  tooltipEl.style.zIndex = 2;
                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.background = PRIMARY_COLOR;
                  tooltipEl.style.color = SECONDRY_COLOR;
                  tooltipEl.style.border = `1px solid ${SECONDRY_COLOR}`;
                  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
                  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
                  tooltipEl.style.font = tooltip.options.bodyFont.string;
                  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
              };

              chart = new ChartJS(chartRef.current, {
                  type: 'bar',
                  data: streamGraphChartData,
                  options: {
                      scales: {
                      x: {
                          type: 'time',
                          // time: {
                          //   unit: "day", // or 'hour' depending on your needs
                          // },
                          ticks: {
                              autoSkip: true,
                              // autoSkipPadding: 50,
                              // maxRotation: 0,
                              callback: function(value) {
                                // This will format the tick labels as date and time
                                return dayjs(value).format('MMM D, HH:mm a'); // Example: "Nov 18, 15:00"
                              }
                          }
                      },
                      y: {
                          stacked: true,
                          ticks: {
                              callback: function(ctx,i){
                                  return i*10;
                              }
                          },
                          min: -100,
                          max: 100,
                      }
                      },
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                          tooltip: {
                              enabled: false,
                              position: 'nearest',
                              external: externalTooltipHandler
                          },
                          zoom: { 
                              limits: {
                                  y: {min: -100,max: 100,minRange: 0}
                              },
                              pan: {
                                  enabled: true,
                                  modifierKey: 'ctrl',
                                  mode: 'x',
                                  onPanComplete: function(e:any){
                                      const chart = e.chart;
                                      const selection = {
                                          range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
                                      };
                                      if(chart.getZoomLevel() === 1){
                                          chart.update();
                                          setBrushSelection(selection);
                                          return;
                                      }
                                      const startTick = chart.scales.x.ticks[0];
                                      const endTick = chart.scales.x.ticks[chart.scales.x.ticks.length-1];
                                      const majorTicks = chart.data.labels.map((item:any,index:number) => {return {year: item,index}}).filter((item:any) => dayjs(item.year).isBetween(dayjs(startTick?.value),dayjs(endTick?.value)));
                                      const highlightedTick = majorTicks[Math.round((majorTicks.length - 1) / 2)];
      

                                      if(highlightedTick){
                                          chart.options.plugins.annotation.annotations.line1.xMin = new Date(highlightedTick.year);
                                          chart.options.plugins.annotation.annotations.line1.xMax = new Date(highlightedTick.year);
                                          chart.update();
                                          const index = data.findIndex((item:any) => new Date(item.year).getTime() === new Date(highlightedTick.year).getTime());
                                          if(!data[index]?.isEvent){
                                            setCurrentCognipulseIndex(index);
                                          }
                                          setCurrentIndex(index);
                                      }
                                      setBrushSelection(selection);
                                  }
                              },
                              zoom: {
                                  drag: {
                                      enabled: true,
                                      backgroundColor: "#00000055"
                                  },
                                  pinch: {
                                      enabled: true
                                  },
                                  mode: 'x',
                                  onZoomComplete: function(e:any){
                                      // console.log("aa");
                                      
                                      const chart = e.chart;
                                      const selection = {
                                          range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
                                      };
                                      if(chart.getZoomLevel() === 1){
                                          chart.update();
                                          setBrushSelection(selection);
                                          return;
                                      }
                                      const startTick = chart.scales.x.ticks[0];
                                      const endTick = chart.scales.x.ticks[chart.scales.x.ticks.length-1];
                                      const majorTicks = chart.data.labels.map((item:any,index:number) => {return {year: item,index}}).filter((item:any) => dayjs(item.year).isBetween(dayjs(startTick?.value),dayjs(endTick?.value)));
                                      const highlightedTick = majorTicks[Math.round((majorTicks.length - 1) / 2)];
      

                                      if(highlightedTick){
                                          chart.options.plugins.annotation.annotations.line1.xMin = new Date(highlightedTick.year);
                                          chart.options.plugins.annotation.annotations.line1.xMax = new Date(highlightedTick.year);
                                          chart.update();
                                          const index = data.findIndex((item:any) => new Date(item.year).getTime() === new Date(highlightedTick.year).getTime());
                                          if(!data[index]?.isEvent){
                                            setCurrentCognipulseIndex(index);
                                          }
                                          setCurrentIndex(index);
                                      }
                                      setBrushSelection(selection);
                                  }
                              }
                          },
                          legend: {
                            labels: {
                                boxWidth: 10,
                                boxHeight: 10
                            },
                          },
                          annotation: {
                              annotations: {
                                  line1: {
                                      type: 'line',
                                      yMin: -100,
                                      yMax: 100,
                                      xMin: streamGraphChartData.labels[streamGraphChartData.labels.length-1],
                                      xMax: streamGraphChartData.labels[streamGraphChartData.labels.length-1],
                                      borderColor: '#000000',
                                      borderWidth: 2,
                                  }
                              }
                          }
                      }
                  },
                  plugins: [
                    {
                      id: "horizontalLine",
                      beforeDraw(chart:any,args:any,option:any){
                        const {ctx,scales} = chart;
                        const {x,y} = scales;

                        // Store tooltip positions in a global or plugin-specific variable
                        chart.horizontalBarTooltips = [];
      
                        chart.getDatasetMeta(6).data.forEach((d:any) => {
                            ctx.save();

                           // Extract start and end dates
                            const startDate = d.$context.raw.x[0];
                            const endDate = d.$context.raw.x[1];
                            const objective = d.$context.raw.objective;

                            // Clamp start and end dates to the visible range of the x-axis
                            const clampedStartDate = Math.max(startDate, x.min);
                            const clampedEndDate = Math.min(endDate, x.max);

                            // Check if the clamped dates are still within the visible range
                            if (clampedStartDate <= clampedEndDate) {
                                // Calculate pixel positions for the clamped dates
                                const x0 = x.getPixelForValue(clampedStartDate);
                                const x1 = x.getPixelForValue(clampedEndDate);

                                // Calculate y-position and height
                                const y0 = y.getPixelForValue(-100);
                                const height = 5; // Height of the horizontal line

                                // Draw the horizontal line
                                // ctx.strokeStyle = "green";
                                ctx.fillStyle = "green";
                                ctx.lineWidth = 1;
                                ctx.beginPath();
                                ctx.rect(x0, y0, x1 - x0, height); // Use x0 and x1 for the visible range
                                // ctx.stroke();
                                ctx.fill();

                               // Store the tooltip data for this bar
                                chart.horizontalBarTooltips.push({
                                  x0,
                                  x1,
                                  y0,
                                  y1: y0 + height,
                                  tooltip: `${objective} ${dayjs(endDate).diff(dayjs(startDate),"minute")} Mins`, // Custom tooltip content
                                });
                            }
                            ctx.restore();
                        });
                      },
                      afterEvent(chart: any, args: any, options: any) {
                        const { x, y, type } = args.event;

                        // Handle mousemove events only
                        if (type === "mousemove") {
                          const hoveredBar = chart.horizontalBarTooltips.find((bar: any) => {
                            return x >= bar.x0 && x <= bar.x1 && y >= bar.y0 && y <= bar.y1;
                          });

                          // Clear the canvas overlay before drawing the tooltip
                          chart.tooltipOverlay = chart.tooltipOverlay || document.createElement("canvas");
                          const chartRect = chart.canvas.getBoundingClientRect();
                          
                          const overlay = chart.tooltipOverlay;
                          if (!overlay.isConnected) {
                            overlay.style.position = "absolute";
                            overlay.style.left = `${chartRect.left}px`;
                            overlay.style.top = `${chartRect.top}px`;
                            overlay.style.pointerEvents = "none";
                            overlay.width = chart.width;
                            overlay.height = chart.height;
                            chart.canvas.parentElement.appendChild(overlay);
                          }

                          

                          const overlayCtx = overlay.getContext("2d")!;
                          overlayCtx.clearRect(0, 0, overlay.width, overlay.height);

                          if (hoveredBar) {
                            const tooltipX = (hoveredBar.x0 + hoveredBar.x1) / 2;
                            let tooltipY = hoveredBar.y0 - 10;
                          
                            overlayCtx.save();
                            overlayCtx.font = "12px Arial";
                            overlayCtx.textAlign = "center";
                          
                            // Tooltip text
                            const text = hoveredBar.tooltip;
                            const maxWidth = 150; // Maximum width of the tooltip
                            const padding = 10;
                            const lineHeight = 16; // Height of each line of text
                          
                            // Split text into lines that fit within maxWidth
                            const lines: string[] = [];
                            let currentLine = '';
                            text.split(' ').forEach((word:any) => {
                              const testLine = currentLine ? `${currentLine} ${word}` : word;
                              const testWidth = overlayCtx.measureText(testLine).width;
                              if (testWidth > maxWidth) {
                                lines.push(currentLine);
                                currentLine = word;
                              } else {
                                currentLine = testLine;
                              }
                            });
                            if (currentLine) {
                              lines.push(currentLine);
                            }
                          
                            const tooltipHeight = lines.length * lineHeight + padding * 2;
                            const tooltipWidth = maxWidth + padding * 2;
                          
                            // Adjust position if tooltip overflows horizontally
                            const overlayWidth = chart.width;
                          
                            let adjustedTooltipX = tooltipX;
                            if (tooltipX - tooltipWidth / 2 < 0) {
                              adjustedTooltipX = tooltipWidth / 2; // Align to the left edge
                            } else if (tooltipX + tooltipWidth / 2 > overlayWidth) {
                              adjustedTooltipX = overlayWidth - tooltipWidth / 2; // Align to the right edge
                            }
                          
                            // Adjust position if tooltip overflows vertically
                            if (tooltipY - tooltipHeight < 0) {
                              tooltipY = hoveredBar.y0 + tooltipHeight + 10; // Place below the bar if too close to the top
                            }
                          
                            // Tooltip background
                            overlayCtx.fillStyle = "rgba(255, 255, 255, 0.9)";
                            overlayCtx.fillRect(
                              adjustedTooltipX - tooltipWidth / 2,
                              tooltipY - tooltipHeight,
                              tooltipWidth,
                              tooltipHeight
                            );
                          
                            // Tooltip border
                            overlayCtx.strokeStyle = "black";
                            overlayCtx.strokeRect(
                              adjustedTooltipX - tooltipWidth / 2,
                              tooltipY - tooltipHeight,
                              tooltipWidth,
                              tooltipHeight
                            );
                          
                            // Render each line of text
                            overlayCtx.fillStyle = "black";
                            lines.forEach((line, index) => {
                              overlayCtx.fillText(
                                line,
                                adjustedTooltipX,
                                tooltipY - tooltipHeight + padding + lineHeight * (index + 1)
                              );
                            });
                          
                            overlayCtx.restore();
                          }
                        }
                      },
                    }
                  ]
              });
              setChart(chart);
          }
          return () => {
              if(chart){
                  chart.destroy();
              }
          }
      }, [setCurrentIndex,streamGraphChartData,setEventData,setEventId,setIsUpdateEvent,setIsOpenAddEventsModal,events,setChart,setBrushSelection,setCurrentCognipulseIndex]);


      if(!streamGraphChartData){
          return null;
      }


      return <canvas ref={chartRef} onDoubleClick={handeDoubleClick} onClick={handleOnClick}></canvas>
  }

  interface IToggleButton {
    isChecked: any;
    setIsChecked: any;
  }

  const ToggleButton: React.FC<IToggleButton> = ({isChecked,setIsChecked}) => {

    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
    }

    return (
      <>
        <label className='autoSaverSwitch relative inline-flex cursor-pointer select-none items-center mb-6'>
          <input
            type='checkbox'
            name='autoSaver'
            className='sr-only'
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span
            className={`slider mr-3 flex h-[26px] w-[50px] items-center rounded-full p-1 duration-200 ${
              isChecked ? 'bg-secondry-button-color' : 'bg-primary-color'
            }`}
          >
            <span
              className={`dot h-[18px] w-[18px] rounded-full bg-white duration-200 ${
                isChecked ? 'translate-x-6' : ''
              }`}
            ></span>
          </span>
          <span className='label flex items-center text-sm font-medium'>
          <span className='pl-1 text-[18px]'> {isChecked ? 'Hide' : 'Show'} Event Details:</span>
          </span>
        </label>
      </>
    )
  }

  interface IPersonalGrowthGraph {
    historicalOPQ: any;
  }

  export const PersonalGrowthGraph: React.FC<IPersonalGrowthGraph> = ({historicalOPQ}) => {
      const arr:any[] = [];
      historicalOPQ.forEach((item:any,idx:number) => {
          const prevSum = historicalOPQ.filter((it:any,id:any) => id < idx).reduce((a:any,i:any) => a+i.OPQ,0);
          if(idx === 0){
            arr.push({compountedValue: item.OPQ, date: item.date})
          }
          else {
            arr.push({compountedValue: item.OPQ+prevSum,date: item.date});
          }
      });
      const myData = {
              labels: arr.map(item => new Date(item.date)),
              datasets: [
                  {
                    label: "Personal Growth (Compounded) Graph",
                    data: arr.map(item => item.compountedValue),
                    backgroundColor: '#0a8001',
                    pointRadius: 0,
                    borderColor: "#0a8001",
                    clip: {left: 0, top: 0, right: 0, bottom: 0}
                  }
              ]
      };


      return <>
            {/* <div className="mt-5 mb-3 text-11 md:text-[14px] flex flex-col w-full">
                <div className="pb-1 flex justify-between items-center border-b border-secondry-color/10 mb-2">
                    <h2 className='text-[11px] md:text-[16px] rounded font-bold text-secondry-color'>Your Personal Growth <InfoIconWithToolTip content={"This graph visualizes your psychological development as you adapt to events and challenges. It reflects the transformative journey of adapting, learning, and growing from each experience you encounter."}/></h2>
                </div>
                <Line 
                  data={myData}
                  options={{
                  scales: {
                      x: {
                        type: 'timeseries',
                        ticks: {
                            callback: function(value, index, ticks){
                                // if(index === 0){
                                //     return "Today"
                                // }
                                return this.getLabelForValue(value).split(",")[0]
                            },
                            color: "black"
                        },
                        grid: {
                          display: false
                        },
                        border: {
                          color: "black"
                        },
                      },
                      y: {
                        // max: 100,
                        // min: 0,
                        ticks: {
                            callback: function(value,index,ticks){
                                return `${value} %`;
                            },
                            color: "black"
                        },
                        title: {
                                                    display: false,
                                                    text: "Personal Growth (Compounded)",
                                                    font: {
                                                        family: 'sans-serif',
                                                        size: 18,
                                                        style: 'normal',
                                                        lineHeight: 1.2
                                                    },
                        },
                        grid: {
                          display: false
                        },
                        border: {
                          color: "black"
                        }
                      }
                  },
                  plugins: {
                      legend: {
                          position: "top",
                          align: "center",
                          display: false,
                          labels: {
                              font: {
                                  size: 11
                              },
                              boxWidth: 11
                          }
                      },
                      labels: {
                          color: "black",
                          font: {
                            family: 'sans-serif',
                            size: 10,
                            weight: "normal"
                          },
                      }
                  },
                  }}
                />
            </div> */}
            <Line 
              data={myData}
                  options={{
                  scales: {
                      x: {
                        type: 'timeseries',
                        ticks: {
                            callback: function(value: any, index, ticks){
                                // if(index === 0){
                                //     return "Today"
                                // }
                                return this.getLabelForValue(value).split(",")[0]
                            },
                            color: "black"
                        },
                        grid: {
                          display: false
                        },
                        border: {
                          color: "black"
                        },
                      },
                      y: {
                        // max: 100,
                        // min: 0,
                        ticks: {
                            callback: function(value,index,ticks){
                                return `${value} %`;
                            },
                            color: "black"
                        },
                        title: {
                                                    display: false,
                                                    text: "Personal Growth (Compounded)",
                                                    font: {
                                                        family: 'sans-serif',
                                                        size: 18,
                                                        style: 'normal',
                                                        lineHeight: 1.2
                                                    },
                        },
                        grid: {
                          display: false
                        },
                        border: {
                          color: "black"
                        }
                      }
                  },
                  plugins: {
                      legend: {
                          position: "top",
                          align: "center",
                          display: false,
                          labels: {
                              font: {
                                  size: 11
                              },
                              boxWidth: 11
                          }
                      },
                      // labels: {
                      //     color: "black",
                      //     font: {
                      //       family: 'sans-serif',
                      //       size: 10,
                      //       weight: "normal"
                      //     },
                      // }
                  },
              }}
            />
          </>
  }


