
interface IMain {
    children: JSX.Element[] | JSX.Element | any;
    style?: React.CSSProperties;
    fullWidth?: boolean; 
}

export const Main: React.FC<IMain> = ({children,style={},fullWidth}) => {


    return <main style={style} className={`bg-primary-color shadow-2xl mx-auto ${fullWidth? "":"max-w-7xl"} min-h-[calc(100vh-121px)] px-2 sm:px-6 lg:px-8`}>
              {children}
           </main>
}
