import { useEffect } from 'react';
import { useState } from 'react';
import { RectTooltip } from "../../../components";
import { motion} from "framer-motion";
import { arc } from 'd3-shape';
import {SECONDRY_COLOR, PRIMARY_COLOR} from "../../../constants";
import { ArrowDownRed, ArrowUpGreen } from '../../../components/Icons';
const tooltipId = "tooltips-inside-score-in-brain";
export const XLScoreInBraintoolTipText = `A high score means you're more able to access your potential for adaptive performance. A low score means your potential is more likely to be limited to tactical performance.`; 




interface IScoreInBrainWithLabels {
  containerRef: any;
  XLScore: any;
  XLChange: any;
  setIsAnimationEnds: any;
  isBrainAnimate: any;
  currentTourStep: any;
  radius?: number;
}

const FlowScoreBrainWithLabels: React.FC<IScoreInBrainWithLabels> = ({radius,containerRef,XLScore,XLChange,setIsAnimationEnds,isBrainAnimate,currentTourStep}) =>  {
    const [width, setWidth] = useState(715);

    useEffect(() => {
      if(containerRef){
        setWidth(containerRef.current.clientWidth);
      }
    }, [containerRef]);


    return <>
        <ProgressBar radius={radius} currentTourStep={currentTourStep} isBrainAnimate={isBrainAnimate} setIsAnimationEnds={setIsAnimationEnds} width={width} progress={XLScore} change={parseFloat(XLChange)}/>
    </> 
}

export {FlowScoreBrainWithLabels};


interface IProgressBar {
  width: any;
  progress: any;
  change: any;
  setIsAnimationEnds: any;
  isBrainAnimate: any;
  currentTourStep: any;
  radius: any;
}

const ProgressBar: React.FC<IProgressBar> = ({radius,width,progress,change,setIsAnimationEnds,isBrainAnimate,currentTourStep}) =>  {
    width = width === 715? 270:((57/100)*width);
    const [currentProgress,setCurrentProgress]= useState(0);
    const [animationDone,setAnimationDone]= useState(false);
    const [isLabelAnimationDone,setIsLabelAnimationDone]= useState(false);
    const height = width;
    const strokeWidth = 19.2;
    const r = radius || 116;
    const strokeDasharray = 2 * 3.14 * r;
    const strokeDashoffset = strokeDasharray * ((100 - currentProgress)/100);
    const gap = 40;
    const labelsAnimationDuration = .2;
    const SVGWidth = r*3;
    const SVGHeight = r*3;
    const left = SVGWidth/2;
    const top = SVGHeight/2;     

    
    useEffect(() => {
        if(!isBrainAnimate){
          setAnimationDone(true);
          setIsAnimationEnds(true);
          setCurrentProgress(progress);
        }
        if(isLabelAnimationDone && isBrainAnimate){
          let time1:any = undefined;
          const timing = setInterval(() => {
            setCurrentProgress(p => {
                // if((p < (progress/4) || p > (progress-(progress/4))) && p <= progress){
                //   return p+.1;
                // }
                if(p <= progress){
                  // const t = easeOutBounce((p+.5)/progress)*progress;
                  return p+.5;
                }
                
                clearInterval(timing);
                setAnimationDone(true);
                time1 = setTimeout(() => {
                  setIsAnimationEnds(true);
                }, 2000);

                return progress;
            })
          }, 5);

          return () => {
              clearInterval(timing);
              clearInterval(time1);
          };
        }
    }, [progress,isLabelAnimationDone,setIsAnimationEnds,isBrainAnimate]);
    

    useEffect(() => {
      const tim = setTimeout(() => {
        setIsLabelAnimationDone(true);
      }, isBrainAnimate? ((((labelsAnimationDuration*1000)*4)*3)):0);

      return () => {
        clearTimeout(tim);
      };
    }, [isBrainAnimate]);


    
    return (<>
        <svg 
        id='introSelector5' 
        style={{overflow: "visible"}}   
        width={SVGWidth} 
        height={SVGHeight} 
        viewBox={`0 0 ${SVGWidth} ${SVGHeight}`}>
          
          <defs>
            <mask id='flowScoreMask'>
              {/* Add mask content here */}
              <circle r={r} cx={left} cy={top} fill="transparent" stroke={`white`} strokeWidth={strokeWidth} strokeDasharray={strokeDasharray+1} strokeDashoffset={strokeDashoffset+2}/>
            </mask>

            <filter id='flowScoreShadow' colorInterpolationFilters="sRGB">
              <feDropShadow dx="2" dy="2" stdDeviation="3" floodOpacity="0.5"/>
            </filter>
          </defs>

          <circle filter="url(#flowScoreShadow)" r={r} cx={left} cy={top} fill="transparent" stroke={PRIMARY_COLOR} strokeWidth={strokeWidth}/>
          
         
          {/* apply gradient  */}
          <foreignObject x={0} y={0} width={SVGWidth} height={SVGHeight} mask='url(#flowScoreMask)'>
              <div 
                style={{
                  width: `100%`,
                  height: `100%`,
                  background: `conic-gradient(from 90deg, #008080 0%, #a2fdfd 100%)`
                }}
              />
          </foreignObject>
          
          <Quadrants radius={r+(strokeWidth/2)} SVGWidth={SVGWidth} SVGHeight={SVGHeight} isBrainAnimate={isBrainAnimate} labelsAnimationDuration={labelsAnimationDuration}/>

          {/* only for dashboard tutorial  */}
          {
            (currentTourStep === 1 || currentTourStep === 2 || currentTourStep === 3) && <>
            <circle r={r-(strokeWidth/2)} cx={left} cy={top} fill="black" style={{opacity: .4}} stroke="transparent" strokeWidth={strokeWidth}/>
            {/* <circle r={r+strokeWidth} cx={left} cy={top} fill="transparent" style={{opacity: .6}} stroke="blue" strokeWidth={strokeWidth+100}/> */}
            </>
          }
          <circle id='introSelector2' r={r+7} cx={left} cy={top} fill="transparent" stroke="transparent" strokeWidth={strokeWidth}/>
          {/* top half  */}
          <rect id='introSelector4' fill='transparent' width={width-strokeWidth} height={(height-strokeWidth)/2} x={left-((width-strokeWidth)/2)} y={top-((height-strokeWidth)/2)}/>
          {/* bottom half  */}
          <rect id='introSelector3' fill='transparent' width={width-strokeWidth} height={(height-strokeWidth)/2} x={left-((width-strokeWidth)/2)} y={top}/>
        
          <foreignObject style={{overflow: "visible",display: (currentTourStep === 1 || currentTourStep === 2 || currentTourStep === 3)? "none":""}} width={r*2-gap} height={r*2-gap} x={left-((r*2-gap)/2)} y={top-((r*2-gap)/2)}>
            <XLScore r={r}  progress={currentProgress} change={change} animationDone={animationDone} isLabelAnimationDone={isLabelAnimationDone}/>
          </foreignObject>

        </svg>
        <RectTooltip id={tooltipId}/>
    </>)
}

interface IXLScore {
  progress: any;
  change: any;
  animationDone: any;
  isLabelAnimationDone: any;
  r: any;
}

const XLScore: React.FC<IXLScore> = ({progress,change,isLabelAnimationDone,r}) => {
    const brainColor = getCurrentColorForFlowScore(progress); 


    
    return (
        <>
          <div className="relative flex justify-center items-center w-full h-full z-20">                
                <div id='introSelector1' className='myBox w-[80%] h-[80%] shadow-[0px_6px_12px_-3px_rgba(0,0,0,0.3)] absolute p-1 rounded-full flex flex-col items-center justify-center'>
                     {
                        isLabelAnimationDone && <>
                                <div>
                                    <div style={{color: brainColor,fontSize: `${(r/100)*50}px`, lineHeight: `${(r/100)*50}px`}} className='text-secondry-color/60 text-center font-bold flex justify-center items-center'>
                                      <span id='dashboard-step-1' className='p-1' >{parseFloat(progress).toFixed(0)}</span>
                                    </div>
                                </div>

                                {/* change  */}
                                <div 
                                  style={{fontSize: `${(r/100)*14}px`,lineHeight: `${(r/100)*14}px`}} 
                                  className={`${change < 0 ? 'text-red-600':'text-green-600'} flex justify-center items-start gap-1`}
                                >
                                          <div style={{height: `${(r/100)*13}px`}} className="flex items-center items-center gap-1">
                                            {parseFloat(change).toFixed(0)}%
                                            {
                                                change < 0 ? <ArrowDownRed className={"h-full"}/>:<ArrowUpGreen className={"h-full"}/>
                                            }
                                          </div>
                                </div>
                        </>
                     } 
                </div> 
          </div>
        </>
    )
}



interface IQuadrants {
  radius: any;
  SVGHeight: any;
  SVGWidth: any;
  isBrainAnimate: any;
  labelsAnimationDuration: any;
}

const Quadrants: React.FC<IQuadrants> = ({radius,SVGHeight,SVGWidth,isBrainAnimate,labelsAnimationDuration}) => {

  const quard = [
      {
        title: "Low Flow",
        desc: "Minimal engagement, limited performance",
        fill: '#008080'
      },
      {
          title: "Below Average Flow",
          desc: "Some engagement, below optimal performance",
          fill: '#007e8c'
      },
      {
          title: "Moderate Flow",
          desc: "Steady engagement, moderate performance",
          fill: '#439fa3'
      },
      {
          title: "High Flow",
          desc: "High engagement, effective performancel",
          fill: '#78c0ba'
      },
      {
          title: "Optimal Flow",
          desc: "Peak engagement, maximised performance",
          fill: '#a2fdfd'
      }
  ]
  
  const arcGenerator:any = arc().innerRadius(radius+10).outerRadius(radius+15);

  const textArcGenerator:any = arc().innerRadius(radius+30).outerRadius(radius+30);

  const oppositeTextArcGenerator:any = arc().innerRadius(radius+42).outerRadius(radius+42);


  

  return (
    <>
      <g transform={`translate(${SVGWidth/2},${SVGHeight/2})`} >
          {
            quard.map((quadrant,idx) => {
              const dur = isBrainAnimate? labelsAnimationDuration:0;
              const parentDur = dur*3;
              const delay = idx*parentDur;
              const quadrantAngle = (Math.PI*2)/5;
              const startFrom = Math.PI/2;
              const gapBetweeenSections = .05;
              const startAngle = ((quadrantAngle*idx)+startFrom)+gapBetweeenSections;
              const endAngle = ((quadrantAngle*(idx+1))+startFrom)-gapBetweeenSections;
              const isOpposite = (idx === 0 || idx === 1)? true:false;
              
              return <g key={idx}>

                  <motion.path filter="url(#shadow)"  transition={{duration: dur,delay: delay+0}} initial={{opacity: 0}} animate={{opacity: 1}} fill={quadrant.fill} d={arcGenerator({startAngle,endAngle})}/>
              
                  <path fill="black" d={!isOpposite? oppositeTextArcGenerator({startAngle,endAngle}):textArcGenerator({startAngle,endAngle})}></path>
 
                  <path id={`${idx}`} fill="black" d={isOpposite? oppositeTextArcGenerator({startAngle,endAngle}):textArcGenerator({startAngle,endAngle})}/>
                  <text fill={SECONDRY_COLOR}>
                    <textPath  href={`#${idx}`} className='text-sm' textAnchor='middle' startOffset={isOpposite? "75%":"25%"}>
                        {quadrant.title}
                    </textPath>
                  </text>
              </g>
            })
          }
      </g>
    </>
  )
}

export function getCurrentColorForFlowScore(value:any) {
  if (value < 0 || value > 100) {
    throw new Error('Value must be between 0 and 100');
  }

  const getShade = (startColor:any, endColor:any, ratio:any) => {
    const hex = (start:any, end:any, ratio:any) => Math.round(start + ratio * (end - start)).toString(16).padStart(2, '0');
    const r = hex(parseInt(startColor.slice(1, 3), 16), parseInt(endColor.slice(1, 3), 16), ratio);
    const g = hex(parseInt(startColor.slice(3, 5), 16), parseInt(endColor.slice(3, 5), 16), ratio);
    const b = hex(parseInt(startColor.slice(5, 7), 16), parseInt(endColor.slice(5, 7), 16), ratio);
    return `#${r}${g}${b}`;
  };

  const colorRanges = [
    { min: 0, max: 20, startColor: '#008080', endColor: '#008080' }, // Dark red to red
    { min: 20, max: 40, startColor: '#007e8c', endColor: '#007e8c' }, // Dark orange to orange
    { min: 40, max: 60, startColor: '#439fa3', endColor: '#439fa3' }, // Dark green to green
    { min: 60, max: 80, startColor: '#78c0ba', endColor: '#78c0ba' }, // Dark yellow to yellow
    { min: 80, max: 100, startColor: '#a2fdfd', endColor: '#a2fdfd' } // Dark yellow to yellow
  ];

  for (const range of colorRanges) {
    if (value >= range.min && value <= range.max) {
      const ratio = (value - range.min) / (range.max - range.min);
      return getShade(range.startColor, range.endColor, ratio);
    }
  }
}












