import { Skeleton } from "./Skeleton";

export function TabsSkeleton(){

    return <>
          <div className="pb-4 md:pb-8 h-[87px] flex justify-center gap-5">
            <Skeleton className="mt-2 h-auto w-[100px]"/>
            <Skeleton className="mt-2 h-auto w-[100px]"/>
          </div>
    </>
}