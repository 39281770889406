import React, { useEffect, useState } from 'react'
import { SecondryButton, ShadowBox } from '../../../components'
import { updateActivity } from '../../../Store/actions/activity';
import { Main } from '../../../layout';
import { getCurrentGoal, updateGoal } from '../../../Store/actions/goal';
import { GOAL_TYPES } from '../../../constants';
import { CurrentGoalModal } from '../../../components/Modals/CurrentGoalModal';

interface IDailyGoal {
    currentActivityId: string;
    setShouldDisplayEditCurrentGoal: any;
    currentActivity: any;
    setGoals: any;
}


export const CurrentGoalorTask: React.FC<IDailyGoal> = ({setGoals,currentActivity,currentActivityId,setShouldDisplayEditCurrentGoal}) => {
    const [shouldDisplayDayOrWeekGoalInCognipuse, setShouldDisplayDayOrWeekGoalInCognipuse] = useState(true);
    // const [
    //     isShowCurrentGoalPopup,
    //     setIsShowCurrentGoalPopup,
    //     currentGoalData,
    //     setCurrentGoalData,
    //     handleCurrentGoalSubmit,
    //     finalCurrntGoal,
    //     setFinalCurrntGoal
    // ] = useCurrentGoalCal(currentActivityId,setGoals,setShouldDisplayEditCurrentGoal);
    const [goal, setGoal] = useState<any>(null);
    const [currentGoalorTaskName, setcurrentGoalorTaskName] = useState("");
    const [isShowCurrentGoalModal, setIsShowCurrentGoalModal] = useState(false);

    const handleDailyOrWeeklyGoalChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setcurrentGoalorTaskName(e.target.value);          
    }

    const handleSkip = async () => {
        await setGoals(currentActivityId,true);
        setShouldDisplayEditCurrentGoal(false);
    }

    const handleWillDisplaydayOrWekGoalInCognipuseChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setShouldDisplayDayOrWeekGoalInCognipuse(e.target.checked);
        await updateActivity(currentActivityId,{shouldDisplayDayOrWeekGoalInCognipuse: e.target.checked});
    }

    const handleAddNewGoal = () => {
        setIsShowCurrentGoalModal(true);
    }

    const handleSave = async (e: any) => {
        e.preventDefault();

        if(!currentGoalorTaskName){
            return;
        }

        const res = await updateGoal(goal._id,currentGoalorTaskName);
        
        if(res.success){
            await setGoals(currentActivityId);
            setShouldDisplayEditCurrentGoal(false);
        }
    }

    const handleAfterGoalCreated = async () => {
        await setGoals(currentActivityId);
        setShouldDisplayEditCurrentGoal(false);
    }

    useEffect(() => {
        (async () => {
            await updateActivity(currentActivityId,{shouldDisplayDayOrWeekGoalInCognipuse: true});
        })()
    }, [currentActivityId]);


    useEffect(() => {
        (async () => {
            const res = await getCurrentGoal(GOAL_TYPES[5],currentActivityId);

            if(res.success && res.goal){
                setGoal(res.goal);
                setcurrentGoalorTaskName(res.goal.name);
            }
        })()
    }, [currentActivityId]);

    return (
        <>
            <Main>
                <div className="w-full h-auto min-h-[calc(100vh-64px)] flex justify-center items-center">
                    <div className='w-[400px] max-w-full mx-auto'>
                        <ShadowBox> 
                            <h3 className='text-center font-bold text-secondry-color mt-10'>My Current Goal or Task</h3>
                            <textarea onChange={handleDailyOrWeeklyGoalChange} value={currentGoalorTaskName || "You currently have no goals."} placeholder='Enter Your Current Goal or Task' className='w-full bg-secondry-color/20 text-secondry-color rounded-lg p-4 text-center mt-3'/>
                            
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <input checked={shouldDisplayDayOrWeekGoalInCognipuse} onChange={handleWillDisplaydayOrWekGoalInCognipuseChange} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-secondry-button-color bg-gray-100 border-gray-300 rounded focus:ring-secondry-button-color focus:ring-2"/>
                                    <label htmlFor="checked-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Display your goal in your CogniPulse</label>
                                </div>
                                <span onClick={handleAddNewGoal} className='cursor-pointer ml-1 text-xs rounded-md bg-primary-button-color text-white p-1 px-2'>Enter New Goal</span>
                            </div>

                            <div className="flex justify-center mt-12 gap-2">
                                <SecondryButton onClick={handleSkip} style={{padding: "12px 0"}}>Skip</SecondryButton>
                                {
                                    goal && <SecondryButton onClick={handleSave} style={{padding: "12px 0"}}>{"Save"}</SecondryButton>
                                }
                            </div>
                        </ShadowBox>
                    </div>      
                </div>
            </Main>
            {/* {
                isShowCurrentGoalPopup && <CurrentGoalOrTask 
                                                activityCategory={currentActivity.category} 
                                                isUpdate={false} 
                                                data={currentGoalData} 
                                                handleSubmit={handleCurrentGoalSubmit} 
                                                setData={setCurrentGoalData} 
                                                setIsShow={setIsShowCurrentGoalPopup}
                                                finalCurrntGoal={finalCurrntGoal}
                                                setFinalCurrntGoal={setFinalCurrntGoal}
                                            />
            } */}
            {
               isShowCurrentGoalModal && <CurrentGoalModal handleSubmit={handleAfterGoalCreated} currentActivity={currentActivity} setIsShow={setIsShowCurrentGoalModal}/>
            }
        </>
    )
}



// function useCurrentGoalCal(currentActivityId:string,setGoals:any,setShouldDisplayEditCurrentGoal:any){
//     const loggedInUser = useSelector((state:RootState)=> state.loggedInUser);
//     const [isShowCurrentGoalPopup, setIsShowCurrentGoalPopup] = useState(false);
//     const [currentGoalData, setCurrentGoalData] = useState<any>({
//         where: "",
//         how: "",
//         what: "",
//         when: ""
//     });
//     const [finalCurrntGoal, setFinalCurrntGoal] = useState("");


//     const handleSubmit = async () => {
//         const data = {
//             name: finalCurrntGoal,
//             type: GOAL_TYPES[5],
//             dynamicProperties: currentGoalData,
//             startDate: new Date(),
//             user: loggedInUser._id,
//             activity: currentActivityId
//         }

//         const res = await createGoal(data);

//         if(res.success){
//             await setGoals(currentActivityId);
//             setShouldDisplayEditCurrentGoal(false);
//             setIsShowCurrentGoalPopup(false);
//         }
//     }


//     return [isShowCurrentGoalPopup,setIsShowCurrentGoalPopup,currentGoalData,setCurrentGoalData,handleSubmit,finalCurrntGoal,setFinalCurrntGoal];
// }