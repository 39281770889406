import {useState,useEffect} from 'react';
import {
  createEvent,
  updateEvent,
  deleteEvent,
  createTag,
  getAllTagsOfUser,
  setPersonalImprovementPoints,
  getUniversalSurveyTitle,
  deleteTag
} from "../../Store/actions";
import { toast } from 'react-toastify';
import {formetDateToFormInputType} from "../../utils";
import { useSelector,useDispatch } from 'react-redux';
import { ADD_EVENT } from '../../constants';
import {PrimaryButton, SecondryButton} from "../index";
import { InfoIconWithToolTip } from "../index";
import dayjs from 'dayjs'
import { Cross } from '../Icons';
import { Modal } from './Modal';
import { AppDispatch, RootState } from '../../Store/store';
import localizedFormat from "dayjs/plugin/localizedFormat";
import { EMOJIES } from '../../constants/constants';
import { useLocation } from 'react-router-dom';

dayjs.extend(localizedFormat);


interface IEventModal {
  setIsPhySelfieChanged: any;
  isManagemaneEvent: any;
  surveyTitle: any;
  data: any;
  eventId: any;
  isUpdate: any;
  setEvents: any;
  isOpen: any;
  setIsOpen: any;
  userId: any;
  surveyId?: string;
  surveyName: string;
}


export const  EventModal: React.FC<IEventModal> = ({surveyName,surveyId,setIsPhySelfieChanged,isManagemaneEvent,surveyTitle,data,eventId,isUpdate,setEvents,isOpen,setIsOpen,userId}) => {
        const dispatch: AppDispatch = useDispatch();
        const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
        const [universalSurveyTitle, setUniversalSurveyTitle] = useState<any>(null);
        const surveyUser = useSelector((state: RootState) => state.globalStates.surveyUser);
        const user = loggedInUser || surveyUser;
        const [isUserTagsLoaded,setIsUserTagsLoaded] = useState(false);
        const location = useLocation();

        

        const [tags, setTags] = useState(
          [
            {tag: "Relationships",selected: false},
            {tag: "Professional",selected: false},
            {tag: "Teams Meeting",selected: false},
            {tag: "Personal",selected: false},
            {tag: "WFH",selected: false},
          ]
        );
        const [newTag, setNewTag] = useState('');
        const [eventData, setEventData] = useState<any>({
          date: "",
          tags: [],
          description: "",
          surveytitle: "",
          surveyName: ""
        });
        const [selectedEmojies, setSelectedEmojies] = useState([]); 

        const handdleTagSelect = (tag:any)=>{
            setTags(prev => {
                return prev.map(t => t.tag === tag? {...t,selected: !t.selected}:t);
            })
        
        }

        const handdleTagChange = (e:any)=> {
            e.preventDefault();
            setNewTag(e.target.value);
        }

        const handdleEventInputChange = (e:any)=> {
            if(e.target.id === "description"){
              // const len = e.target.value.split("\n").length;
              // console.log(len);
              // const height = 29*len;
              // e.target.style.height = `${height < 64? 64:height}px`; 
              e.target.style.height = 'auto';
              e.target.style.height = `${e.target.scrollHeight}px`;
              const textarea = e.target;
              textarea.scrollTop = textarea.scrollHeight;
            }
            // console.log(e.target)
            setEventData((prev:any)=>{
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
        }

        const handdleAddTag = async (e:any)=> {
          e.preventDefault();
          const res = await createTag({user: user._id,tag: newTag });
          if(res.success){
            setTags(prev => {
              return [...prev,{tag: newTag,selected: false,_id: res.tag._id}]
            });
            setNewTag("")
          }
          else {
            toast.error("someting went wrong");
          }
        }

        const handdleAddEventSubmit = async (e:any) =>{
            e.preventDefault();
          
            if(!universalSurveyTitle){
              return;
            }

            const t = tags.filter(tag => tag.selected);
            const selectedTags = t.map(t => t.tag);
            const data = {
              ...eventData,
              surveytitle: isManagemaneEvent? surveyTitle:universalSurveyTitle?._id,
              tags: selectedTags,
              user: loggedInUser._id || userId,
              isManagementEvent: isManagemaneEvent? true:false,
              surveyName: surveyName,
              feelings: selectedEmojies,
              date: eventData.date || dayjs().toDate(),
              newCognipulseId: location.state?.newCognipulseId || null,
              survey: surveyId,
            }
            

            if(!data.date){
              toast.error("Date is required");
              setIsOpen(true);
              return;
            }

            if(isUpdate){
              const res = await updateEvent(data,eventId);
              // console.log(res);
              if(res.success){
                // toast.success(res.message);
                setEvents((prev:any) => {
                  return prev.map((ev:any) => ev._id === eventId? res.event:ev)
                })
              }
              setIsOpen(false);
              return;
            }

            if(!data.surveytitle){
              delete data.surveytitle
              // toast.error("Survey Title is Required");
              // setIsOpen(true);
              // return;
            }

            // data
            const res = await createEvent(data);
            console.log(res);

            if(res.success){
              // toast.success(res.message);
              if(setEvents){
                setEvents((prev:any) => {
                  return [...prev,res.event]
                })
              }
              
              setTimeout(() => {
                dispatch(setPersonalImprovementPoints(userId,ADD_EVENT));
              }, 3000);
              
              setIsOpen(false);

              if(setIsPhySelfieChanged){
                setIsPhySelfieChanged(true);
              }
            }
            else{
              toast.error(res.message);
            }
            
        } 

        const handdleDeleteEvent = async (e:any) => {
          e.preventDefault();
          const res = await deleteEvent(eventId);
          if(res.success){
            setIsOpen(false);
            setEvents((prev:any) => {
              return prev.filter((ev:any) => ev._id !== eventId);
            })
          }
          toast.success(res.message);
        }

        const handleEmojiSelect = (emojiName:any) => {
          setSelectedEmojies((prev:any) => {
            const emoji =  prev.find((emoji:any) => emoji === emojiName);
            if(emoji){
              return prev.filter((em:any) => em !== emoji);
            }
            return [...prev,emojiName];
          })
        }

        const handleDeleteTag = async (tagId:any) => {
          const isSure = window.confirm("Are You Sure!");

          if(!isSure){
            return;
          }

          const res = await deleteTag(tagId);
          if(res.success){
            toast.success("Tag Deleted Successfully!");
            setTags(prev => {
              return prev.filter((tag:any) => tag._id !== tagId);
            })
          }
          else {
            toast.error(res.message);
          }
        }

        useEffect(() => {
          if(isUpdate){
            console.log(data);
            setEventData({
              ...data,
              date: dayjs(data.date).format("YYYY-MM-DDTHH:mm")
            });

            setTags(prev => {
              const temp = prev.map(t => data.tags.indexOf(t.tag) !== -1? {...t,selected: true}:{...t,selected: false});
              const customTags = data.tags.map((t:any) => {return {tag:t,selected:true}});
              const tempTags:any = {};
              const finalTags:any = [];
              [...temp,...customTags].forEach(t => {
                if(!tempTags[t.tag]){
                  tempTags[t.tag] = t.tag;
                  finalTags.push(t);
                }
              })
              return finalTags;
            })
          }
          if(!isUpdate){
            setEventData({
              date: "",
              tags: [],
              description: ""
            });
          
            setTags(prev => {
              return prev.map(t => {return{...t,selected: false}});
            })
          }
        }, [isUpdate,data]);

        useEffect(() => {
          setEventData((prev:any) => {
            return {
              ...prev,
              date: formetDateToFormInputType(new Date().toISOString()) 
            }
          })
        }, []);

        useEffect(() => {
          (async ()=> {
            if(!isUserTagsLoaded){
              const res = await getAllTagsOfUser(userId);
              if(res.success){
                const tags = res.tags.map((tag:any) => {return {...tag,selected: false}});
                setTags(prev => {
                  const uniqueTags:any = [];
                  const allTags = [...prev,...tags].filter(item => {
                      if(uniqueTags.indexOf(item.tag) === -1){
                          uniqueTags.push(item.tag);
                          return item;
                      }

                      return undefined;
                  });
                  return allTags;
                });
                setIsUserTagsLoaded(true);
              }
              else {
                toast.error("Failed to load your tags.");
              }
            }
          })()
        }, [dispatch,userId,isUserTagsLoaded]);
        
        useEffect(() => {
          (async ()=> {
            const res = await getUniversalSurveyTitle();
            if(res.success){
              setUniversalSurveyTitle(res.surveyTitle);
            }
          })()
        }, []);


        

        if(!isOpen){
          return null;
        }

        return (
          <Modal 
          isHeader={true} 
          headerText={<div className='flex items-center gap-1 text-secondry-color'>Snap a quick ‘Psy-Selfie’ before it's lost. <InfoIconWithToolTip content="It's like a psychological selfie. It will offer powerful and long-lasting insights that will guide you in boosting your mindset and personal growth. Take an instant snapshot now to preserve them before they vanish."/></div>}
          modalWidth='max-w-xl'
          setIsOpen={setIsOpen}
          >
              <form className=''>
                  <div className={`flex items-center ${isUpdate? "justify-between":"justify-center"} mb-4`}>
                      {
                        isUpdate && <div className='flex justify-start'>
                        <PrimaryButton onClick={handdleDeleteEvent}>Delete</PrimaryButton>
                      </div>
                      }
                  </div>

                  <h6 className='font-bold text-gray-600 flex gap-2 items-center text-secondry-color mb-2'>How are you feeling right now? <InfoIconWithToolTip key="this" content="Select any emojis that reflect your current emotion. Your choice here helps to pinpoint your emotional state, providing a clearer picture of your mindset at this moment."/></h6>
                  
                  <div className="flex mb-6 gap-2 overflow-x-auto border border-secondry-color p-2 rounded-lg">
                      {
                        EMOJIES.map((item:any,idx) => (
                          <>
                            {
                              selectedEmojies.indexOf(item.name as never) !== -1 &&  <div onClick={()=>handleEmojiSelect(item.name)} className="transition-all p-2 bg-accent-color rounded-md flex flex-col cursor-pointer select-none justify-center items-center pb-2" key={idx}>
                                                                              <span className='text-[30px] transition-all'>{item.emoji}</span>
                                                                              <span className={`text-white text-xs text-center text-nowrap transition-all`}>{item.name}</span>
                                                                            </div>
                            }
                            {
                              selectedEmojies.indexOf(item.name as never) === -1 &&  <div onClick={()=>handleEmojiSelect(item.name)} className="flex flex-col border px-1 rounded cursor-pointer select-none justify-center items-center pb-2" key={idx}>
                                                                              <span className='text-[40px]'>{item.emoji}</span>
                                                                              <span className={`"text-gray-400 text-sm text-center text-nowrap`}>{item.name}</span>
                                                                            </div>
                            }
                          </>
                        ))
                      }
                  </div>

                  <p className='mb-1 text-[16px] text-secondry-color font-bold items-center mb-4'><span className='mr-2'>Select or add your own tag to quickly capture what’s on your mind right now</span> <InfoIconWithToolTip content="Choose tags relevant to your current thoughts or create your own. Tagging makes it easier to identify patterns in what affects your well-being and performance over time. These moments are like dreams, easily forgotten if not immediately noted."/></p>
                  
                  <div className="tags-box flex items-center flex-wrap mb-3 gap-2">
                      {
                          tags.map((tag:any,i) => {
                              return <div className={`border border-secondry-color/20 cursor-pointer rounded-md flex  select-none ${tag.selected? "bg-secondry-color text-white":"text-gray-600"}`} key={i}>
                              <p onClick={(()=>handdleTagSelect(tag.tag))} className="border-r px-2 py-1 font-bold rounded-md text capitalize text-xs mb-0">{tag.tag}</p>
                              {
                                tag._id && <div onClick={()=>handleDeleteTag(tag._id)} className='flex items-center self-stretch bg-secondry-color/20 rounded-tr-md rounded-br-md'>
                                              <Cross/>
                                           </div>
                              }
                            </div>
                          })
                      }
                  </div>

                  <div className="tags-input mb-6 flex gap-2 items-center">
                    <div className="form-group flex-1">
                      <input value={newTag} onChange={handdleTagChange} type="text" className="bg-secondry-color/20 w-full rounded-md px-2 py-2" id="tag-input" placeholder="Type your own tag.."/>
                    </div>
                    <div>
                      <PrimaryButton disabled={!newTag} onClick={handdleAddTag}>
                        + Add
                      </PrimaryButton>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className='mb-1 text-secondry-color font-bold flex gap-2 items-center'>Add some detail? <InfoIconWithToolTip content="The more detail you add, the deeper the insight you can gain into your emotional triggers and the influences on your daily well-being & performance. Again like dreams, these details are easily forgotten if not immediately noted."/></label>
                    <textarea onChange={handdleEventInputChange} value={eventData.description} name="description" id='description' className="bg-secondry-color/20 p-2 rounded-md w-full" placeholder="Enter details to truly capture the what you are experiencing."/>
                  </div>
                  <div className="mb-3">
                    <label className='mb-1 text-secondry-color font-bold flex gap-2 items-center'>When did this happened <InfoIconWithToolTip content="Today's date and time are auto-filled, but you can change them to when your experience actually happened, ensuring significant insights are captured, no matter when they occurred."/></label>
                    <input onChange={handdleEventInputChange} value={eventData.date || dayjs().format("YYYY-MM-DDTHH:mm")} name="date" type="datetime-local" className="bg-secondry-color/20 w-full px-2 text-secondry-color rounded-md" id="date"/>
                  </div>

                  <div className="mt-10 flex justify-between gap-3">
                    <SecondryButton style={{padding: "12px 0"}} disabled={!universalSurveyTitle} onClick={handdleAddEventSubmit}>Capture</SecondryButton>
                  </div>
                  {/* <div className="mt-10 flex justify-between gap-3 h-[64px]"/> */}
              </form>
          </Modal>
        )
}






