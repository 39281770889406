import { DOMAIN_NAME } from "../../constants";
import {fetchRequest} from "../../utils";



export const getCurrentGoal = async (goalType: string,activityId: string) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/goals/${goalType}/${activityId}`,"GET",{});

    return res;
}


interface IBody {
    name?: string;
    type: string;
    dynamicProperties: Object;
    startDate: Date | null;
    user?: string;
    activity: string;
}

export const createGoal = async (body: IBody) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/goals`,"POST",body);

    return res;
}

export const updateGoal = async (goalId: string, name: string) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/goals/${goalId}`,"PUT",{name});

    return res;
}

export const startGoal = async (goalId: string) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/goals/${goalId}/start`,"PUT",{});

    return res;
}


export const completeGoal = async (goalId: string) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/goals/${goalId}/complete`,"PUT",{});

    return res;
}