import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUniversalSurveyTitle } from "../../../Store/actions";
import { toast } from "react-toastify";
import { Main } from "../../../layout";
import { firstLetterUpperCase } from "../../../utils";
import { PrimaryButton, SecondryButton } from "../../../components";

interface INonCogniPulseTime {
    userId: any;
    surveyName: any;
    title: any;
    setPastTimeCompleted: any;
    inThePastMinutes: any;
    setInThePastMinutes: any;
    dayOrWeekGoal: any;
  }
  
  export const NonCogniPulseTime: React.FC<INonCogniPulseTime> = ({dayOrWeekGoal,userId,surveyName,title,setPastTimeCompleted,inThePastMinutes,setInThePastMinutes}) => {
    const navigator = useNavigate();
    const [value, setValue] = useState(null);
    const array = [
      {
        text: "5 Minutes",
        minute: 5
      },
      {
        text: "10 Minutes",
        minute: 10
      },
      {
        text: "20 Minutes",
        minute: 20
      },
      {
        text: "30 Minutes",
        minute: 30
      },
      {
        text: "40 Minutes",
        minute: 40
      },
      {
        text: "50 Minutes",
        minute: 50
      },
      {
        text: "1 hour",
        minute: 60
      },
    ];
    
  
    const [times] = useState(array);
  
  
    const handleChange = (e:any) => {
      setValue(e.target.value);
      setInThePastMinutes(Number(e.target.value));
    }
  
    const handleSkip = async () => {
        const universalSurveyTitleRes = await getUniversalSurveyTitle();
        if(universalSurveyTitleRes.success){
          const universalSurveyTitleId = universalSurveyTitleRes.surveyTitle._id;
          navigator(`/`);
          navigator(`/survey/${userId}/${universalSurveyTitleId}/${new Date().getTime()}?surveyName=${surveyName}&title=${title}&new=true`);
          setInThePastMinutes(null);;
        }
        else {
          toast.error("Something Went Wrong!");
        }
    }
  
    return (
      <Main>
  
        <h1 className="pt-10 md:text-center text-[24px] md:text-[30px] font-bold text-primary-button-color">Capture your creative and productive impact on <br/> {dayOrWeekGoal? <span className="bg-secondry-color/20 text-secondry-color text-sm px-4 py-1 rounded-md font-normal">{dayOrWeekGoal}</span>: `${firstLetterUpperCase(title)} ${firstLetterUpperCase(surveyName)}`} </h1>
  
        <h3 className="md:text-center pt-2 mb-4 mt-10 text-secondry-color text-[18px]">Select the time you were working on this activity (up to this moment)</h3>
  
        <div className="max-w-3xl mx-auto flex flex-wrap gap-2 md:justify-center">
          {
            times.map((item,idx) => (
              <button onClick={()=>{setInThePastMinutes(item.minute);setValue(null)}} className={`border border-secondry-color/20 shadow-md rounded p-2 cursor-pointer ${(inThePastMinutes === item.minute && !value)? "bg-accent-color text-white font-bold":""}`} title="click to select/deselect" key={idx}>{item.text}</button>
            ))
          }
        </div>
        <div className="flex flex-col w-full md:w-[300px] mx-auto mt-8">
            <label htmlFor="inThePastMinutes" className="text-secondry-color font-bold text-center">Enter Custom Time (in Minutes)</label>
            <input 
              value={value === null?  "":value} 
              onChange={handleChange} 
              id="inThePastMinutes" 
              placeholder="Enter time in minutes, e.g., 25mins"  
              type="number" 
              className="p-2 rounded-md bg-secondry-color/10"
            />
        </div>
  
        <div className="flex justify-center mt-12 gap-5 w-[500px] max-w-full mx-auto">
            <PrimaryButton style={{padding: "12px 0"}} onClick={()=>handleSkip()}>
              Skip
            </PrimaryButton>
            <SecondryButton style={{padding: "12px 0"}} onClick={()=>setPastTimeCompleted(true)} disabled={inThePastMinutes?false:true}>
              Next
            </SecondryButton>
        </div>
      </Main>
    )
  }