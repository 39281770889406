interface ISkeleton {
    style?: React.CSSProperties;
    className?: string;
  }
  
  export const Skeleton: React.FC<ISkeleton> =  ({style,className}) => {
  
    return <>
            <div style={style} className={`animate-pulse bg-gray-300 rounded-md ${className}`}/>
    </>
  }