import { DOMAIN_NAME } from "../../constants";
import {fetchRequest} from "../../utils";





export const getActivityByid = async (activityId: string) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/activities/one/${activityId}`,"GET",{});

    return res;
}


export const getUserActivities = async () => {

    const res = await fetchRequest(`${DOMAIN_NAME}/activities`,"GET",{});

    return res;
}

interface IActivity {
    category: string,
    name: string
}

export const createActivity = async (activity:IActivity | IActivity[]) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/activities`,"POST",activity);

    return res;
}

interface Ibody {
    category?: string;
    name?: string;
    purpose?: string[];              
    goal?: string[];
    dayOrWeekGoal?: string;
    currentGoalOrTask?: string;
    newCurrentGoalOrTask?: string;
    shouldDisplayDayOrWeekGoalInCognipuse?: boolean;
    order?: number;
}

export const updateActivity = async (activityid: string,body: Ibody) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/activities/${activityid}`,"PUT",body);

    return res;
}


interface ImoonShortGoal {
    result: string;
    action: string;
    impact: string;
    time: number;
}

export const createMoonShortGoal = async (activityId: string,moongShortGoal:ImoonShortGoal) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/activities/${activityId}/moon-short-goal`,"POST",moongShortGoal);

    return res;
}


export const updateMoonShortGoal = async (activityId: string,moongShortGoal:ImoonShortGoal) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/activities/${activityId}/moon-short-goal`,"PUT",moongShortGoal);

    return res;
}

