import { getAllCompletedSurveys } from "../../../Store/actions";
import { allSurveysWithXLScoresToHistoricalSPQ } from "../../../utils";
import { toast } from "react-toastify";

  interface IFilterOptions {
      setLoading: any;
      setPaginationData: any;
      setCompletedSurveys: any;
      searchTerms: any;
      setSearchTerms: any;
      setHistoricalOPQ: any;
      setSurveyFound: any;
      users: any;
      groups: any;
      surverTitles: any;
      setIsImpactSurvey: any;
  }
  
  export const FilterOptions: React.FC<IFilterOptions> = ({setIsImpactSurvey,setLoading,setPaginationData,setCompletedSurveys,searchTerms,setSearchTerms,setHistoricalOPQ,setSurveyFound,users,groups,surverTitles}) =>{
    
    const activities = ["study","hobby","relationship","work","study","sport","business"];

    const extrectAllUserIds = (group:any) => {
      let userIds:any = "";
      visit(group);
      function visit(node:any){
        const temp = node.users?.map((u:any) => u._id);
        userIds = [...userIds,...temp];
        
        if(node.children){
          node.children.forEach((child:any) => {
            const foundGroup = groups.find((g:any) => g._id === child._id);
            if(foundGroup){
              visit(foundGroup);
            }
          })
        }
      }
      return userIds.join(",");
    }
  
    const handleChaange = (e:any) => {
      setSearchTerms((prev:any) => {
        return {
          ...prev,
          [e.target.name]: e.target.value
        }
      })
  
    }
    
    const handleSelect = (type:any,payload:any) => {
      if(type === "user"){
        setSearchTerms((prev:any) => {
          return {
            ...prev,
            userSearch: `${payload.forename} ${payload.surname}`,
            userId: payload._id
          }
        })
      }
      if(type === "survey"){
        setSearchTerms((prev:any) => {
          return {
            ...prev,
            surveySearch: payload.title,
            surveyId: payload._id
          }
        });
        
        if(payload.title === "impact survey" || payload.title === "Impact"){
          setIsImpactSurvey(true);
        }
        else {
          setIsImpactSurvey(false);
        }
      }
      if(type === "group"){
        setSearchTerms((prev:any) => {
          return {
            ...prev,
            groupSearch: payload.groupname,
            groupId: extrectAllUserIds(payload)
          }
        })
      }
      if(type === "excluded"){
        setSearchTerms((prev:any) => {
          return {
            ...prev,
            showExcluded: payload.target.checked
          }
        });
      }
      if(type === "all"){
        setSearchTerms((prev:any) => {
          return {
            ...prev,
            showAll: payload.target.checked
          }
        });
      }
      if(type === "surveyName"){
        setSearchTerms((prev:any) => {
          return {
            ...prev,
            surveyName: payload
          }
        });
      }
    }
  
    const handleSearch = async () => {
      let query = ``;
  
      if(searchTerms.userId){
        query += `user=${searchTerms.userId}`;
      }
      if(searchTerms.surveyId){
        query += `&&surveytitle=${searchTerms.surveyId}`;
      }
      if(searchTerms.groupId){
        query += `&&groupId=${searchTerms.groupId}`;
      }
  
      if(searchTerms.groupId || searchTerms.surveyId || searchTerms.userId){
        query += `&&showAll=${true}`;
      }
      else {
        query += `&&showAll=${searchTerms.showAll}`;
      }

      if(searchTerms.surveyName){
        query += `&&surveyName=${searchTerms.surveyName}`;
      }
  
      query += `&&isexcluded=${searchTerms.showExcluded}`;
  
      setLoading(true);
  
      const res = await getAllCompletedSurveys(query);
      
      // console.log(res);
      
      setLoading(false);
  
      if(res.success){
          const sortedSurveys = res.surveys.sort((a:any,b:any)=> new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime());
          setCompletedSurveys(sortedSurveys);
          setSurveyFound(sortedSurveys.length > 0? true:false);
          const temp1 = sortedSurveys.reduce((a:any,b:any) => {
            const t = {createdAt: b.createdAt,XLScore: b.lxscore,data: b};
            if(a[b.user?._id]){
              a[b.user._id].push(t);
            }
            else {
              a[b.user._id] = [t]; 
            }
            return a; 
          },{});
          let sds:any = [];
          for (const key in temp1) {
            const sd = allSurveysWithXLScoresToHistoricalSPQ(temp1[key]);
            const t = sd.map(item => {return {...item,data:temp1[key][0].data}})
            sds = [...sds,...t];
          }
          setHistoricalOPQ(sds);
          setSearchTerms((prev:any) => {
            return {
              ...prev,
              page: 1
            }
          });
          setPaginationData({
            page: res.page,
            totalDocuments: res.totalDocuments,
            limit: res.limit,
          });
      }
      else {
        toast.error("Someting Went Wrong!")
      }
    }



    return (
      <>
       <div className='py-10'>
          <div className="flex gap-10 items-center flex-wrap">
              {/* <div className="block min-h-6 pl-7">
                <label className='flex items-center gap-3'>
                  <input checked={searchTerms.showAll} onChange={(e)=>handleSelect("all",e)} id="checkbox-1" className="w-4 h-4 ease-soft text-base -ml-7 rounded-1.4" type="checkbox" />
                  <label htmlFor="checkbox-1" className="cursor-pointer select-none text-slate-700">Show All</label>
                </label>
              </div> */}
              <div className="block min-h-6 pl-7 relative">
                <label className='flex items-center gap-3'>
                  <input checked={searchTerms.showExcluded} onChange={(e)=>handleSelect("excluded",e)} id="checkbox-1" className="w-4 h-4 ease-soft text-base -ml-7 rounded-1.4" type="checkbox" />
                  <label htmlFor="checkbox-1" className="cursor-pointer select-none text-slate-700">Show Excluded</label>
                </label>
              </div>
              <div className="block min-h-6 pl-7">
                <input value={searchTerms.userSearch} onChange={handleChaange} type="search" name='userSearch' id="userSearch" className="block w-full p-4 py-1 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search User.." />
                {
                  (searchTerms.userSearch && !users.find((user:any) => `${user.forename} ${user.surname}`.toLowerCase() === searchTerms.userSearch.toLowerCase())) && <div className="absolute z-[1] bg-white w-[198px] rounded-md p-2">
                                      { 
                                        users.filter((user:any) => `${user.forename} ${user.surname}`.toLowerCase().includes(searchTerms.userSearch.toLowerCase())).map((user:any,idx:number) => (
                                          <p key={idx} onClick={()=> handleSelect('user',user)} className='cursor-pointer'>{user.forename} {user.surname}</p>
                                        ))
                                      }
                                    </div>
                }
              </div>
              <div className="block min-h-6 pl-7 relative">
                  <input value={searchTerms.surveySearch} onChange={handleChaange} name='surveySearch' type="search" id="surveySearch" className="block w-full p-4 py-1 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search Survey.." />
                    {
                      (searchTerms.surveySearch && !surverTitles.find((survey:any) => `${survey.title}`.toLowerCase() === searchTerms.surveySearch.toLowerCase())) && <div className="absolute z-[1] bg-white w-[198px] rounded-md p-2">
                                          {
                                            surverTitles.filter((survey:any) => `${survey.title}`.toLowerCase().includes(searchTerms.surveySearch.toLowerCase())).map((survey:any) => (
                                              <p onClick={()=> handleSelect("survey",survey)} className='cursor-pointer'>{survey.title}</p>
                                            ))
                                          }
                                        </div>
                    }
              </div>
              <div className="block min-h-6 pl-7">
                <input value={searchTerms.groupSearch} onChange={handleChaange} name='groupSearch'  type="search" id="groupSearch" className="block w-full p-4 py-1 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search Group.." />
                {
                  (searchTerms.groupSearch && !surverTitles.find((group:any) => `${group.groupname}`.toLowerCase() === searchTerms.groupSearch.toLowerCase())) && <div className="absolute z-[1] bg-white w-[198px] rounded-md p-2">
                                      {
                                        groups.filter((group:any) => `${group.groupname}`.toLowerCase().includes(searchTerms.groupSearch.toLowerCase())).map((group:any) => (
                                          <p onClick={()=> handleSelect("group",group)} className='cursor-pointer'>{group.groupname}</p>
                                        ))
                                      }
                                    </div>
                }
              </div>
              <div className="block min-h-6 pl-7">
                <input value={searchTerms.surveyName} onChange={handleChaange} name='surveyName'  type="search" id="surveyName" className="block w-full p-4 py-1 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search Activity.." />
                {
                  (searchTerms.surveyName && activities.indexOf(searchTerms.surveyName) === -1) && <div className="absolute z-[1] bg-white w-[198px] rounded-md p-2">
                                      {
                                        activities.filter((surveyName:any) => `${surveyName}`.toLowerCase().includes(searchTerms.surveyName.toLowerCase())).map((surveyName:any) => (
                                          <p onClick={()=> handleSelect("surveyName",surveyName)} className='cursor-pointer'>{surveyName}</p>
                                        ))
                                      }
                                    </div>
                }
              </div>
              <div className="block min-h-6 pl-7">
                <button onClick={handleSearch} className='bg-indigo-800 text-white px-3 py-1 rounded-md'>Search</button>
              </div>
          </div>
       </div>
      </>
    )
  }